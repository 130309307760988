import axios from 'axios'
import {
  requestInterceptorsClass,
  responseInterceptorsClass,
  responseErrorInterceptorsClass
} from './serviceClass'


// 创建axios实例
const baseURL = window.baseURL = process.env.NODE_ENV === 'production' ? '/' : process.env.VUE_APP_BASE_API
const service = axios.create({
  baseURL
  // timeout: 60000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  async (config) => {
    return await requestInterceptorsClass.requestInterceptorsHandler(config)
  },
  (error) => {
    // Do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  (response) => {
    return responseInterceptorsClass.interceptorsHandler(response)
  },
  (error) => {
    return responseErrorInterceptorsClass.errorInterceptorsHandler(error)
  }
)

export default service
