<template>
  <section class="app-main">
    <transition :name="animationName" mode="out-in">
      <keep-alive :include="cachedViews" :max="8">
        <router-view :key="key" v-if="isRouterAlive" />
      </keep-alive>
    </transition>
  </section>
</template>

<script>
import { pull } from 'lodash'

export default {
  name: 'AppMain',
  // provide用在了帮助中心分类排序后刷新页面，解决不刷新页面，再次排序顺序异常的bug
  provide() {
    return {
      reload: this.reload,
      refreshPage: this.refreshPage
    }
  },
  computed: {
    cachedViews({ exclude }) {
      const cachedViews = this.$store.state.tagsView.cachedViews.concat(['protoManage', 'formwork', 'hadFedex', 'userBalance'])
      return cachedViews.filter(item => !exclude.includes(item))
      // return ['protoManage']
    },
    key({ $route }) {
      // 设置key为$route.fullPath是为了在路由参数变化时更新组件而不是直接复用缓存的组件
      let key = $route.fullPath
      // 当前路由为多层级路由时，设置key为$route.matched[1].path
      if ($route.matched.length > 2) {
        key = $route.matched[1].path
        if (key == '/infoManage/noticeManage') {
          key = $route.fullPath
        }
      }

      return key
    }
  },
  data() {
    return {
      animationName: 'fade-transform',
      // 控制路由router-view的显示与隐藏
      isRouterAlive: true,
      exclude: []
    }
  },
  watch: {
    $route() {
      this.addViewTags()
    }
  },
  mounted() {
    this.addViewTags()
  },
  methods: {
    addViewTags() {
      const { name } = this.$route
      if (name) {
        this.$store.dispatch('addView', this.$route)
      }
      return false
    },
    // 控制路由显示与隐藏，在别的组件注入这个方法后，调用这个方法可以刷新当前页面
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },
    refreshPage(name = this.$route.matched.slice(-1)[0].components.default.name) {
      const { exclude } = this
      exclude.push(name)
      this.reload()
      this.$nextTick(function () {
        pull(exclude, name)
        this.exclude = [...exclude]
      })
    }
  }
}
</script>

<style scoped>
.app-main {
  /*84 = navbar + tags-view = 50 +34 */
  min-height: calc(100vh - 84px);
  width: 100%;
  position: relative;
  overflow: hidden;
}
</style>
