//获取合并的等级
export function getSpanLevel(col, spanLevelList) {
  //当col的property为空时, property = 'done'为最后一列
  const { property = 'done' } = col
  for (let level in spanLevelList) {
    const arr = spanLevelList[level]
    if (arr.includes(property)) {
      return level
    }
  }
  return null
}

export function getSpanMethod({ row, column, spanLevelList }) {
  const { $spanLevelList = {} } = row
  const level = getSpanLevel(column, spanLevelList)
  let spanNum = 1
  if (level !== null) {
    spanNum = $spanLevelList[level]
  }
  return [spanNum, 1]
}
