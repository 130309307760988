import { Message } from 'element-ui'

export default {
  install: (Vue) => {
    Vue.prototype.$lConfirm = async (msg, title = '提示') => {
      return await Vue.prototype
        .$confirm(msg, title, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        .then(() => {
          return true
        })
        .catch(() => {
          Vue.prototype.$message({
            type: 'info',
            message: '已取消'
          })
          return false
        })
    }
  
    function message(option) {
      if (typeof option === 'string') {
        option = {
          message: option
        }
      }
      return Message({
        ...option,
        customClass: [
          option.customClass,
          `uiid-zd-${option.type || 'info'}`
        ].join(' ')
      })
    }
    ;['success', 'warning', 'info', 'error'].forEach(type => {
      message[type] = options => {
        if (typeof options === 'string') {
          options = {
            message: options
          }
        }
        options.type = type
        return message(options)
      }
    })
    Vue.prototype.$message = message
    
    //默认图片
    Vue.prototype.$DEFAULT_PIC = require('@/assets/images/default.png')
  }
}
