import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'
// 店铺管理

// 店铺类型字典
export function getShopType(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/dictionary/list',
    method: 'post',
    data
  })
}

// 店铺类型明细
export function getShopTypeItem(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/dictionaryItem/list',
    method: 'post',
    data
  })
}

//店铺列表
export function getShopList(data, menuType = 'menu') {
  return request({
    url: '/externaladmin/shopService/shop/list',
    method: 'post',
    data
  })
}

//店铺创建
export function createShop(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/shopService/shop/create',
    method: 'post',
    data
  })
}

//店铺修改
export function updateShop(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/shopService/shop/update',
    method: 'post',
    data
  })
}

//店铺删除
export function deleteShop(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/shopService/shop/delete',
    method: 'post',
    data
  })
}

// 洲，国家字典
export function getWebsiteAreaCode(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/countryCode/complexList',
    method: 'post',
    data
  })
}

//店铺批量禁用
export function disableShop(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/shopService/shop/batchDisable',
    method: 'post',
    data
  })
}

//店铺批量启用
export function enableShop(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/shopService/shop/batchCancelDisable',
    method: 'post',
    data
  })
}

//关联账号
export function relateAccounts(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/shopService/userShop/batchCreate',
    method: 'post',
    data
  })
}

//取消关联账号
export function cancelAccounts(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/shopService/userShop/delete',
    method: 'post',
    data
  })
}

//关联账号列表
export function relateAccountsList(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/shopService/userShop/list',
    method: 'post',
    data
  })
}

// 授权绑定店铺回调
export function callbackAliExpressCode(data, menuType = 'menu') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/shopService/shop/callbackAliExpressCode',
    method: 'post',
    data
  })
}

// 获取店铺平台
export function getPlatformList(data, menuType = 'menu') {
  return request({
    url: '/externaladmin/shopService/shopType/list',
    method: 'post',
    data
  })
}

// 创建店铺平台
export function createPlatform(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/shopService/shopType/create',
    method: 'post',
    data
  })
}

// 更新店铺平台
export function updatePlatform(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/shopService/shopType/update',
    method: 'post',
    data
  })
}

// 获取分销商列表
export function getDistributorUserList(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/user/distributorUserList',
    method: 'post',
    data
  })
}
