import request from '@/service/request'
//物流公司
export function list(data = {}) {
  return request({
    url: '/externaladmin/systemService/expressCompany/list/',
    method: 'post',
    data
  })
}

// 根据订单请求参数获取物流列表
export function expressCompanyStatistics(data = {}) {
  return request({
    url: '/externaladmin/systemService/expressCompany/expressCompanyStatistics',
    method: 'post',
    data
  })
}
