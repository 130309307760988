<template>
  <div class="form-container pull-auto" :class="{'is-group': option.group}">
    <el-form
      ref="form"
      :model="form"
      :size="option.size || DEFAULT_FORM_SIZE"
      :label-width="setPx(option.labelWidth, 80)"
      :label-suffix="labelSuffix"
      :label-position="option.labelPosition"
      :style="{ width: setPx(option.width) }"
      :rules="formRules"
      :validate-on-rule-change="vaildData(option.validateOnRuleChange, false)"
      :hide-required-asterisk="option.hideRequiredAsterisk"
      @submit.stop=""
      v-bind="$attrs"
    >
      <el-row :span="24">
        <el-col
          v-for="(item, index) in columnOption"
          :key="index"
          :span="vaildData(option.columnSpan, 24)"
          class="avue-group"
          :class="`${item.customClass || ''}${item.prop ? ` avue-group__${item.prop}` : ''}`"
        >
          <div class="avue-group__item">
            <div v-if="item.label" class="avue-group__header">
              <slot :name="`${item.prop}Header`" v-bind="item">
                <div class="avue-group__title">{{ item.label }}</div>
              </slot>
            </div>
            <div v-else-if="$slots.header" class="avue-group__header">
              <slot name="header"></slot>
            </div>
            <el-row class="avue-form__group clearfix" :gutter="option.gutter">
              <template v-for="(column, index) in getDisplayColumn(item.column)">
                <el-col
                  class="avue-form__row"
                  :offset="column.offset"
                  :pull="column.pull"
                  :md="column.span || item.span || option.span || 12"
                  :sm="12"
                  :xs="24"
                  :span="24"
                  :style="{ width: setPx(column.width), marginLeft: setPx(column.marginLeft) }"
                >
                  <el-form-item
                    :label="column.label"
                    :prop="column.prop"
                    :size="column.size || item.size || DEFAULT_FORM_SIZE"
                    :label-width="setPx(column.labelWidth, validData(item.labelWidth, option.labelWidth, 80))"
                    :show-message="!(column.disabled || column.readonly)"
                    :error="errorMsg[column.prop]"
                    :class="{ 'column-append': column.appendSlot }"
                    :required="column.required"
                  >
                    <slot
                      v-if="validData(column.formslot, column.formslotName)"
                      :name="column.formslotName || column.prop"
                      :form="form"
                      :prop="column.prop"
                      :label="column.label"
                      :value="form[column.prop]"
                      :column="column"
                      :dic="setDic(column, DIC)"
                      :size="column.size || item.size || DEFAULT_FORM_SIZE"
                      :placeholder="column.placeholder"
                      :setError="setError"
                      :validate="handleValidate"
                    ></slot>
                    <avue-crud-tooltip
                      v-else-if="['tooltip', 'text'].includes(column.type)"
                      effect="dark"
                      placement="top"
                      :content="detail(form, column)"
                    >
                      <div class="text-cut">{{ detail(form, column) }}</div>
                    </avue-crud-tooltip>
                    <component
                      v-else
                      v-bind="column"
                      :is="getComponent(column.type)"
                      :uiid="`zd-${column.prop}`"
                      v-model="form[column.prop]"
                      :trim="column.trim"
                      :column="column"
                      :precision="column.precision"
                      :label="column.label"
                      :props="column.props"
                      :placeholder="column.placeholder"
                      :clearable="column.clearable"
                      :type="column.type"
                      :size="column.size || item.size || DEFAULT_FORM_SIZE"
                      :prefix-icon="column.prefixIcon"
                      :minRows="column.minRows"
                      :maxRows="column.maxRows"
                      :maxlength="column.maxlength"
                      :dic="setDic(column, DIC)"
                      :disabled="vaildData(column.disabled, vaildData(item.disabled, option.disabled))"
                      :readonly="vaildData(column.readonly, vaildData(item.readonly, option.readonly))"
                      :format="column.format"
                      :value-format="column.valueFormat"
                      :emitPath="column.emitPath"
                      :checkStrictly="column.checkStrictly"
                      :show-all-levels="column.showAllLevels"
                      :expandTrigger="column.expandTrigger"
                      :controls="column.controls"
                      :controls-position="column.controlsPosition"
                      @search-change="searchChange"
                    ></component>
                    <slot
                      v-if="column.appendSlot"
                      :name="`${column.prop}Append`"
                      :form="form"
                      :column="column"
                      :dic="setDic(column, DIC)"
                      :size="column.size || item.size || DEFAULT_FORM_SIZE"
                      :setError="setError"
                      :validate="handleValidate"
                    >
                      <slot
                        name="columnAppend"
                        :form="form"
                        :column="column"
                        :dic="setDic(column, DIC)"
                        :size="column.size || item.size || DEFAULT_FORM_SIZE"
                        :setError="setError"
                        :validate="handleValidate"
                      ></slot>
                    </slot>
                  </el-form-item>
                </el-col>
              </template>
            </el-row>
          </div>
        </el-col>

        <el-col v-if="menuBtn" :span="vaildData(option.menuSpan, 24)" class="form-menu-wrapper">
          <el-form-item label-width="0">
            <div class="form-menu" :class="menuPosition">
              <el-button plain :size="DEFAULT_FORM_SIZE" @click="resetForm" v-if="vaildData(option.emptyBtn, true)">{{
                  option.emptyText ? option.emptyText : '取消'
                }}</el-button>
              <el-button
                v-if="vaildData(option.submitBtn, true)"
                type="primary"
                :size="DEFAULT_FORM_SIZE"
                :loading="loading"
                @click="submit"
              >{{ option.submitText ? option.submitText : '保存' }}</el-button>
              <slot name="menuForm"></slot>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {
  DEFAULT_FORM_SIZE
} from '../../utils/const/config'
import crud from '../../mixins/crud'
import { validatenull } from '../../utils/validate.js'
import { vaildData, validData } from '@/components/avue/utils/util'

export default {
  name: 'AvueForm',
  mixins: [crud()],
  components: {},
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    disabled: Boolean,
    readonly: Boolean,
    option: {
      type: Object,
      required: true,
      default: () => ({})
    },
    dic: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      DEFAULT_FORM_SIZE,
      formCreate: false,
      form: {},
      formRules: {},
      DIC: {},

      loading: false,

      errorMsg: {}
    }
  },
  computed: {
    parentOption({ option }) {
      let group = option.group || []
      option.column && group.push({
        ...option,
        group: undefined
      })
      return {
        ...option,
        group
      }
    },
    columnOption({ parentOption: { group } }) {
      return this.deepClone(group)
    },
    propOption() {
      let tempArr = []
      this.columnOption.forEach(item => {
        tempArr = tempArr.concat(item.column)
      })
      return tempArr
    },
    objectOption() {
      let tempObj = {}
      this.propOption.forEach(column => {
        tempObj[column.prop] = column
      })
      return tempObj
    },
    controlOption() {
      return this.propOption.filter(column => column.control)
    },

    labelSuffix({ parentOption: { labelSuffix } }) {
      if (typeof labelSuffix === 'boolean') return labelSuffix ? '：' : ''
      return vaildData(labelSuffix, '：')
    },

    menuBtn() {
      return (
        this.vaildData(this.option.menuBtn, true) &&
        (this.vaildData(this.option.submitBtn, true) ||
          this.vaildData(this.option.emptyBtn, true) ||
          this.$slots.menuForm)
      )
    },
    menuPosition: function () {
      if (this.option.submitPostion) {
        return 'is-' + this.option.submitPostion
      } else {
        return 'is-center'
      }
    }
  },
  watch: {
    option: {
      handler(n, o) {
        this.formCreate = false
        // this.$nextTick 导致 formVal 在 value 赋值之后执行
        this.formVal()
        // this.rulesInit()
      },
      deep: true
    },
    value: {
      handler(n, o) {
        // console.log(this.option.column[0].prop, JSON.parse(JSON.stringify(this.value)));
        if (this.formCreate) this.formVal()
      },
      deep: true
    },
    columnOption: {
      handler(n, o) {
        this.formRules = {}
        n.forEach((ele, index) => {
          // ele.column = (ele.column || []).filter(column => !column.hide)
          // 循环列的全部属性
          if (!validatenull(ele.column)) {
            //规则初始化
            this.rulesInit(ele.column)
            //初始化form表单
            this.formInit(ele)
          }
        })
        //初始化dic字典
        this.dicInit()
        //初始化值
        // this.formVal();
      },
      immediate: true,
      deep: true
    },
    form: {
      handler() {
        this.controlOption.forEach(ele => {
          let control = ele.control(this.form[ele.prop], this.form) || {}
          Object.keys(control).forEach(item => {
            this.objectOption[item] = Object.assign(this.objectOption[item], control[item])
            if (control[item].dicData) {
              this.DIC[item] = control[item].dicData
            }
          })
        })
      },
      immediate: true,
      deep: true
    },
    dic: {
      handler(dic) {
        Object.keys(dic).forEach((key) => {
          this.$set(this.DIC, key, dic[key])
        })
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.$nextTick(function () {
      //规则初始化
      // this.rulesInit()
      //初始化dic字典
      // this.dicInit()
      //初始化form表单
      // this.formInit()
      //初始化值
      this.formVal()
    })
  },
  mounted() {},
  methods: {
    vaildData,
    validData,
    rulesInit(column) {
      this.errorMsg = {}
      column.forEach((ele) => {
        // this.getDicData(ele)
        // this.setCascaderItem('form', ele)

        if (ele.rules && !ele.customValidate) {
          let rules = Array.isArray(ele.rules) ? ele.rules : [ele.rules]
          this.formRules[ele.prop] = rules.map((rule) => {
            if (rule.required === true) {
              return {
                message: `${ele.label || '该项'}必填`,
                ...rule
              }
            }
            return rule
          })
        }
      })
    },
    dicInit() {
      this.GetDic(this.option.dic).then((data) => {
        Object.keys(data).forEach((key) => {
          this.$set(this.DIC, key, data[key])
        })
      })
    },
    formInit(group) {
      let { disabled, readonly, option, form } = this
      disabled = this.validData(group.disabled, option.disabled, disabled)
      readonly = this.validData(group.readonly, option.readonly, readonly)
      const list = group.column
      let nForm = {}
      list.forEach((ele) => {
        if (ele.type == 'checkbox' || ele.type == 'cascader' || ele.type == 'daterange') {
          nForm[ele.prop] = []
        } else {
          nForm[ele.prop] = ''
        }
        // console.log(ele)
        ele.disabled = this.vaildData(ele.disabled, disabled)
        ele.readonly = this.vaildData(ele.readonly, readonly)
        if (!validatenull(ele.value) || Array.isArray(ele.value)) nForm[ele.prop] = ele.value
      })
      for (const key in nForm) {
        let value = form[key]
        this.$set(form, key, [undefined].includes(value) ? nForm[key] : form[key])
      }
    },
    formVal() {
      if (this.formCreate) {
        this.form = this.value
        this.$emit('input', this.form)
      } else {
        this.formCreate = true
        Object.keys(this.form).forEach((ele) => {
          this.value[ele] === undefined && this.$set(this.value, ele, this.form[ele])
        })
        this.form = this.value
        // Object.assign(this.form, this.value);
        this.$emit('input', this.form)
      }
      // console.log(this.option.column[0].prop, JSON.parse(JSON.stringify(this.form)), JSON.parse(JSON.stringify(this.value)));
    },
    searchChange() {
      if (this.option.isSearch) {
        this.submit()
      }
    },
    submit() {
      this.option.submitLoading && (this.loading = true)
      this.$refs['form'].validate((valid, errObj) => {
        if (valid) {
          this.$emit('submit', this.form, () => this.loading = false)
        } else {
          this.loading = false
          this.$emit('err', errObj)
        }
      })
    },
    resetForm() {
      this.$refs['form'].resetFields()
      this.$emit('input', this.form)
      this.$emit('reset-change', this.form, () => {})
    },
    resetFields() {
      return this.$refs.form.resetFields()
    },
    async validate(callback) {
      let { errorMsg } = this
      if (Object.values(errorMsg).some(Boolean)) return false

      return this.$refs.form.validate(callback)
    },
    validateField(field, callback) {
      let { errorMsg } = this
      field = Array.isArray(field) ? field : [field]
      let errors = {}
      field.forEach(prop => errors[prop] = errorMsg[prop])
      if (Object.values(errors).some(Boolean)) {
        if (typeof callback === 'function') {
          field.forEach(prop => callback(errors[prop] || ''))
        }
        return false
      }

      return this.$refs.form.validateField(field, callback)
    },
    clearValidate(list) {
      this.$nextTick(() => {
        this.errorMsg = {}
        this.$refs.form.clearValidate(list)
      })
    },
    setError(prop, error) {
      // console.log(prop, error)
      let { errorMsg } = this
      if (errorMsg[prop] === error) {
        errorMsg[prop] = ''
        this.$nextTick(function () {
          this.$set(errorMsg, prop, error)
        })
      } else {
        this.$set(errorMsg, prop, error)
      }
    },
    handleValidate(prop, valid, error) {
      // console.log(prop, valid, error)
      this.setError(prop, error)
    },
    detail(form, column) {
      let { formatter, prop, placeholder } = column
      let value = form[prop]
      if (validatenull(value)) return placeholder
      if (typeof formatter === 'function') return formatter(value, form, column)
      return value
    },

    getDisplayColumn(column) {
      return (column || []).filter(col => !col.hide)
    }
  }
}
</script>

<style lang="scss" scoped>
.form-container {
  //padding-right: 5%;
  text-align: left;
}
.form-menu {
  width: 100%;
  &.is-center {
    text-align: center;
  }
  &.is-left {
    text-align: left;
  }
  &.is-right {
    text-align: right;
  }
}
.form-menu-sticky {
  .form-menu-wrapper {
    position: sticky;
    bottom: 0;
    background-color: #FFFFFF;

    .el-form-item {
      margin: 10px 0;
    }
  }
}

.is-group {
  $border-color: $color-info;
  .avue-group {
    border: 1px solid $border-color;

    + .avue-group {
      border-top: none;
    }
  }
  .avue-form__group {
    padding: 22px 40px 0;
  }
  .avue-group__title {
    line-height: 60px;
    padding-left: 18px;
    border-bottom: 1px solid $border-color;
    background-color: #f7f8fa;
  }
  .form-menu-wrapper {
    margin-top: 22px;
  }
}
.one-page-form {
  &.form-container,
  &.form-container > .el-form,
  &.form-container > .el-form > .el-row,
  .avue-form__group > .el-col,
  .avue-form__group > .el-col > .el-form-item,
  ::v-deep .avue-form__group > .el-col > .el-form-item > .el-form-item__content,
  .avue-group__item {
    height: 100%;
  }

  > .el-form {
    > .el-row {
      display: flex;
      flex-direction: column;
    }
  }

  &.shrink-2 {
    .avue-group:nth-child(2) {
      flex: 1;
      overflow: hidden;
      .avue-group__item {
        display: flex;
        flex-direction: column;
      }
      .avue-form__group {
        flex: 1;
        overflow: hidden;
      }
      .el-form-item {
        padding-bottom: 22px;
      }
    }
  }
}

.clearfix {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
}
</style>
