/* eslint-disable */
import options from './options'
import store from '@/store'
import { Message, Loading } from 'element-ui'
import { uploadOSSPics, OSS_DIR_MAP_WITH_TYPE } from './oss'
import { accAdd, accDiv, accMul, accSub, checkPermission, parseImgSrc, getIP } from '@/utils'
import get from 'lodash/get'
import { COMPANY_NAME } from '@/utils/constant/systemConst'
import { aLiCompressPicByUrl } from '@/utils/constant/imageConst'
import { rewriteMap } from '@/utils'
import { hasOwnProperty, validData } from '@/components/avue/utils/util'
//获取公网和内网ip
getIP()

export default {
  install(Vue) {
    window.IS_PRODUCTION = process.env.NODE_ENV === 'production' && ['newprd', 'newprd2', 'prd', 'pro'].includes(process.env.VUE_APP_ENV_STAGE)
    Vue.prototype.$formOptions = options
    Vue.prototype.COMPANY_NAME = COMPANY_NAME
    Vue.prototype.$console = console.log
    Vue.prototype.parseImgSrc = parseImgSrc
    Vue.prototype.checkPermission = checkPermission
    Vue.prototype.$math = {
      // 浮点数求和
      add: accAdd,
      // 浮点数相减
      subtract: accSub,
      // 浮点数相除
      divide: accDiv,
      // 浮点数相乘
      multiply: accMul
    }

    window.aLiCompressPicByUrl = Vue.prototype.aLiCompressPicByUrl = aLiCompressPicByUrl
    window.$SUC = (res) => {
      if (!res) return false
      const { code } = res || {}
      return (code >= 200 && code < 300) || code == 0
    }
    /*
     * 处理promise，方便await使用
     * @param {promise}
     * @return {array}
     * @example see @\views\product\baseProductPage\module\header.vue
     * */
    window.awaitWrap = (promise) => {
      return promise.then((data) => [null, data]).catch((err) => [err, null])
    }
    window.awaitLoading = async (promise, option = '请稍候') => {
      typeof option === 'string' && (option = { lock: true, text: option })
      option.customClass = (option.customClass || '') + 'zIndexMax'
      const loading = Loading.service(option)
      await awaitWrap(promise)
      loading.close()
      return promise
    }
    window.awaitFormResolve = async (promise) => {
      // eslint-disable-next-line no-undef
      return (await awaitWrap(promise))[1]
    }
    window.awaitResolve = async (promise, { isMsg, sucMsg = '操作成功', errMsg = '操作失败', mustCheckCode = true } = {}) => {
      // eslint-disable-next-line no-undef
      let [err, res] = await awaitWrap(promise)
      if ((mustCheckCode || hasOwnProperty(res, 'code')) && !$SUC(res)) {
        if (err && /1001000/.test(err.code) && err.message) {
          Message.warning(err.message)
        }
        res = false
      }
      if (isMsg) {
        Message[res ? 'success' : 'warning'](res ? sucMsg : errMsg)
      }
      return res
    }
    window.awaitResolveLoading = async (promise, option) => {
      return awaitLoading(awaitResolve(promise, option))
    }
    window.awaitResolveDetail = async (promise) => {
      let res = await awaitResolve(promise)
      return res ? res?.detail : undefined
    }
    window.awaitResolveDetailLoading = async (promise, option) => {
      return awaitLoading(awaitResolveDetail(promise, option))
    }
    window.promiseAll = (promises) => {
      return new Promise((resolve) => {
        let res = []
        if (!Array.isArray(promises) || promises.length === 0) return resolve(res)
        let resLen = 0
        promises.map((promise, index) => {
          if (!(promise instanceof Promise)) {
            res[index] = promise
            if (++resLen === promises.length) resolve(res)
            return
          }
          return promise
            .then((data) => {
              res[index] = data
            })
            .catch(() => {
              res[index] = false
            })
            .finally(() => {
              if (++resLen === promises.length) resolve(res)
            })
        })
      })
    }
    window.refreshDic = async (promise, dicName) => {
      // eslint-disable-next-line no-undef
      await awaitWrap(promise)
      store.dispatch('RefreshDic', dicName)
      return promise
    }

    //oss图片上传公共方法
    window.$uploadOSSPics = uploadOSSPics
    //oss图片上传对应的目录映射
    window.$ossDirMapWithType = OSS_DIR_MAP_WITH_TYPE

    window.$DEFAULT_PIC = require('@/assets/images/default.png')

    window.$GET = (object, path, defaultValue) => {
      return validData(get(object, path, defaultValue), defaultValue)
    }
    window.$MAP = rewriteMap
  }
}
