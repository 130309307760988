import { isNumber, isString } from 'lodash'

const VALID_LIST = {
  number(val) {
    if (isNumber(val)) return true
    console.log('isNaN(+val)', isNaN(+val))
    return !isNaN(+val)
  },
  string(val) {
    if (!isString(val)) return false
    return isNaN(+val)
  }
}

export default {
  componentUpdated(el, binding, vNode) {
    let { expression, value, oldValue, arg } = binding
    if (!value) return
    if (!arg) arg = 'number'
    if (VALID_LIST[arg](value)) return
    const splitKeys = expression.split('.')
    let lastKey = splitKeys.pop()
    let lastVal = $GET(vNode.context, splitKeys.join('.'))
    const lastKeyIsArray = lastKey.match(/\[(\d+)\]$/)
    //数组重置Key和val
    if (lastKeyIsArray) {
      lastVal = $GET(lastVal, lastKey.split('[').shift())
      lastKey = lastKeyIsArray[1]
    }
    vNode.context.$set(lastVal, lastKey, oldValue)
  }
}
