/* eslint-disable */

// prototypePermissionType 1所有原型 2指定原型
export const PROTOTYPE_ALL = 1
export const PROTOTYPE_APPOINT = 2
export const PROTOTYPE_DIC = [
  {
    label: '所有原型',
    value: PROTOTYPE_ALL
  },
  {
    label: '指定原型',
    value: PROTOTYPE_APPOINT
  }
]

// imagePermissionType 1所有图片 2指定图片
export const PICTURE_ALL = 1
export const PICTURE_APPOINT = 2