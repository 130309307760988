import dic from '@/store/modules/dic'
import store from '@/store/index'

const getters = {
  shop: (state) => state.shop,
  sidebar: (state) => state.app.sidebar,
  device: (state) => state.app.device,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  visitedViews: (state) => state.tagsView.visitedViews,
  cachedViews: (state) => state.tagsView.cachedViews,
  id: (state) => state.user.id,
  name: (state) => state.user.name,
  createTime: (state) => state.user.createTime,
  email: (state) => state.user.email,
  roles: (state) => state.user.roles,
  //是否是超级管理员
  is_super: (state) => state.user.is_super,
  //是否是主账号
  isMain: (state) => state.user.accountType === 1,
  type: (state) => state.user.type,
  permission_routers: (state) => state.permission.routers,
  addRouters: (state) => state.permission.addRouters,
  curRouterId: (state) => {
    if (state.permission.curRouterId) {
      let curRouterId = sessionStorage.getItem('curRouterId')
      if (curRouterId) {
        state.permission.curRouterId = curRouterId
      }
    }
    return state.permission.curRouterId
  },
  socketApi: (state) => state.api.socketApi,
  knifePsds: (state) => state.checkKnife.knifePsds,
  isGoBack: (state) => state.checkKnife.isGoBack,
  knifeStandardStatus: (state) => state.checkKnife.knifeStandardStatus,
  //原型
  productObj: (state) => {
    if (!Object.keys(state.product.productObj).length) {
      if (sessionStorage.getItem('productObj') && sessionStorage.getItem('productObj') != 'undefined') {
        state.product.productObj = JSON.parse(sessionStorage.getItem('productObj') || '{}')
      }
    }
    return state.product.productObj
  },
  tasks: (state) => {
    return state.product.tasks
  },
  fedexData: (state) => {
    store.dispatch('GetDic', 'expressCompanyId')
    let dicData = state.dic.expressCompanyId.partData.isDeleted
    return Array.isArray(dicData) ? dicData : []
  },
  //编辑时候获取定制数据
  customFloorData: (state) => {
    return state.design.customFloorData
  },
  //自定义底板实例
  customDesignInstance: (state) => {
    return state.design.customDesignInstance
  },
  customFloorRenderFinish: (state) => {
    return state.design.customFloorRenderFinish
  },
  //当前激活对象
  curFabircActiveObject: (state) => {
    return state.design.curFabircActiveObject
  },
  //当前激活旋转角度
  curFabircActiveObjectAngle: (state) => {
    return state.design.curFabircActiveObjectAngle
  },
  //获取缩放
  curFabircActiveObjectScale: (state) => state.design.curFabircActiveObjectScale,
  //获取fabricObjects
  fabricObjects: (state) => state.design.fabricObjects
}

for (const dicKey in dic.state) {
  getters[dicKey] = (state) => {
    store.dispatch('GetDic', dicKey)
    let { dicData } = state.dic[dicKey]
    return Array.isArray(dicData) ? dicData : []
  }
  getters[`${dicKey}Props`] = (state) => {
    return (
      state.dic[dicKey].props || {
        label: 'label',
        value: 'value'
      }
    )
  }
}

export default getters
