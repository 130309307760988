import { validatenull } from '@/components/avue/utils/validate'

//获取坐标效果图

export function getPrimShowImgs(data) {
  try {
    return data.map(({ showImageGroupList }) => {
      showImageGroupList = Array.isArray(showImageGroupList) ? showImageGroupList : []
      if (!showImageGroupList.length) {
        return false
      }
      const { showImageGroupItemList = [] } = showImageGroupList[0] || {}
      console.log('prim_show_img', showImageGroupItemList)
      return showImageGroupItemList || []
    })
  } catch (err) {
    return false
  }
}

export function formDataAppendArray(formData, data, key) {
  for (let i = 0; i < data.length; i++) {
    const val = data[i]
    if (Array.isArray(val)) {
      for (let j = 0; j < val.length; j++) {
        const sVal = val[j]
        formData.append(key, sVal)
      }
    } else {
      formData.append(key, val)
    }
  }
}

export function formatOffData(data) {
  return data.map((item) => {
    const { id, styleList, chineseName, detail, sizeList } = item
    const style = (styleList || []).filter((item) => {
      return item.status === 1
    })
    const size = (sizeList || []).filter((item) => {
      return item.status == 1
    })
    style.map((struct) => (struct.status = false))
    size.map((size) => (size.status = false))
    return {
      style,
      chineseName,
      detail,
      size,
      status: false,
      id,
      message: {
        status: true,
        text: ''
      }
    }
  })
}

export function formatPutOnData(data) {
  return data.map((item) => {
    const { id, $styleList: styleList, chineseName, detail, $sizeList: sizeList } = item
    const style = (styleList || []).filter((item) => {
      return item.status === 0 || item.status === 2
    })
    const size = (sizeList || []).filter((item) => {
      return item.status === 0 || item.status === 2
    })
    style.map((struct) => (struct.status = false))
    size.map((size) => (size.status = false))
    return {
      style,
      chineseName,
      detail,
      size,
      status: false,
      id,
      message: {
        status: true,
        text: ''
      }
    }
  })
}

export function hasShelvesStyle(data) {
  const tempArr = (data || []).filter(({ status }) => {
    return status === 1
  })
  return tempArr
}

//获取组合产品原型列表
export function getProductPrototypeList(row) {
  const { max } = Math
  const tmpArr = []
  const productPrototypeList = $GET(row, 'productPrototypeList', [])
  productPrototypeList.map((product) => {
    const tmpObj = {
      name: $GET(product, 'name', $GET(product, 'chineseName', '暂无')),
      styleDisplayImageList: []
    }
    const styleList = $GET(product, 'styleList', [])
    const sizeList = $GET(product, 'sizeList', [])
    styleList.map((styleItem) => {
      $GET(styleItem, 'styleDisplayImageList', []).map((displayImg) => {
        tmpObj.styleDisplayImageList.push($GET(displayImg, 'displayImagePath', window.$DEFAULT_PIC))
      })
    })
    const priceList = sizeList.map((sizeItem) => {
      const sizeLevelCategoryPriceList = $GET(sizeItem, 'sizeLevelCategoryPriceList', [])
      return (
        max(
          ...sizeLevelCategoryPriceList.map((priceItem) => {
            return $GET(priceItem, 'price', 0)
          })
        ) || 0
      )
    })
    tmpObj.price = max(...priceList)
    tmpArr.push(tmpObj)
  })
  return tmpArr
}

/**
 * @description: 根据 bottomBoardList列表的创建时间和更新时间，获取最早的创建时间和最晚的更新时间
 * @param {Object}
 * @return {Object} {createTime: '最早的创建时间， updateTime： 最晚更新时间}
 */
export function getBottomBoardListTimeList(row) {
  try {
    const timeObj = {}
    row.bottomBoardList.map((item) => {
      const { createTime, updateTime } = item
      if (!timeObj.createTime) {
        timeObj.createTime = createTime
      }
      if (!timeObj.updateTime) {
        timeObj.updateTime = updateTime
      }
      if (new Date(createTime) <= new Date(timeObj.createTime)) {
        timeObj.createTime = createTime
      }

      if (new Date(updateTime) >= new Date(timeObj.updateTime)) {
        timeObj.updateTime = updateTime
      }
    })
    return timeObj
  } catch {
    return {}
  }
}

/**
 * @description: 获取订单模块下载名称
 * @param {Object} data
 * @param {String} suffix
 * @return {String} 拼接之后的文件名称
 */
export function getOrderDownloadName(data, suffix = '') {
  const { orderCode } = data
  if (!suffix) {
    return orderCode
  }
  return `${orderCode}-${suffix}`
}

//设置setTimeout延迟
export async function waitTimeByNum(num) {
  await new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, num)
  })
}

/**
 * @description: 按照给定字段扁平化一棵树
 * @param Array | tree  被扁平化的树
 * @param String | childrenKey 扁平化的依据字段
 * @return {Array
 */
export function flattenTreeByKey(tree, childrenKey = 'children') {
  const res = []
  for (const item of tree) {
    const { [childrenKey]: children, ...rest } = item
    res.push(rest)
    if (!validatenull(children)) {
      res.push(...flattenTreeByKey(children))
    }
  }
  return res
}