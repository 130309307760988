import castPath from 'lodash/_castPath'
import { map, flattenDepth } from 'lodash'
import { validatenull } from '@/components/avue/utils/validate'

export function rewriteMap(arr, params, isFlat) {
  typeof params === 'string' && (params = castPath(params))
  if (!Array.isArray(arr) || !Array.isArray(params)) return map(arr, params)
  let lastIndex = params.length - 1
  return params.reduce((prev, next, index) => {
    if (!isFlat && index === lastIndex) return map(prev, next)
    return flattenDepth(map(prev, next))
  }, arr)
}

export function intersectionDic(dic, { label, value } = { label: 'label', value: 'value' }) {
  if (validatenull(dic)) return []
  
  let tempArr = []
  let tempObj = {}
  dic.forEach(item => {
    tempObj[item[value]] = {
      label: item[label],
      value: item[value]
    }
  })
  for (const key in tempObj) {
    tempArr.push(tempObj[key])
  }
  return tempArr
}