import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

export function add(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/user/createDistributorUser',
    method: 'post',
    data
  })
}

export function employeeList(params) {
  return request({
    url: '/api/rbac/employee_list/',
    method: 'get',
    params
  })
}

export function subDistributor(data) {
  return request({
    url: `/api/rbac/common_users/${data}/sub_distributor/`,
    method: 'get',
    data
  })
}
export function read(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/user/getUserById',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: '/api/rbac/common_users/' + id + '/',
    method: 'delete'
  })
}

export function edit(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/user/updateUser',
    method: 'post',
    data
  })
}

export function disabled(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/isDisabled',
    method: 'post',
    data
  })
}

export function resetPassword(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/user/changePassword',
    method: 'post',
    data
  })
}
export function setPaymentPassword(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/user/updateApproveConfirmPassword',
    method: 'post',
    data
  })
}

export function changePassword(data) {
  return request({
    url: '/externaladmin/authService/user/updateApproveConfirmPassword',
    method: 'post',
    data
  })
}

export function resetAdminUserPassword(id, data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: `/api/rbac/admin_users/${id}/reset_password/`,
    method: 'patch',
    data
  })
}

export function updateAdminType(id, data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/api/rbac/common_users/admin_type/',
    method: 'patch',
    data
  })
}

export function updatePasswd(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/api/rbac/common_users/change_password/',
    method: 'patch',
    data
  })
}

export function reportUser(data) {
  return request({
    url: '/api/order/report_user/',
    method: 'get',
    data
  })
}

export function logoutting(data) {
  return request({
    url: '/api/logout',
    method: 'post',
    data
  })
}

// 注销用户

// 注销条件
export function cancellCondition(data = {}) {
  return request({
    url: '/externaladmin/authService/user/cancellationCondition',
    method: 'post',
    data
  })
}

// 要注销的用户的信息
export function cancellUserInfo(data = {}) {
  return request({
    url: '/externaladmin/authService/user/cancellationUserInfo',
    method: 'post',
    data
  })
}

// 获取短信接口
export function getPhoneMessage(data) {
  return request({
    url: '/externaladmin/messageService/messageSendRecord/sendPhoneMessage',
    method: 'post',
    data
  })
}

// 确认注销
export function confirmCancell(data) {
  return request({
    url: '/externaladmin/authService/user/confirmCancellation',
    method: 'post',
    data
  })
}

// 确认注销 自主注销
export function userConfirmCancell(data) {
  return request({
    url: '/externaladmin/authService/user/activeCancellation',
    method: 'post',
    data
  })
}

// 确认注销校验
export function activeCancellationValidate(data) {
  return request({
    url: '/externaladmin/authService/user/activeCancellationValidate',
    method: 'post',
    data
  })
}

// 注销管理
// 查询注销用户列表
export function cancellationUserList(data, menuType = 'menu') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/user/cancellationUserList',
    method: 'post',
    data
  })
}
// 查询注销用户信息
export function cancellationUserInfo(data, menuType = 'menu') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/user/cancellationUserInfo',
    method: 'post',
    data
  })
}

// 发送警告
export function sendWarning(data = {}) {
  return request({
    url: '/externaladmin/authService/userWarningRecord/warning',
    method: 'post',
    data
  })
}
// 冻结解冻
export function userFreeze(data = {}) {
  return request({
    url: '/externaladmin/authService/userFreezeRecord/freeze',
    method: 'post',
    data
  })
}

// 查询注销用户历史记录

export function balanceChangeRecordList(data) {
  return request({
    url: '/externaladmin/financeService/paymentAccount/balanceChangeRecordList',
    method: 'post',
    data
  })
}
// 获取冻结用户信息
export function getFreezeInfo(data = {}) {
  return request({
    url: '/externaladmin/authService/userFreezeRecord/getFreezeInfo',
    method: 'post',
    data
  })
}
// 删除账号
export function deleteAccount(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/user/deleteAccount',
    method: 'post',
    data
  })
}
