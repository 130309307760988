var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDialog',{attrs:{"maxHeight":"60vh","width":"1200px","title":"任务列表","dialogVisible":_vm.dialog},on:{"update:dialogVisible":function($event){_vm.dialog=$event},"update:dialog-visible":function($event){_vm.dialog=$event},"closed":function($event){return _vm.SET_TASK_DIALOG(false)}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('span')]},proxy:true}])},[[_c('CommonTable',{attrs:{"height":"auto","selection":false,"cols":_vm.cols,"infoData":_vm.data},scopedSlots:_vm._u([{key:"statusSlot",fn:function(ref){
var row = ref.scoped;
return [_c('el-tag',{attrs:{"size":"small","type":_vm.UP_STATUS[row[row.prop]].type}},[(row[row.prop] < 3)?_c('i',{staticClass:"el-icon-loading"}):_vm._e(),_vm._v(" "+_vm._s(_vm.UP_STATUS[row[row.prop]].label)+" ")])]}},{key:"thumbnailPathSlot",fn:function(ref){
var row = ref.scoped;
return [_c('div',{staticStyle:{"text-align":"center"}},[_c('CacheImg',{staticStyle:{"witdh":"50px","height":"50px"},attrs:{"fit":"contain","raw":row[row.prop]}})],1),_c('el-button',{staticStyle:{"white-space":"normal"},attrs:{"type":"text","size":"mini"}},[_vm._v(" "+_vm._s(row.name)+" ")])]}},{key:"categoryNameSlot",fn:function(ref){
var row = ref.scoped;
return [_vm._v(" "+_vm._s(row[row.prop] || '默认')+" ")]}},{key:"totalSlot",fn:function(ref){
var row = ref.scoped;
return [_c('el-tag',{attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(_vm.size(row))+" ")])]}},{key:"progressSlot",fn:function(ref){
var row = ref.scoped;
return [_c('el-progress',{key:row.id,attrs:{"color":_vm.customColors,"text-inside":true,"stroke-width":15,"percentage":_vm.percentage(row)}})]}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }