import { initData } from '@/api/data'
import { deepClone, getDiffData } from '@/components/avue/utils/util'
import camelCase from 'lodash/camelCase'

export default {
  data() {
    return {
      tableLoading: true,
      selectedData: [],
      data: [],
      page: 1,
      size: 10,
      total: 0,
      isPageChange: false,
      params: {},
      mergeData: this.resetMergeData || {},
      query: {},
      time: 170,
      initBool: true,
      method: 'post',
      infiniteScroll: false
    }
  },
  mounted() {
    if (this.$refs.paginationBar) {
      this.$refs.paginationBar.pagingInfo.size = this.size
    }
    if (this.initBool) {
      this.init()
    }
  },
  activated() {
    // 第一次加载时会执行activated生命周期
    // deactivated为true时，表示组件已缓存，此时可以执行init更新数据
    if (this.deactivated) {
      this.refreshPage()
    }
  },
  deactivated() {
    this.deactivated = true
  },
  methods: {
    handleSelectionChange(selection) {
      this.selectedData = selection
    },
    async beforeInit() {
      if (this.beforeInitCallback) {
        await this.beforeInitCallback()
      }
      if (!this.url) {
        throw new Error('需要重置url')
      }
      let { query, handleSearchFormProps } = this
      this.params = { page: this.page, size: this.size }
      const tmpObj = {}
      for (let key in query) {
        let value = query[key]
        if (value !== undefined && value !== 'all') {
          if (handleSearchFormProps) {
            if (handleSearchFormProps[key]) {
              let curVal = handleSearchFormProps[key](value, query)
              if (curVal && curVal.$isMergeData) {
                delete curVal.$isMergeData
                Object.assign(tmpObj, curVal)
              } else {
                tmpObj[key] = curVal
              }
              continue
            }
          }

          tmpObj[key] = value
        }
      }
      //排序的空字符串处理

      if (Object.keys(tmpObj).length) {
        const _this = this.$refs.paginationBar
        for (let key in tmpObj) {
          this.params[key] = tmpObj[key]
        }
        _this && _this.reFreshpage && _this.reFreshpage(this.page)
      }

      let { mergeData } = this
      for (let key in mergeData) {
        let value = mergeData[key]
        if (value || value === 0 || value === false) {
          this.params[key] = value
        }
      }
      // console.log(JSON.stringify(mergeData))

      this.toPostParams()
      return true
    },
    toPostParams() {
      if (this.method.toUpperCase() === 'POST') {
        const { page, size, ...rest } = this.params
        const data = {
          page: {
            pageIndex: page,
            pageSize: size
          }
        }
        const params = {}
        Object.assign(params, data, rest)
        let tempObj = {}
        for (const key in params) {
          let value = params[key]
          if (value || value === 0 || value === false) {
            tempObj[key] = value
          }
        }
        this.params = tempObj
      }
    },

    initCallBack() {},
    
    async init() {
      // console.log(JSON.stringify(this.query), JSON.stringify(this.prevParams))
      // 搜索表单必须放在this.query，为了在搜索表单改变时，将this.page设为1
      let queryDiffData = getDiffData(this.query, this.prevParams || {})
      this.prevParams = deepClone(this.query)
      if (queryDiffData) {
        this.page = 1
      }
      // console.log(queryDiffData)
      if (!(await this.beforeInit())) return
      // console.log('init', JSON.stringify(this.mergeData))
      if (typeof this.beforeInitData === 'function') {
        await this.beforeInitData()
      }

      return new Promise((resolve, reject) => {
        this.tableLoading = true
        this.selectedData = []
        //无限滚动
        if (!this.infiniteScroll) {
          this.data = []
        }
        let curRequest = this.lastRequest = initData(this.url, this.method, this.params, this.xMenuType)
        curRequest
          .then((res) => {
            this.total = (res.detail && res.detail.count) || (res.page && res.page.total) || 0
            let resData = (res.detail && res.detail.results) || (Array.isArray(res.detail) ? res.detail : [])
            resData = this.handleTableData(resData)
            if (!this.infiniteScroll) {
              // 防止因为前一次请求比最近请求晚响应，导致前一次请求数据覆盖最近请求数据
              if (this.lastRequest !== curRequest) return
              this.lastRequest = null
              
              this.data = resData
            } else {
              //无限滚动 data叠加
              this.data = this.data.concat(resData)
            }
            // 列表数据发生改变，当前页码（大于1）的数据为0，则返回第一页
            if (!this.special) {
              if (this.page > 1 && this.data.length === 0) {
                this.page = 1
                this.init()
              }
            }
            setTimeout(() => {
              this.tableLoading = false
            }, this.time)
            this.initCallBack && this.initCallBack(res, this.params)
            resolve(res)
          })
          .catch((err) => {
            this.tableLoading = false
            this.initCallBack && this.initCallBack({}, this.params)
            reject(err)
          })
      })
    },
    handleTableData(detail) {
      return detail
    },
    refreshPage() {
      this.init()
    },
    searchChange() {
      this.page = 1
      this.init()
    },
    pageChange(e) {
      this.setIsPageChangeTrue()
      this.page = e
      this.init()
    },
    sizeChange(e) {
      this.setIsPageChangeTrue()
      this.page = 1
      this.size = e
      this.init()
    },
    refreshTableEventFun({ page, size }) {
      this.setIsPageChangeTrue()
      this.page = page
      this.size = size
      this.init()
    },
    setIsPageChangeTrue() {
      this.isPageChange = true
      clearTimeout(this.isPageChangeTimer)
    },
    setIsPageChangeFalse() {
      clearTimeout(this.isPageChangeTimer)
      this.$nextTick(function () {
        this.isPageChangeTimer = setTimeout(() => (this.isPageChange = false), 500)
      })
    },
    getTimeProps(key) {
      if (/Time|Date/.test(key)) {
        return {
          startProp: this.getJavaDirTime(key, 'Start'),
          endProp: this.getJavaDirTime(key, 'End')
        }
      }
      if (/_time|_date/.test(key)) {
        return {
          startProp: camelCase(`start_${key}`),
          endProp: camelCase(`end_${key}`)
        }
      }
    },
    getJavaDirTime(key, fill) {
      return key.replace(/(?=(Time|Date))/, fill)
    },
    handleSearchTime(key, value) {
      let { startProp, endProp } = this.getTimeProps(key) || {}
      if (!startProp || !endProp) return {}
      if (!Array.isArray(value) || !value.length) return {
        [startProp]: undefined,
        [endProp]: undefined
      }
    
      let startTime = value[0]
      if (startTime.split(' ').length < 2) startTime = `${startTime} 00:00:00`
      let endTime = value[1]
      if (endTime.split(' ').length < 2) endTime = `${endTime} 23:59:59`
    
      return {
        [startProp]: startTime,
        [endProp]: endTime
      }
    }
  }
}
