import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

//登录
export function login(data) {
  return request({
    url: '/externaladmin/authService/user/login',
    method: 'post',
    data
  })
}

//刷新token
export function refreshToken() {
  return request({
    headers: getXMenuType(),
    url: '/externaladmin/authService/user/refreshToken',
    method: 'post'
  })
}

//检查token是否有效
export function verifyToken(data) {
  return request({
    url: '/api/verify/',
    method: 'post',
    data
  })
}

export function getInfo() {
  return request({
    url: '/externaladmin/authService/user/getUserInfo',
    method: 'post',
    headers: getXMenuType('menu')
  })
}

export function buildMenus() {
  return request({
    url: '/externaladmin/authService/user/getAllAuth',
    method: 'post'
  })
}

// 退出登录调用接口
export function logoutting() {
  return request({
    headers: getXMenuType(),
    url: '/externaladmin/authService/user/logout',
    method: 'post'
  })
}
