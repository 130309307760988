import { isPrice } from '@/utils'

/**
 * 将数据转化为数字格式
 * @param {any} num
 * @returns {Number}
 * @example see parsePrice
 */
export function parseNumber(num) {
  return parseFloat(num || 0)
}
/**
 * 将数据转化为金额格式
 * @param {String|Number} price
 * @param {Number} fixed
 * @param {String} unit
 * @returns {String}
 * @example see @/views/order/module/orderGroupBasePage.vue
 */
export function parsePrice(price, fixed = 2, unit = '￥') {
  // console.log('parsePrice', price, fixed, unit)
  price = price || 0
  if (isPrice(price)) return unit + parseNumber(price).toFixed(fixed)
  return unit + price
}

/**
 * 将时间公司转化为时间展示格式
 * @param {time} time
 * @returns {String}
 */
export function parseTime(time) {
  if (time) {
    var date = new Date(time)
    var year = date.getFullYear()
    /* 在日期格式中，月份是从0开始的，因此要加0
     * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
     * */
    var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    // 拼接
    return year + '年' + month + '月' + day + '日 ' + hours + ':' + minutes + ':' + seconds
  } else {
    return ''
  }
}

/**
 * 将时间公司转化为时间展示格式
 * @param {time} time
 * @returns {String}
 */
export function parseCharacterTime(time) {
  if (time) {
    var date = new Date(time)
    var year = date.getFullYear()
    /* 在日期格式中，月份是从0开始的，因此要加0
     * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
     * */
    var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    // 拼接
    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
  } else {
    return ''
  }
}

export function parseDay(time) {
  return parseTime(time).split(' ')[0]
}

export function parseImgSrc(src) {
  return encodeURI(src).replace(/\+/g, '%2B')
}

export function formatTime(time, option) {
  time = +time * 1000
  const d = new Date(time)
  const now = Date.now()
  
  const diff = (now - d) / 1000
  
  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
  }
}

export const timeNum = () => {
  return parseTime(Date.now()).replace(/[^\d]/g, '')
}

/**
 * @description:  数字转中文
 * @param:  @number {Integer} 形如123的数字
 * @return: @return {String} 返回转换成的形如 一百二十三 的字符串
 */
export function numberToChinese(number) {
  var a = (number + '').split(''), s = [], t = {
    /* 单位 */
    units: '个十百千万@#%亿^&~' ,
    /* 字符 */
    chars: '零一二三四五六七八九'
  }
  if (a.length > 12) {
    throw  new Error('too big')
  } else {
    for (var i = 0, j = a.length - 1; i <= j; i++) {
      if (j == 1 || j == 5 || j == 9) { //两位数 处理特殊的 1*
        if (i == 0) {
          if (a[i] != '1') s.push(t.chars.charAt(a[i]))
        } else {
          s.push(t.chars.charAt(a[i]))
        }
      } else {
        s.push(t.chars.charAt(a[i]))
      }
      if (i != j) {
        s.push(t.units.charAt(j - i))
      }
    }
  }
  //return s;
  return s.join('').replace(/零([十百千万亿@ #%^&~])/g, function (m, d, b) {//优先处理 零百 零千 等
    b = t.units.indexOf(d)
    if (b != -1) {
      if (d == '亿') return d
      if (d == '万') return d
      if (a[j - b] == '0') return '零'
    }
    return ''
  }).replace(/零+/g, '零').replace(/零([万亿])/g, function (m, b) { // 零百 零千处理后 可能出现 零零相连的 再处理结尾为零的
    return b
  }).replace(/亿[万千百]/g, '亿').replace(/[零]$/, '').replace(/[@ #%^&~]/g, function (m) {
    return { '@': '十', '#': '百', '%': '千', '^': '十', '&': '百', '~': '千' }[m]
  }).replace(/([亿万])([一-九])/g, function (m, d, b, c) {
    c = t.units.indexOf(d)
    if (c != -1) {
      if (a[j - c] == '0') return d + '零' + b
    }
    return m
  })
}