/**
 * 获取总价格
 * @param order
 * @param fixedNum
 * @returns {Number|string|*|string|string}
 */
export function getTotalPrice(order, fixedNum = 2) {
  const externalOrderFinance = order.externalOrderFinance
  if (!externalOrderFinance) return
  const paymentFreight = $GET(externalOrderFinance, 'paymentFreight', 0)
  const paymentProduct = $GET(externalOrderFinance, 'paymentProduct', 0)
  const couponAmount = $GET(externalOrderFinance, 'couponAmount', 0)
  return (paymentFreight + paymentProduct - couponAmount).toFixed(fixedNum)
}

/**
 * 获取运费
 * @param order
 * @returns {*}
 */
export function getPaymentFreight(order) {
  return $GET(order, 'externalOrderFinance.paymentFreight', 0)
}

/**
 * 优惠
 * @param order
 * @returns {*}
 */
export function getCouponAmount(order) {
  return $GET(order, 'externalOrderFinance.couponAmount', 0)
}
