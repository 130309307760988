<template>
  <div :class="[shake && 'shake', 'missionComponent']">
    <div class="count" v-show="finalData.length">
      <span v-if="finalData.length <= 99">{{ finalData.filter(({ status }) => 3 > status).length }}</span>
      <span v-else-if="finalData.length > 99">99</span>
    </div>
    <el-button
      type="info"
      icon="el-icon-menu"
      style="position: absolute; top: -20px; right: -52px"
      size="large"
      circle
      @click="showHandler"
    ></el-button>
    <EForm ref="form" :sup_this="sup_this" :data="finalData" />
  </div>
</template>

<script>
import EForm from './module/form'
import { mapGetters, mapMutations } from 'vuex'
import { UP_STATUS } from '@/utils/constant'

export default {
  components: {
    EForm
  },
  data() {
    return {
      sup_this: this,
      shake: false
    }
  },
  watch: {
    data() {
      this.shake = true
      this.shakeTimer && clearTimeout(this.shakeTimer)
      this.shakeTimer = setTimeout(() => {
        this.shake = false
      }, 2000)
    }
  },
  computed: {
    ...mapGetters({
      data: 'tasks'
    }),

    finalData({ data }) {
      return data.filter(({ status }) => status == UP_STATUS[3].value)
    }
  },

  methods: {
    ...mapMutations(['SET_TASK_DIALOG']),
    showHandler() {
      this.SET_TASK_DIALOG(true)
      // const _this = this.$refs.form
      // _this.dialog = true
      this.$emit('success')
    }
  }
}
</script>

<style lang="scss" scoped>
.missionComponent {
  position: fixed;
  right: 50px;
  bottom: 20px;
  z-index: 100;
  .count {
    position: absolute;
    top: -21px;
    right: -52px;
    display: flex;
    z-index: 999;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    color: #fff;
    font-size: 12px;
    line-height: 1;
    background: #ff5733;
    border-radius: 50%;
  }
}
.missionComponent.shake {
  animation: shake 1s 2;
}
@keyframes shake {
  0%,
  100% {
    -webkit-transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translateX(-5px);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translateX(5px);
  }
}
</style>
