import cloneDeep from 'lodash/cloneDeep'

const design = {
  state: {
    customFloorData: {},
    customDesignInstance: null,
    curFabircActiveObject: null, //当前激活对象
    customFloorRenderFinish: true,
    fabricObjects: [],
    curFabircActiveObjectAngle: 0, //当前旋转角度
    curFabircActiveObjectScale: {
      scaleX: 0,
      scaleY: 0
    } //当前对象
  },
  mutations: {
    /**
     * @description: 渲染是否结束
     * @param {*}
     * @return {*}
     */
    SET_CUSTOM_DESIGN_INSTANCE(state, customDesignInstance) {
      state.customDesignInstance = customDesignInstance
    },

    /**
     * @description: 设置编辑初始数据
     * @param {*}
     * @return {*}
     */
    SET_CUSTOM_FLOOR_DATA(state, customFloorData) {
      state.customFloorData = customFloorData
    },

    SET_FABRIC_OBJECTS(state, objects) {
      state.fabricObjects = objects
    },

    SET_CUSTOM_FLOOR_RENDER_FINISH(state, customFloorRenderFinish) {
      state.customFloorRenderFinish = customFloorRenderFinish
    },

    //更新角度
    UPDATE_CUR_FABRIC_ACTIVE_OBJECT_ANGLE(state, curFabircActiveObjectAngle) {
      state.curFabircActiveObjectAngle = curFabircActiveObjectAngle
    },

    //更新缩放
    UPDATE_CUR_FABRIC_ACTIVE_OBJECT_SCALE(state, curFabircActiveObjectScale) {
      state.curFabircActiveObjectScale = curFabircActiveObjectScale
    },

    //更新对象
    UPDATE_CUR_FABRIC_ACTIVE_OBJECT(state, curFabircActiveObject) {
      state.curFabircActiveObject = curFabircActiveObject
    },
    
    //清除定制所有信息
    CLEAR_DESIGN_STATE(state) {
      for (let key in cloneDesignState) {
        state[key] = cloneDesignState[key]
      }
    }
  },
  actions: {}
}

//保留文件初始化时的state对象
export const cloneDesignState = cloneDeep(design.state)

export default design
