<template>
  <el-button :loading="loading" :disabled="isDisabled" v-bind="$attrs" v-on="new$listeners">
    <slot :loading="loading"></slot>
  </el-button>
</template>

<script>
export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    new$listeners() {
      return Object.assign(
        {
          ...this.$listeners
        },
        {
          click: this.onclick
        }
      )
    }
  },
  methods: {
    async onclick(e) {
      if (this.loading) return
      this.loading = true
      try {
        await this.$listeners.click.fns(e)
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    }
  }
}
</script>

<style></style>
