export async function handleSelectionData(that, param, callback) {
  param = validateSelectionData(that, param)
  if (!param) return
  typeof callback === 'function' && await callback(param, that)
  return param
}
export function validateSelectionData(that, param = 'batch') {
  if (param === 'batch') {
    if (!that.selectionDataAllArr.length) {
      that.$message.warning('请至少选择一条数据')
      return false
    }
    param = that.getSelectionDataAllArr
  }
  return getSelectionData(param)
}
export function getSelectionData(selectionData) {
  if (typeof selectionData === 'function') return selectionData
  selectionData = Array.isArray(selectionData) ? selectionData : [selectionData]
  return () => selectionData
}