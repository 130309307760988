// orderType 订单类型 1为默认值，1为普通订单，2为FBA订单，3为速卖通同步订单,4现货订单
export const ORDER_TYPE_COMMON = 1
export const ORDER_TYPE_FBA = 2
export const ORDER_TYPE_SMT = 3
export const ORDER_TYPE_SPOT = 4
export const ORDER_TYPE_CART = 6

export const ORDER_TYPE_DIC = [
  {
    label: '普通订单',
    value: 1
  },
  {
    label: 'FBA订单',
    value: ORDER_TYPE_FBA
  },
  {
    label: '店铺订单',
    value: ORDER_TYPE_SMT
  },
  {
    label: '现货订单',
    value: ORDER_TYPE_SPOT
  }
]

// isCartOrder 是否是购物车订单 0:否 1:是
export const IS_CART_ORDER = 1
export const NOT_IS_CART_ORDER = 0

export const CART_ORDER_DIC = [
  {
    label: '是',
    value: IS_CART_ORDER
  },
  {
    label: '否',
    value: NOT_IS_CART_ORDER
  }
]

//换单状态
export const WAYBILL_CHANGE_STATUS_DIC = [
  {
    label: '系统换单',
    value: '2'
  },
  {
    label: '手动换单',
    value: '1'
  },
  {
    label: '未换单',
    value: '0'
  }
]
