import { validatenull } from '@/components/avue/utils/validate'
import { validData } from '@/components/avue/utils/util'
import { accAdd } from '@/utils'

/**
* @description: 获取表格列数
* @param: option {Object}
* @return: length {Number}
*/
export function getColumnLenByOption(option) {
  if (validatenull(option)) return 0
  let len = 0
  option.menu !== false && len++ // menu默认为true
  option.expand && len++
  option.selection && len++
  option.index && len++
  
  len += getColumnVisibleList(option.column).length
  return len
}

export function getColumnVisibleList(column) {
  if (validatenull(column)) return []
  return column.filter((column) => !column.hide)
}

export function handleSearchOption(option, isCrud = true) {
  let defaultWidths = {
    selectInput: 368,
    daterange: 256,
    input: 256,
    select: 256
  }
  
  let labelWidthKey = isCrud ? 'searchLabelWidth' : 'labelWidth'
  let widthKey = isCrud ? 'searchWidth' : 'width'
  
  let { [labelWidthKey]: labelWidth } = option
  option.column.forEach((column) => {
    if (isCrud && !column.search) return
    
    let type = validData(column.type, column.searchType, 'input')
    
    if (validatenull(column[labelWidthKey])) {
      // label 不存在表示该项不是单行
      column[labelWidthKey] = column.label ? labelWidth : 20
    }
    
    if (validatenull(column[widthKey]) && validatenull(column.span)) {
      let defaultWidth = defaultWidths[type]
      defaultWidth && (column[widthKey] = accAdd(defaultWidth, column[labelWidthKey]))
    }
    
    if (type === 'input') {
      column.prefixIcon = validData(column.prefixIcon, 'el-icon-search')
    }
  })
}