<template>
  <avue-form
    ref="form"
    class="base-form"
    :class="{ 'label-justify': finalOption.isSearch }"
    :option="finalOption"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="slot in scopedSlots" v-slot:[slot.prop]="scope">
      <avueCrudTooltip
        v-if="['tooltip', 'text'].includes(slot.type)"
        :content="tooltipFormatter(scope, slot)"
      ></avueCrudTooltip>
      <component
        v-else-if="components[slot.type]"
        :is="components[slot.type]"
        v-model="scope.form[slot.prop]"
        :form="scope.form"
        :list="setDic(slot, finalOption.dic)"
        :dic="setDic(slot, finalOption.dic)"
        :column="scope.column"
        v-bind="slot"
        @change="searchChange"
      ></component>
      <slot v-else :name="slot.prop" v-bind="scope"></slot>
    </template>
  </avue-form>
</template>

<script>
import { componentMethodsMixin } from '@/mixins'
import Vue from 'vue'

import defaultImg from '@/views/components/defaultImg'
import selectInput from './module/selectInput'
import inputSelect from './module/inputSelect'

import { setDic, validData, findByvalue } from '@/components/avue/utils/util'
import { validatenull } from '@/components/avue/utils/validate'
import { accAdd } from '@/utils'
import { cloneDeep } from 'lodash'

export default {
  inheritAttrs: false,
  mixins: [
    componentMethodsMixin(
      'form',
      [
        'resetForm',
        'validate',
        'resetFields',
        'validateField',
        'clearValidate',
        'submit'
      ]
    )
  ],
  props: {
    option: {
      type: Object,
      default: () => ({})
    },
    group: Array,
    column: Array,
    dic: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      DIC: {}
    }
  },
  computed: {
    finalOption({ reactiveOption, finalGroup }) {
      let tempObj = reactiveOption.group
        ? {
          group: finalGroup.length ? finalGroup : undefined,
          column: undefined
        }
        : {
          ...finalGroup[0],
          group: undefined
        }

      return {
        ...reactiveOption,
        ...tempObj,
        dic: {
          // this.$attrs.dic放进finalOption的时候在v-for baseForm 的时候会造成finalOption无限循环：去掉$attrs则正常
          ...this.dic,
          ...reactiveOption.dic
        }
      }
    },
    finalGroup({ reactiveOption }) {
      let group = reactiveOption.group || []
      reactiveOption.column && group.push({
        ...reactiveOption,
        group: undefined,
        dic: undefined
      })
      return group.map(item => {
        return {
          ...item,
          labelWidth: validData(item.labelWidth, reactiveOption.labelWidth)
        }
      })
    },
    // 用于设置异步的数据，如：HandleOption取dicData为异步操作，需要设置响应式以便取值后更新option
    reactiveOption({ optionClone }) {
      this.$store.dispatch('HandleOption', optionClone)
      return Vue.observable(optionClone)
    },
    // 设置默认值
    optionClone({ option, group, column }) {
      let isSearch = validData(option.isSearch, false)
      return {
        ...option,
        group: cloneDeep(validData(group, option.group)),
        column: cloneDeep(validData(column, option.column)),
        isSearch,
        labelSuffix: validData(option.labelSuffix, !isSearch),
        labelWidth: validData(option.labelWidth, 82),
        menuBtn: validData(option.menuBtn, false),
        span: validData(option.span, 24)
      }
    },

    scopedSlots({ $scopedSlots, finalGroup }) {
      let scopedSlots = Object.keys($scopedSlots)
      let tempArr = scopedSlots.map(prop => ({ prop }))

      let addSlots = []
      finalGroup.forEach(item => {
        item.column.forEach(column => {
          // 有设置插槽则开启表单插槽功能
          if (tempArr.some(({ prop }) => prop === column.prop)) {
            column.formslot = true
            column.type = 'auto'
          }

          // 类型为已注册组件则开启表单插槽功能
          if (this.componentKeys.includes(column.type)) {
            column.formslot = true
            addSlots.push(column)
          }
        })
      })

      return tempArr.concat(addSlots)
    },
    componentKeys({ components }) {
      return Object.keys(components)
    },
    components() {
      return {
        defaultImg,
        inputSelect,
        selectInput,
        tooltip: 'avueCrudTooltip',
        text: 'avueCrudTooltip'
      }
    }
  },
  watch: {
    reactiveOption: {
      handler() {
        this.handleSearchColumn()
        this.handleTextColumn()
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleSearchColumn() {
      if (!this.finalOption.isSearch) return
      let { finalGroup } = this

      let defaultWidths = {
        selectInput: 368,
        daterange: 256,
        input: 256,
        select: 256
      }

      finalGroup.forEach(item => {
        let { labelWidth } = item
        item.column.forEach((column) => {
          let type = validData(column.type, 'input')

          if (validatenull(column.labelWidth)) {
            // label 不存在表示该项不是单行
            column.labelWidth = column.label ? labelWidth : 20
          }

          if (validatenull(column.width) && validatenull(column.span)) {
            let defaultWidth = defaultWidths[type]
            defaultWidth && (column.width = accAdd(defaultWidth, column.labelWidth))
          }

          if (type === 'input') {
            column.prefixIcon = validData(column.prefixIcon, 'el-icon-search')
          }
        })
      })
    },
    handleTextColumn() {
      let { finalGroup, finalOption: { isText } } = this

      finalGroup.forEach((item) => {
        if (!validData(item.isText, isText)) return
        item.column.forEach((column) => {
          this.$set(column, 'type', validData(column.type, 'text'))
        })
      })
    },

    searchChange() {
      // console.log('onsubmit')
      if (this.finalOption.isSearch) {
        // console.log('searchChange')
        return this.submit()
      }
    },

    tooltipFormatter({ form, column, column: { formatter, props } }, { prop }) {
      let result = form[prop]
      if (typeof formatter === 'function') result = formatter(form, prop, result)
      if (column.type) {
        result = findByvalue(
          setDic(column, this.finalOption.dic),
          result,
          props
        )
      }
      return result
    },
    setDic
  }
}
</script>

<style lang="scss" scoped>
.label-justify ::v-deep {
  .select-input {
    margin-top: 4px;
  }
}
</style>
