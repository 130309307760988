<template>
  <div class="crud-container pull-auto">
    <slot name="headerBefore"></slot>
    <header-search v-if="option.search !== false && searchSlot.length" ref="headerSearch" :search="search">
      <template slot="searchMenu" slot-scope="scope">
        <slot name="searchMenu" v-bind="scope"></slot>
      </template>
      <template slot-scope="scope" v-for="item in searchSlot" :slot="item">
        <slot v-bind="scope" :name="item"></slot>
      </template>
    </header-search>
    <slot name="headerAfter"></slot>
    <paginationOperation>
      <template #left>
        <slot name="menuLeftBefore"></slot>
        <el-button v-if="vaildData(option.addBtn, false)" type="primary" :size="DEFAULT_TABLE_SIZE" @click="rowAdd">
          {{ option.addBtnText || '新增' }}
        </el-button>
        <slot name="menuLeft"></slot>
      </template>
      <template #right>
        <slot name="menuRight"></slot>
        <template v-if="columnBtn">
          <columnVisibleDialog :columnList="columnList" @submit="columnVisibleSubmit">
            <template #columnBtn>
              <slot name="columnBtn"></slot>
            </template>
          </columnVisibleDialog>
        </template>
      </template>
      <el-pagination
        v-if="vaildData(option.topPage, true) && !validatenull(page)"
        class="crud-pagination crud-pagination-top"
        :current-page.sync="page.pageIndex"
        :background="vaildData(option.pageBackground, true)"
        :page-sizes="pageSizes"
        :page-size="page.pageSize"
        :hide-on-single-page="hideOnSinglePage"
        @size-change="sizeChange"
        @current-change="currentChange"
        layout="total, slot, prev, pager, next, jumper"
        :total="page.total"
      >
        <span class="el-pagination__sizes mr10">
          <el-select
            :value="page.pageSize"
            size="mini"
            placeholder="请选择"
            :popper-append-to-body="vaildData(option.paginationPopperAppendToBody, true)"
            @change="sizeChange"
          >
            <el-option v-for="item in pageSizes" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </span>
      </el-pagination>
    </paginationOperation>
    <slot name="header"></slot>
    <el-table
      ref="table"
      :data="data"
      :row-key="option.rowKey"
      :expand-row-keys="expandRowKeys"
      :lazy="lazy"
      :load="load"
      :tree-props="vaildData(option.treeProps, { children: 'children', hasChildren: 'hasChildren' })"
      v-loading="tableLoading"
      :stripe="option.stripe"
      :show-header="option.showHeader"
      :default-sort="option.defaultSort"
      :show-summary="showSummary"
      :summary-method="tableSummaryMethod"
      :max-height="option.maxHeight"
      :height="option.height == 'auto' ? $AVUE.clientHeight - vaildData(option.calcHeight, 275) : option.height"
      :width="setPx(option.width, '100%')"
      :border="vaildData(option.border, DEFAULT_TABLE_BORDER)"
      :row-class-name="tableRowClassName"
      :cell-class-name="tableCellClassName"
      :header-cell-class-name="tableHeaderCellClassName"
      :span-method="tableSpanMethod"
      @row-click="rowClick"
      @row-dblclick="rowDblclick"
      @sort-change="sortChange"
      @select="select"
      @select-all="selectAll"
      @selection-change="selectionChange"
    >
      <!-- 下拉弹出框  -->
      <template v-if="option.expand">
        <el-table-column key="expand" type="expand" width="50" fixed="left" align="center">
          <template slot-scope="props">
            <slot :row="props.row" name="expand"></slot>
          </template>
        </el-table-column>
      </template>
      <!-- 选择框 -->
      <template v-if="option.selection">
        <el-table-column
          key="selection"
          prop="selection"
          type="selection"
          width="50"
          align="center"
          :selectable="option.selectable"
          :reserve-selection="option.reserveSelection"
        ></el-table-column>
      </template>

      <!-- 序号 -->
      <template v-if="option.index">
        <el-table-column
          key="index"
          :label="vaildData(option.indexLabel, '序号')"
          type="index"
          :index="indexMethod"
          width="50"
          fixed="left"
          align="center"
        ></el-table-column>
      </template>
      <!-- 循环列 -->
      <el-table-column
        v-for="(column, index) in tableColumn"
        :key="column.$sortKey"
        :prop="column.prop"
        :show-overflow-tooltip="vaildData(column.showOverflowTooltip, column.overHidden)"
        :min-width="column.minWidth"
        :sortable="column.sortable"
        :align="vaildData(column.align, option.align)"
        :header-align="vaildData(column.headerAlign, option.headerAlign)"
        :render-header="renderHeader"
        :width="column.width"
        :label="column.label"
        :fixed="column.fixed"
      >
        <template slot="header" slot-scope="scope">
          <slot
            v-if="column.headerSlot"
            :name="column.prop + 'Header'"
            :dic="setDic(column, DIC)"
            v-bind="scope"
          ></slot>
          <template v-else>{{ column.label }}</template>
        </template>
        <template slot-scope="scope">
          <lazyComponent
            :lazy="vaildData(column.lazy, option.lazy)"
            :scrollContainer="option.scrollContainer"
            :isOnePage="option.isOnePage"
            :sign="scope.$index"
            :height="vaildData(column.lazyHeight, option.lazyHeight)"
          >
            <slot
              v-bind="scope"
              :dic="setDic(column, DIC)"
              :name="column.prop"
              v-if="column.slot"
            >
              {{ detail(scope.row, column) }}
            </slot>
            <template v-else>
              <span v-if="column.type || column.formatter" v-html="detail(scope.row, column)"></span>
              <span v-else>{{ vaildData(get(scope.row, column.prop), column.placeholder) }}</span>
<!--              存在报错信息-->
              <div class="text-danger" v-if="scope.row.asyncValidatorErrorList&&scope.row.asyncValidatorErrorList[`_${column.prop}_errMsg`]">
                {{scope.row.asyncValidatorErrorList[`_${column.prop}_errMsg`]}}
              </div>
            </template>
          </lazyComponent>
        </template>
      </el-table-column>
      <el-table-column
        v-if="vaildData(option.menu, true)"
        label="操作"
        class-name="table-operation"
        :fixed="option.menuFixed === 'normal' ? undefined : vaildData(option.menuFixed, 'right')"
        :align="vaildData(option.menuAlign, 'center')"
        :header-align="option.menuHeaderAlign"
        :width="vaildData(option.menuWidth, 240)"
      >
        <template slot-scope="scope">
          <lazyComponent
            :lazy="vaildData(option.menuLazy, option.lazy)"
            :scrollContainer="option.scrollContainer"
            :isOnePage="option.isOnePage"
            :sign="scope.$index"
            :height="option.lazyHeight"
          >
            <slot :row="scope.row" name="menu" :index="scope.$index" v-bind="scope"></slot>
            <template v-if="vaildData(option.menu, true)">
              <template v-if="vaildData(option.editBtn, true)">
                <el-button class="menu-btn-item" type="text" @click.stop.safe="rowEdit(scope.row, scope.$index)">修改</el-button>
              </template>
              <template v-if="vaildData(option.delBtn, true)">
                <Popover class="menu-btn-item" @sureHandler="rowDel($event, scope.row, scope.$index)">
                  <template #tip>
                    <p>{{ option.delTip || '您确定要删除该行数据么?' }}</p>
                  </template>
                  <template #reference="{ popoverLoading }">
                    <el-button type="text" danger :loading="popoverLoading">删除</el-button>
                  </template>
                </Popover>
              </template>
            </template>
          </lazyComponent>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      v-if="vaildData(option.page, true) && !validatenull(page)"
      class="crud-pagination crud-pagination-bottom pull-right"
      :current-page.sync="page.pageIndex"
      :background="vaildData(option.pageBackground, true)"
      :page-sizes="pageSizes"
      :page-size="page.pageSize"
      :hide-on-single-page="hideOnSinglePage"
      @size-change="sizeChange"
      @current-change="currentChange"
      :layout="$attrs.layout || 'total, slot, prev, pager, next, jumper'"
      :total="page.total"
    >
      <div class="crud-pagination-left">
        <slot name="page"></slot>
      </div>
      <span class="el-pagination__sizes mr10">
        <el-select
          :value="page.pageSize"
          size="mini"
          placeholder="请选择"
          :popper-append-to-body="vaildData(option.paginationPopperAppendToBody, true)"
          @change="sizeChange"
        >
          <el-option v-for="item in pageSizes" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </span>
    </el-pagination>
    <!-- 表单 -->
    <el-dialog
      v-if="boxVisible || boxVisibleLock"
      lock-scroll
      v-el-drag-dialog
      :fullscreen="vaildData(option.formFullscreen, false)"
      :modal-append-to-body="false"
      :append-to-body="true"
      :title="boxType == 0 ? option.addBtnText || '新增' : option.editBtnText || '修改'"
      :visible.sync="boxVisible"
      :width="vaildData(option.formWidth, '60%')"
      :before-close="hide"
      @close="dialogFormClose"
      @closed="boxVisibleLock = false"
    >
      <el-form
        ref="tableForm"
        class="crud-form"
        :model="tableForm"
        :label-width="setPx(option.labelWidth, 80)"
        :rules="tableFormRules"
        :size="vaildData(option.formSize, DEFAULT_TABLE_SIZE)"
        :validate-on-rule-change="vaildData(option.validateOnRuleChange, false)"
        @submit.native.prevent
      >
        <el-row :gutter="20" :span="24">
          <template v-for="(column, index) in dialogFormColumn">
            <el-col :span="column.span || option.dialogFormSpan || 12">
              <el-form-item
                :style="{ height: setPx(column.formHeight, 'auto') }"
                :label="vaildData(column.dialogFormLabel, column.label)"
                :prop="column.prop"
                :label-width="setPx(column.labelWidth, option.labelWidth || 80)"
              >
                <slot
                  :form="tableForm"
                  :prop="column.prop"
                  :value="tableForm[column.prop]"
                  :column="column"
                  :dic="setDic(column, DIC)"
                  :name="column.prop + 'Form'"
                  v-if="column.formslot"
                ></slot>
                <component
                  v-else
                  :is="getComponent(column.type)"
                  v-model="tableForm[column.prop]"
                  :column="column"
                  :precision="column.precision"
                  :height="setPx(column.formHeight, 'auto')"
                  :size="vaildData(column.size, DEFAULT_TABLE_SIZE)"
                  :clearable="column.clearable"
                  :type="column.type"
                  :props="column.props"
                  :minRows="column.minRows"
                  :maxRows="column.maxRows"
                  :emitPath="column.emitPath"
                  :checkStrictly="column.checkStrictly"
                  :show-all-levels="column.showAllLevels"
                  :placeholder="vaildData(column.dialogFormLabel, column.label)"
                  :dic="setDic(column, DIC)"
                  :disabled="
                    boxType == 0 ? vaildData(column.addDisabled, false) : vaildData(column.editDisabled, false)
                  "
                  :format="column.format"
                  :value-format="column.valueFormat"
                ></component>
              </el-form-item>
            </el-col>
          </template>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hide">取 消</el-button>
        <el-button type="primary" @click="rowUpdate" :loading="boxLoading" v-if="boxType == 1">确定</el-button>
        <el-button type="primary" @click="rowSave" :loading="boxLoading" v-else>确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { DEFAULT_TABLE_SIZE, DEFAULT_TABLE_BORDER } from '../../utils/const/config'
import crud from '../../mixins/crud.js'
import { validatenull } from '../../utils/validate.js'
import tableTemp from '../../utils/tableTemp.js'
import moment from 'moment'
import lazyComponent from '@/components/avue/crud/src/lazyComponent'
import columnVisibleDialog from './columnVisibleDialog'
import paginationOperation from './paginationOperation'
import headerSearch from './headerSearch'
import tableOperation from './tableOperation'
import { setDic } from '@/components/avue/utils/util'
import { get } from 'lodash'

export default {
  name: 'AvueCrud',
  mixins: [crud()],
  components: {
    paginationOperation,
    columnVisibleDialog,
    headerSearch,
    tableOperation,
    lazyComponent: {
      name: 'LazyComponentWrap',
      functional: true,
      render(h, { props, scopedSlots }) {
        if (!props.lazy) return scopedSlots.default?.()
        return h(
          lazyComponent,
          {
            props,
            scopedSlots
          }
        )
      }
    }
  },
  data() {
    return {
      DEFAULT_TABLE_SIZE,
      DEFAULT_TABLE_BORDER,
      hideOnSinglePage: false,

      overHidden: true,

      // 表格列显隐
      columnSortList: null,

      boxVisible: false,
      boxVisibleLock: false,
      boxLoading: false,
      boxType: 0,
      DIC: {},
      tableForm: {},
      tableFormRules: {},
      tableIndex: -1,
      tableSelect: []
    }
  },
  computed: {
    searchSlot() {
      return this.option.column.filter(column => column.search).map(column => `${column.prop}SearchForm`)
    },
    columnList() {
      return this.option.column.filter((column) => {
        return !column.hide
      })
    },
    tableColumn() {
      return this.columnVisibleList
        .filter((column, index) => {
          column.$sort = column.$sort === undefined ? index : column.$sort
          column.$sortKey = `${column.prop}-${column.$sort}`
          return column.$checked !== false
        })
        .sort((a, b) => a.$sort - b.$sort)
    },
    columnVisibleList() {
      return this.columnSortList || this.columnList
    },
    columnBtn() {
      return this.vaildData(this.option.columnBtn, false)
    },
    dialogFormColumn() {
      return this.option.column
        .filter((column) => {
          return this.boxType == 0 ? this.vaildData(column.addDisplay, true) : this.vaildData(column.editDisplay, true)
        })
        .map((column, index) => {
          column.dialogFormSort = this.vaildData(column.dialogFormSort, index)
          return column
        })
        .sort((a, b) => a.dialogFormSort - b.dialogFormSort)
    },

    pageSizes({ option: { pageSizes } }) {
      pageSizes = this.vaildData(pageSizes, [5, 10, 15, 20])
      return pageSizes.map((item) => {
        return {
          label: `${item}条/页`,
          value: item
        }
      })
    }
  },
  watch: {
    option: {
      handler(n, o) {
        this.hideOnSinglePage = this.vaildData(n.hideOnSinglePage, false)
        this.rulesInit()
        if (n !== o) {
          //初始化字典
          this.dicInit()
          //初始化表单
          this.formInit()
        }
      },
      immediate: true,
      deep: true
    },
    tableForm: {
      handler(n, o) {
        this.formVal()
      },
      deep: true
    },
    dic: {
      handler(dic) {
        Object.keys(dic).forEach((key) => {
          this.$set(this.DIC, key, dic[key])
        })
      },
      immediate: true
    }
  },
  created() {
    //规则初始化
    this.rulesInit()
    //初始化字典
    this.dicInit()
    //初始化表单
    this.formInit()
  },
  mounted() {},
  methods: {
    get,
    validatenull,
    rulesInit() {
      let {
        option: { searchSpan, searchLabelWidth, colWidth, colMinWidth }
      } = this
      this.tableFormRules = {}
      // this.option.dic = []
      this.option.column.forEach((ele) => {
        // this.getDicData(ele)
        this.getTableType(ele)
        // this.setCascaderItem('form', ele)

        ele.width = this.vaildData(ele.width, colWidth)
        ele.minWidth = this.vaildData(ele.minWidth, colMinWidth)
        ele.searchSpan = this.vaildData(ele.searchSpan, searchSpan)
        ele.searchLabelWidth = this.vaildData(ele.searchLabelWidth, searchLabelWidth)

        if (ele.rules) {
          let rules = Array.isArray(ele.rules) ? ele.rules : [ele.rules]
          this.tableFormRules[ele.prop] = rules.map((rule) => {
            if (rule.required === true) {
              return {
                message: `${ele.label || '该项'}必填`,
                ...rule
              }
            }
            return rule
          })
        }
      })
    },
    dicInit() {
      this.GetDic(this.option.dic).then((data) => {
        Object.keys(data).forEach((key) => {
          this.$set(this.DIC, key, data[key])
        })
      })
    },
    formVal() {
      this.$emit('input', this.tableForm)
    },
    formReset() {
      this.tableForm = {}
      // for (let o in this.tableForm) {
      //   if (this.tableForm[o] instanceof Array) {
      //     this.tableForm[o] = []
      //   } else if (typeof this.tableForm[o] === 'number') {
      //     this.tableForm[o] = 0
      //   } else {
      //     this.tableForm[o] = ''
      //   }
      // }
    },
    formInit() {
      const list = this.option.column
      let form = {}
      list.forEach((ele) => {
        if (['checkbox', 'daterange', 'cascader'].includes(ele.type)) {
          form[ele.prop] = []
        } else if (ele.type == 'number') {
          form[ele.prop] = 0
        } else {
          form[ele.prop] = ''
        }
        if (!validatenull(ele.value)) form[ele.prop] = ele.value
      })
      this.tableForm = Object.assign({}, form)
    },
    // 页大小回调
    sizeChange(val) {
      this.hideOnSinglePage = false
      this.$emit('size-change', val)
    },
    // 页码回调
    currentChange(val) {
      this.$emit('current-change', val)
    },
    // 选中实例
    toggleSelection(rows, selected) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.table.toggleRowSelection(row, selected)
        })
      } else {
        this.$refs.table.clearSelection()
      }
    },
    //选择回调
    selectionChange(val) {
      this.tableSelect = val
      this.$emit('selection-change', val)
    },
    select(selection, row) {
      this.$emit('select', selection, row)
    },
    selectAll(selection) {
      this.$emit('select-all', selection)
    },
    //排序回调
    sortChange(val) {
      this.$emit('sort-change', val)
    },

    tableSummaryMethod(param) {
      if (typeof this.summaryMethod === 'function') return this.summaryMethod(param)
    },
    //合并行
    tableSpanMethod(param) {
      if (typeof this.spanMethod === 'function') return this.spanMethod(param)
    },
    //行添加class
    tableRowClassName(param) {
      if (typeof this.rowClassName === 'function') return this.rowClassName(param)
    },
    // 单元格添加class
    tableCellClassName(params) {
      let { cellClassName } = this
      if (typeof cellClassName === 'function') cellClassName = cellClassName(params)

      let { column, rowIndex } = params

      return [cellClassName, `uiid-zd-${rowIndex}-${column.property}`].filter(Boolean).join(' ')
    },
    tableHeaderCellClassName({ row, column, rowIndex, columnIndex }) {
      return `uiid-zd-header-${column.property}`
    },

    renderHeader(h, { column, $index }) {
      const realWidth = (column.label?.pxWidth('normal 14px Robot') || 0) + 30 // 可能还有边距/边框等值，需要根据实际情况加上
      if (column.minWidth < realWidth) column.minWidth = realWidth
      const keys = Object.keys(this.$scopedSlots)
      const headerProp = column.property + 'Header'
      const isInclude = keys.includes(headerProp)
      if(isInclude) {
        return this.$scopedSlots[headerProp]()
      }
      return h('span', column.label)
    },
    //当某个单元格被点击时会触发该事件
    cellClick(row, column, cell, event) {
      this.$emit('cell-click', row, column, cell, event)
    },
    //行单机
    rowClick(row, event, column) {
      this.$emit('row-click', row, event, column)
    },
    //行双击
    rowDblclick(row, event) {
      this.$emit('row-dblclick', row, event)
    },
    //处理数据
    detail(row, column) {
      let result = get(row, column.prop)
      if (column.formatter && typeof column.formatter === 'function') {
        result = column.formatter(row, column, result)
      }
      if (column.type) {
        if ((column.type == 'date' || column.type == 'time' || column.type == 'datetime') && column.format) {
          const format = column.format.replace('dd', 'DD').replace('yyyy', 'YYYY')
          result = moment(result).format(format)
        }
        result = this.findByvalue(
          setDic(column, this.DIC),
          result,
          column.props
        )
      }
      this.$set(row, `$${column.prop}`, result)
      return result
    },
    // 新增
    rowAdd() {
      this.boxType = 0
      this.show()
    },
    // 编辑
    rowEdit(row, index) {
      this.tableForm = Object.assign({}, row)
      this.tableIndex = index
      this.boxType = 1
      this.show()
    },
    // 删除
    rowDel(that, row, index) {
      return new Promise((resolve) => {
        this.$emit('row-del', row, index, resolve)
      })
    },
    //保存
    rowSave() {
      this.$refs['tableForm'].validate((valid) => {
        if (valid) {
          this.boxLoading = true
          this.$emit('row-save', Object.assign({}, this.tableForm), this.hide, () => (this.boxLoading = false))
        }
      })
    },
    //更新
    rowUpdate() {
      this.$refs['tableForm'].validate((valid) => {
        if (valid) {
          const index = this.tableIndex
          this.boxLoading = true
          this.$emit('row-update', Object.assign({}, this.tableForm), index, this.hide, () => (this.boxLoading = false))
        }
      })
    },
    //显示表单
    show(cancel) {
      this.boxLoading = false
      const callack = () => {
        if (cancel !== true) {
          this.boxVisible = true
          this.boxVisibleLock = true
          this.$nextTick(function () {
            this.$refs['tableForm'].clearValidate()
          })
        }
      }
      if (typeof this.beforeOpen === 'function') this.beforeOpen(callack)
      else callack()
    },
    //隐藏表单
    hide(cancel) {
      const callack = () => {
        if (cancel !== false) {
          this.$nextTick(() => {
            this.$refs['tableForm']?.resetFields()
            //释放form表单
            this.formReset()
          })
          this.boxVisible = false
        }
        this.boxLoading = false
      }
      if (typeof this.beforeClose === 'function') this.beforeClose(callack)
      else callack()
    },
    dialogFormClose() {
      this.$emit('close')
    },
    validate(callback) {
      return this.$refs.headerSearch.validate(callback)
    },
    indexMethod: function indexMethod(index) {
      return index + 1 + ((this.page.pageIndex || 1) - 1) * (this.page.pageSize || 10)
    },
    rowPrint() {
      console.log('rowPrint')
      // this.overHidden = false;
      // this.$nextTick(function () {
      //   this.$print(this.$refs.table);
      //   this.overHidden = true;
      // })

      if (validatenull(this.data)) {
        this.$message.warning('请勾选要打印的数据')
        return
      }
      /*const getTemplate = () => {
       return tableTemp(
       this.tableColumn,
       this.data,
       this.tableColumn
       );
       };*/

      this.$nextTick(() => {
        var newstr = this.getTemplate()
        var tab = window.open('', '打印')
        tab.document.open()
        tab.document.write(newstr)
        tab.window.print()
        tab.close()
      })
    },
    getTemplate() {
      return tableTemp(this.tableColumn, this.data, this.tableColumn)
    },

    columnVisibleSubmit(list) {
      this.columnSortList = list
      this.$emit('column-change', this.tableColumn, this.columnList)
    },

    toggleRowExpansion(row, expanded) {
      this.$refs.table.toggleRowExpansion(row, expanded)
    },
    doLayout() {
      return this.$refs.table.doLayout()
    },
    tableMethods(fnName, ...args) {
      this.$nextTick(function () {
        this.$refs.table?.[fnName]?.(...args)
      })
    }
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    },
    showSummary: Boolean,
    summaryMethod: Function,
    lazy: Boolean,
    load: Function,
    spanMethod: Function,
    rowClassName: Function,
    cellClassName: Function,
    beforeClose: Function,
    beforeOpen: Function,
    expandRowKeys: {
      type: Array
    },
    page: {
      type: Object,
      default() {
        return {
          // total: 0, //总页数
          // pageIndex: 0, //当前页数
          // pageSize: 10, //每页显示多少条
          // background: true //背景颜色
        }
      }
    },
    tableLoading: {
      type: Boolean,
      default: false
    },
    search: {
      type: Object,
      default () {
        return {}
      }
    },
    data: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    option: {
      type: Object,
      required: true,
      default: () => ({})
    },
    dic: {
      type: Object,
      default: () => ({})
    }
  },
  provide () {
    return {
      crud: this
    }
  }
}
</script>

<style lang="scss">
.crud-container {
  margin: 0 auto;
  overflow: auto;

  .formPart {
    margin: 20px 0;
    background-color: #f7f8fa;
    padding: 20px 20px 5px;
    .el-form-item {
      margin-right: 20px;
    }
    .search-form-btn .el-form-item__content {
      margin-left: 0!important;
    }
  }

  .menu-left {
    width: 100%;
    margin: 10px 0 5px 0;
    .fl {
      .el-button {
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }
  }
  .el-table {
    margin-bottom: 15px;
  }

  /* 可以设置不同的进入和离开动画 */
  /* 设置持续时间和动画函数 */
  .slide-fade-enter-active,
  .slide-fade-leave-active {
    -webkit-transition: all 0.49s linear;
    -moz-transition: all 0.49s linear;
    -ms-transition: all 0.49s linear;
    -o-transition: all 0.49s linear;
    transition: all 0.49s linear;
  }
  .slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active for below version 2.1.8 */ {
    height: 0 !important;
  }
}
.crud-pagination {
  padding: 10px 0 10px 20px;
  > * {
    display: inline-block;
  }
  &.el-pagination {
    width: auto;

    .el-select-dropdown__item {
      span {
        line-height: inherit;
      }
    }
  }

  .el-select-dropdown {
    position: fixed !important;
  }
}
.crud-form {
  padding: 0 8px;
}
.crud-header {
  width: 100%;
  & > .el-button {
    padding: 12px 25px;
  }
}
.crud--overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.mr0 {
  margin-right: 0 !important;
}
// 按钮之间竖线
.menu-btn-item {
  + .menu-btn-item {
    position: relative;
    margin-left: 18px;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -9px;
      width: 1px;
      height: 1em;
      background-color: #DCDFE6;
    }
  }
  &.line::after {
    display: none !important;
  }
  .el-button,
  &.el-button {
    margin-right: 0;
  }
}
.popper-delete {
  .content {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .el-icon-warning {
      margin-right: 5px;
      font-size: 24px;
      color: $color-warning;
    }
  }
}
.icon-text-wrapper {
  position: relative;
  padding-left: 2em;
  .icon {
    position: absolute;
    top: 50%;
    left: 1em;
    transform: translate(-50%, -50%);
  }
}
</style>
