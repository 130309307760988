// 店铺状态管理
import { getWebsiteAreaCode, getPlatformList, getDistributorUserList, getShopList } from '@/api/shop/manageApi'
import { REQUEST_ALL_DATA } from '@/utils/constant/requestConst'
const shop = {
  state: {
    // 站点
    websites: [],
    // 所有子账号
    accounts: [],
    // 平台
    platforms: [],
    shops: [],
    // 分销商主账号
    distributors: []
  },

  mutations: {
    SET_WEBSITE_AREA_CODE_DICT: (state, data) => {
      state.websites = data
    },
    SET_ACCOUNT_DICT: (state, data) => {
      state.accounts = data
    },
    SET_PLATFORMS: (state, data) => {
      state.platforms = data
    },
    SET_SHOP: (state, data) => {
      state.shops = data
    },
    SET_DISTRIBUTOR_DICT: (state, data) => {
      state.distributors = data
    }
  },
  actions: {
    // 获取站点
    async GetWebsiteAreaCodeDict({ state, commit }, dictType) {
      // eslint-disable-next-line no-undef
      let [error, res] = await awaitWrap(
        getWebsiteAreaCode({ ...REQUEST_ALL_DATA, continentDictCode: null })
      )
      if (!res) return
      // eslint-disable-next-line no-undef
      let data = res?.detail || []
      commit('SET_WEBSITE_AREA_CODE_DICT', data)
    },
    // 获取平台
    async GetPlatforms({ state, commit }, dictType) {
      // eslint-disable-next-line no-undef
      let [error, res] = await awaitWrap(getPlatformList(REQUEST_ALL_DATA))
      if (!res) return
      // eslint-disable-next-line no-undef
      let data = res?.detail || []
      commit('SET_PLATFORMS', data)
    },
    // 获取店铺
    async GetShop({ state, commit }, dictType) {
      // eslint-disable-next-line no-undef
      let [error, res] = await awaitWrap(getShopList(REQUEST_ALL_DATA))
      if (!res) return
      // eslint-disable-next-line no-undef
      let data = res?.detail || []
      commit('SET_SHOP', data)
    },
    async GetDistributorDict({ state, commit }, dictType) {
      // eslint-disable-next-line no-undef
      let [error, res] = await awaitWrap(getDistributorUserList(REQUEST_ALL_DATA))
      if (!res) return
      // eslint-disable-next-line no-undef
      let data = res?.detail || []
      commit('SET_DISTRIBUTOR_DICT', data)
    }
  }
}

export default shop
