
/**
 * 计算文本在页面所占px宽度 -- 扩展String原型方法pxWidth
 * 获取文本px宽度
 * @param font{String}: 字体样式
 **/
String.prototype.pxWidth = function(font) {
  // re-use canvas object for better performance
  var canvas = String.prototype.pxWidth.canvas || (String.prototype.pxWidth.canvas = document.createElement('canvas')),
    context = canvas.getContext('2d')
  
  font && (context.font = font)
  var metrics = context.measureText(this)
  
  return metrics.width
}