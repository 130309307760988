<template>
  <div v-if="!item.hidden && item.children" class="menu-wrapper">
    <template
      v-if="
        hasOneShowingChild(item.children, item) &&
        (!onlyOneChild.children || onlyOneChild.noShowingChildren) &&
        childrenIsEmpty(item)
      "
    >
      <!-- <div style="background-color: #212529;">
        <img :src="logo" style="height:50px;"/>
      </div> -->
      <app-link :to="resolvePath(onlyOneChild.path)" :disabled="isDisabled(onlyOneChild)">
        <el-menu-item
          :index="resolvePath(onlyOneChild.path)"
          :class="{ 'submenu-title-noDropdown': !isNest }"
          :disabled="isDisabled(onlyOneChild)"
        >
          <item
            v-if="onlyOneChild.meta"
            :icon="onlyOneChild.meta.icon || item.meta.icon"
            :title="onlyOneChild.meta.title"
            :uiid="`zd-menu-${onlyOneChild.path}`"
          />
        </el-menu-item>
      </app-link>
    </template>
    <template v-else-if="item.children.filter(({ hidden }) => !hidden).length === 0"></template>
    <el-submenu v-else ref="submenu" :index="resolvePath(item.path)">
      <template slot="title">
        <item v-if="item.meta" :uiid="`zd-menu-${item.path}`" :icon="item.meta.icon" :title="item.meta.title" />
      </template>

      <template v-for="child in item.children" v-if="!child.hidden">
        <sidebar-item
          v-if="child.children && child.children.length > 0"
          :is-nest="true"
          :item="child"
          :key="child.path"
          :base-path="resolvePath(child.path)"
          class="nest-menu"
        />

        <app-link v-else :to="resolvePath(child.path)" :disabled="isDisabled(child)" :key="child.name">
          <el-menu-item :index="resolvePath(child.path)" :disabled="isDisabled(child)">
            <item v-if="child.meta" :uiid="`zd-menu-${child.path}`" :icon="child.meta.icon" :title="child.meta.title" />
          </el-menu-item>
        </app-link>
      </template>
    </el-submenu>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
import path from 'path'
import { isExternal } from '@/utils'
import Item from './Item'
import AppLink from './Link'
import FixiOSBug from './FixiOSBug'

export default {
  name: 'SidebarItem',
  components: { Item, AppLink },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      onlyOneChild: null,
      logo: ''
    }
  },
  // computed: {
  //   ...mapGetters([
  //     'sidebar'
  //   ])
  // },
  // mounted: function() {
  //   this.initLogo()
  // },
  computed: {
    childrenIsEmpty() {
      return (item) => {
        const children = item.children.filter(({ hidden }) => !hidden)
        if (children.length == 1 && Array.isArray(children[0] && children[0].children) && children[0].children.length) {
          return false
        }
        if (children.length == 1) {
          return true
        }
        return false
      }
    }
  },
  methods: {
    hasOneShowingChild(children, parent) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item

          return true
        }
      })
      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        this.onlyOneChild.noShowingChildren = true
        return true
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: '', noShowingChildren: true }
        return true
      }

      return false
    },
    resolvePath(routePath) {
      // console.log('routePath', routePath)
      if (this.isExternalLink(routePath)) {
        return routePath
      }
      // console.log('path.resolve(this.basePath, routePath)', path.resolve(this.basePath, routePath))
      return path.resolve(this.basePath, routePath)
    },
    isExternalLink(routePath) {
      return isExternal(routePath)
    },
    isDisabled(route) {
      return route.meta.isAuthRoute === false
    }
    // initLogo() {
    //   if (this.sidebar.opened) {
    //     this.logo = require('../../../../assets/logo/logo1.png')
    //   } else {
    //     this.logo = require('../../../../assets/logo/logo2.png')
    //   }
    // }
  }
}
</script>
<style lang="scss" scoped>
</style>
