<template>
  <el-cascader
    ref="cascader"
    v-model="text"
    :options="dic"
    :size="size"
    :placeholder="placeholder || '请选择'+placeholder"
    :disabled="disabled"
    :clearable="clearable"
    :show-all-levels="showAllLevels"
    :props="cascaderProps"
    :collapse-tags="column.collapseTags"
    v-bind="$attrs"
    v-on="new$listeners"
  ></el-cascader>
</template>

<script>
import propsMixin from '../../mixins/propsMixin'
export default {
  name: 'AvueCrudCascader',
  mixins: [propsMixin],
  props: {
    emitPath: {
      type: Boolean,
      default: false
    },
    checkStrictly: {
      type: Boolean,
      default: true
    },
    showAllLevels: {
      type: Boolean,
      default: false
    },
    expandTrigger: {
      type: String
    },
    column: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    cascaderProps({ props, expandTrigger, emitPath, checkStrictly, isRadio, column }) {
      let {
        multiple
      } = column
      if (!expandTrigger && isRadio) expandTrigger = 'hover'
      return {
        ...props,
        expandTrigger,
        emitPath,
        checkStrictly,
        multiple
      }
    },
    isRadio({ checkStrictly, column: { multiple } }) {
      return checkStrictly && !multiple
    },

    new$listeners() {
      return {
        ...this.$listeners,
        change: this.handleCascaderChange
      }
    }
  },
  methods: {
    handleCascaderChange(val) {
      if (this.isRadio) this.$refs.cascader.dropDownVisible = false
      this.handleChange(val)
      // console.log(this.$refs.cascader.getCheckedNodes())
    }
  }
}
</script>

<style>

</style>
