// common
export const DEFAULT_SIZE = 'mini'

// table
export const DEFAULT_TABLE_SIZE = DEFAULT_SIZE
export const DEFAULT_TABLE_BORDER = true

// form
export const DEFAULT_FORM_SIZE = DEFAULT_SIZE

