<script>
import dialog from '@/components/base/appDialog'
import baseForm from '@/components/base/baseForm1'
import { cloneDeep } from 'lodash'
import componentMethods from '@/mixins/componentMethods'
import castPath from 'lodash/_castPath'

export default {
  extends: dialog,
  mixins: [componentMethods('body', ['resetFields'])],
  props: {
    option: {
      default: () => ({})
    },
    getFormDataByOption: Boolean
  },
  computed: {
    componentName() {
      return baseForm
    },
    dialogAttrs() {
      return {}
    },
    btnAttrs() {
      return {}
    },
    bodyOptions() {
      return {
        ...this.$attrs,
        option: this.finalOption,
        value: this.curValue,
        type: this.type
      }
    },
    finalOption() {
      return {
        ...this.option,
        isSearch: false
      }
    }
  },
  methods: {
    cloneDeep(value) {
      return this.getFormDataByOption ? cloneDeep(this.getOption(value, this.finalOption)) : cloneDeep(value)
    },

    getOption(data, column) {
      column = getDisplayColumn(Array.isArray(column) ? column : column.column)
      let form = {}
      column.forEach(({ prop }) => {
        prop = castPath(prop)[0]
        form[prop] = data[prop]
      })
      return form
    },
    
    getDisplayColumn(column) {
      return (Array.isArray(column) ? column : []).filter((col) => !col.hide)
    },
    clearValidate() {
      return this.$refs.body.clearValidate()
    }
  }
}
</script>

<style lang="scss"></style>
