// 实名认证

//是否进行人工审核
export const APPROVE_NO_DEAL = 0 //未处理(人工审核中）manualApproveStatus
export const APPROVE_DEAL = 1 //已处理(人工审核失败/人工审核通过 ) manualApproveStatus

// 实名认证类型
export const PERSONNAL_CERTIFY = 1 //个人用户
export const COMPANY_CERTIFY = 2  //企业用户
//人工审核状态
export const REAL_NAME_NOT_DEAL_OR_FAIL = 0 //未实名认证(审核失败) isCertificated
export const REAL_NAME_PASS = 1 //实名认证通过 isCertificated

//组合 是否进行人工审核 人工审核状态
export const NO_REAL_NAME = 0 //未实名认证
export const REAL_NAME_FAIL = 1 //实名认证失败
export const REAL_NAME_SUCCSS = 2 //实名认证成功

/**
 * @description:审核状态与manualApproveStatus和isCertificated有关 获取审核状态状态
 * @param {Objecy} row
 * @return {Number | String}
 */
export function REAL_NAME_STATUS(row) {
  const { manualApproveStatus, isCertificated } = row
  //未处理
  if (manualApproveStatus == APPROVE_NO_DEAL) {
    if (isCertificated == REAL_NAME_NOT_DEAL_OR_FAIL) {
      return NO_REAL_NAME
    }
  }
  //已处理
  else if (manualApproveStatus == APPROVE_DEAL) {
    if (isCertificated == REAL_NAME_NOT_DEAL_OR_FAIL) {
      return REAL_NAME_FAIL
    } else if (isCertificated == REAL_NAME_PASS) {
      return REAL_NAME_SUCCSS
    }
  }
  return NO_REAL_NAME
}
