var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',_vm._g(_vm._b({directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],ref:"table",class:[
    'commonTableComponent',
    _vm.size === 'mini' && 'minicommonTableComponent' ],style:({
    width: _vm.$attrs.width || '100%',
    'max-height': _vm.$attrs['max-height'] || 'auto',
    'min-height': _vm.$attrs['min-height'] || 'auto',
  }),attrs:{"data":_vm.infoData,"tooltip-effect":_vm.$attrs['tooltip-effect'] || 'dark',"border":_vm.$attrs.border === undefined ? true : _vm.$attrs.border}},'el-table',
    Object.assign(
      _vm.height === 'auto'
        ? {}
        : {
            height: _vm.height ? _vm.height : 'calc(100vh - 240px)',
          },
      _vm.$attrs
    )
  ,false),_vm.$listeners),[_c('template',{slot:"empty"},[_vm._t("empty",[_vm._v(" "+_vm._s(_vm.$attrs["empty-text"] || "暂无数据")+" ")])],2),(_vm.selection)?_c('el-table-column',{attrs:{"type":"selection","width":"55"}}):_vm._e(),_vm._l((_vm.cols),function(ref,index){
      var prop = ref.prop;
      var label = ref.label;
      var width = ref.width;
      var minWidth = ref.minWidth;
      var align = ref.align;
      var fixed = ref.fixed;
      var headerSlotName = ref.headerSlotName;
      var slotName = ref.slotName;
      var isSex = ref.isSex;
      var isPic = ref.isPic;
      var detailArr = ref.detailArr;
      var children = ref.children;
return [(children)?_c('el-table-column',{key:index,attrs:{"prop":prop,"label":label,"width":width,"align":align,"min-width":minWidth}},[_vm._l((children),function(item){return [_c('el-table-column',{attrs:{"prop":item.prop,"label":item.label,"width":item.width,"min-width":item.minWidth},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
      var $index = ref.$index;
return [_vm._t(item.prop,[_c('span',[_vm._v(_vm._s(row[item.prop]))])],{"scoped":Object.assign({}, row, {prop: item.prop, index: $index})})]}}],null,true)})]})],2):(isPic)?_c('el-table-column',{key:index,attrs:{"prop":prop,"label":label,"width":width,"min-width":minWidth},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [(Array.isArray(row[prop]))?[_c('el-image',{staticStyle:{"width":"60px","height":"60px"},attrs:{"fit":"contain","lazy":"","scroll-container":_vm.height === 'auto' ? undefined : '.el-table__body-wrapper',"src":_vm._f("formatUrl")(row[prop][0].path,'min'),"preview-src-list":_vm._f("prvFormatUrl")(row[prop])}})]:_c('el-image',{staticStyle:{"width":"60px","height":"60px"},attrs:{"fit":"contain","lazy":"","scroll-container":_vm.height === 'auto' ? undefined : '.el-table__body-wrapper',"src":_vm._f("formatUrl")(row[prop],'min'),"preview-src-list":_vm._f("prvFormatUrl")(row[prop])}})]}}],null,true)}):(detailArr)?_c('el-table-column',{key:index,attrs:{"show-overflow-tooltip":"","prop":prop,"label":label,"width":width,"min-width":minWidth},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('span',[_vm._v(_vm._s(detailArr[row[row.prop]]))])]}}],null,true)}):(headerSlotName && slotName)?[_c('el-table-column',{key:index,attrs:{"prop":prop,"label":label,"width":width,"min-width":minWidth},scopedSlots:_vm._u([{key:"header",fn:function(ref){
      var row = ref.row;
return [_vm._t(headerSlotName,null,{"scoped":Object.assign({}, row, {prop: prop})})]}},{key:"default",fn:function(ref){
      var row = ref.row;
      var $index = ref.$index;
return [_vm._t(slotName,null,{"scoped":Object.assign({}, row, {prop: prop, index: $index})})]}}],null,true)})]:(slotName)?_c('el-table-column',{key:index,attrs:{"prop":prop,"label":label,"width":width,"min-width":minWidth},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
      var $index = ref.$index;
return [_vm._t(slotName,null,{"scoped":Object.assign({}, row, {prop: prop, index: $index})})]}}],null,true)}):(headerSlotName)?_c('el-table-column',{key:index,attrs:{"prop":prop,"label":label,"width":width,"min-width":minWidth},scopedSlots:_vm._u([{key:"header",fn:function(ref){
      var row = ref.row;
return [_vm._t(headerSlotName,null,{"scoped":Object.assign({}, row, {prop: prop})})]}}],null,true)}):(isSex)?_c('el-table-column',{key:index,attrs:{"prop":prop,"label":label,"min-width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [(row[prop] == 0)?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("未知")]):(row[prop] == 1)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("男")]):(row[prop] == 2)?_c('el-tag',{attrs:{"type":"warning"}},[_vm._v("女")]):_vm._e()]}}],null,true)}):_c('el-table-column',{key:index,attrs:{"show-overflow-tooltip":"","prop":prop,"label":label,"minWidth":minWidth,"fixed":fixed}})]}),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }