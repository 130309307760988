// 支付方式
export const PAYMENT_TYPE_DIC = [
  {
    label: '支付宝',
    value: 1
  },
  {
    label: '微信',
    value: 2
  },
  {
    label: '银行卡',
    value: 3
  }
]
export const PAYMENT_TYPE_ONLINE_DIC = [
  {
    label: '支付宝',
    value: 1
  },
  {
    label: '微信',
    value: 2
  }
]
export const PAYMENT_TYPE_SYS_DIC = [
  {
    label: '支付宝',
    value: 1
  },
  {
    label: '微信',
    value: 2
  },
  {
    label: '银行卡',
    value: 3
  },
  {
    label: '账户余额',
    value: 4
  },
  {
    label: '免支付',
    value: 5
  },
  {
    label: '信用额度',
    value: 6
  }
]

// 处理状态 handleStatus
// 审核状态：0、申请已提交/待处理/未审核 1、客服已通过，2，客服已拒绝 3、客服经理已通过，
// 4，客服经理退回 5、财务已通过，6，财务退回  7，客服经理已拒绝 8，财务已拒绝
export const HANDLE_STATUS_PENDING = 0
export const HANDLE_STATUS_SUCCESS_BY_CUSTOMER = 1
export const HANDLE_STATUS_REFUSE_BY_CUSTOMER = 2
export const HANDLE_STATUS_SUCCESS_BY_CUSTOMER_MANAGER = 3
export const HANDLE_STATUS_REFUSE_BY_CUSTOMER_MANAGER_BACK = 4
export const HANDLE_STATUS_SUCCESS_BY_FINANCE = 5
export const HANDLE_STATUS_REFUSE_BY_FINANCE_BACK = 6
export const HANDLE_STATUS_REFUSE_BY_CUSTOMER_MANAGER = 7
export const HANDLE_STATUS_REFUSE_BY_FINANCE = 8

export const HANDLE_STATUS = {
  [HANDLE_STATUS_PENDING]: '未审核',
  [HANDLE_STATUS_SUCCESS_BY_CUSTOMER]: '客服已通过',
  [HANDLE_STATUS_REFUSE_BY_CUSTOMER]: '客服已拒绝',
  [HANDLE_STATUS_SUCCESS_BY_CUSTOMER_MANAGER]: '客服经理已通过',
  [HANDLE_STATUS_REFUSE_BY_CUSTOMER_MANAGER_BACK]: '客服经理退回',
  [HANDLE_STATUS_SUCCESS_BY_FINANCE]: '财务已通过',
  [HANDLE_STATUS_REFUSE_BY_FINANCE_BACK]: '财务退回',
  [HANDLE_STATUS_REFUSE_BY_CUSTOMER_MANAGER]: '客服经理已拒绝',
  [HANDLE_STATUS_REFUSE_BY_FINANCE]: '财务已拒绝'
}

export const HANDLE_STATUS_DIC = [
  {
    label: '未审核',
    value: HANDLE_STATUS_PENDING
  },
  {
    label: '客服已通过',
    value: HANDLE_STATUS_SUCCESS_BY_CUSTOMER
  },
  {
    label: '客服已拒绝',
    value: HANDLE_STATUS_REFUSE_BY_CUSTOMER
  },
  {
    label: '客服经理已通过',
    value: HANDLE_STATUS_SUCCESS_BY_CUSTOMER_MANAGER
  },
  {
    label: '客服经理退回',
    value: HANDLE_STATUS_REFUSE_BY_CUSTOMER_MANAGER_BACK
  },
  {
    label: '财务已通过',
    value: HANDLE_STATUS_SUCCESS_BY_FINANCE
  },
  {
    label: '财务退回',
    value: HANDLE_STATUS_REFUSE_BY_FINANCE_BACK
  },
  {
    label: '客服经理已拒绝',
    value: HANDLE_STATUS_REFUSE_BY_CUSTOMER_MANAGER
  },
  {
    label: '财务已拒绝',
    value: HANDLE_STATUS_REFUSE_BY_FINANCE
  }
]

// approveStatus   审核状态：0、申请已提交/审核中 1、通过 ，2，不通过/拒绝
export const APPROVE_STATUS_PENDING = 0
export const APPROVE_STATUS_PROCESSED = 1
export const APPROVE_STATUS_REFUSED = 2

export const APPROVE_STATUS_DIC = [
  {
    label: '审核中',
    value: APPROVE_STATUS_PENDING
  },
  {
    label: '通过',
    value: APPROVE_STATUS_PROCESSED
  },
  {
    label: '拒绝',
    value: APPROVE_STATUS_REFUSED
  }
]
export const APPROVE_STATUS_RECHARGE_DIC = [
  {
    label: '待支付',
    value: APPROVE_STATUS_PENDING
  },
  {
    label: '已支付',
    value: APPROVE_STATUS_PROCESSED
  },
  {
    label: '支付关闭',
    value: APPROVE_STATUS_REFUSED
  }
]

// handleType 售后处理方式
export const HANDLE_TYPE_POST = 3
export const HANDLE_TYPE_DIC = [
  {
    label: '工厂销毁处理',
    value: 1
  },
  {
    label: '上门自提',
    value: 2
  },
  {
    label: '邮寄到指定地址',
    value: HANDLE_TYPE_POST
  }
]

// expenseType 费用多退少补类型（1、补充费用 2、退还费用）
export const EXPENSE_TYPE_PAY = 1
export const EXPENSE_TYPE_RETURN = 2
export const EXPENSE_TYPE_OBJ = {
  [EXPENSE_TYPE_PAY]: '补运费',
  [EXPENSE_TYPE_RETURN]: '退款'
}

// 申请售后类型/退款类型
export const REFUND_REASON_TYPE_UNPRODUCED = 1
export const REFUND_REASON_TYPE_IN_PRODUCTION = 2
export const REFUND_REASON_TYPE_PRODUCED = 3
export const REFUND_REASON_TYPE_DIC = [
  {
    label: '未生产售后',
    value: REFUND_REASON_TYPE_UNPRODUCED
  },
  {
    label: '已生产售后',
    value: REFUND_REASON_TYPE_IN_PRODUCTION
  },
  {
    label: '已发货售后',
    value: REFUND_REASON_TYPE_PRODUCED
  }
]

// 售后审核类型
export const AUDIT_TYPE_SERVER = 1
export const AUDIT_TYPE_SERVER_MANAGER = 2
export const AUDIT_TYPE_FINANCE = 3
