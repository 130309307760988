export const FONT_LAYER = 0
export const MAKER = 1
export const SECURITY = 2
export const BOUNDARY = 3
export const OTHER_LAYER = 4
export const KL_IMAGE_TYPES = {
  [MAKER]: '膜',
  [SECURITY]: '安全线',
  [BOUNDARY]: '边界线',
  [OTHER_LAYER]: '其他'
}
