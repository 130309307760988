import { MENU_TYPE_OTHER } from '@/utils/constant'

const tagsView = {
  state: {
    visitedViews: [],
    cachedViews: [],
    cacheViews: [
      '/product/shelves',
      '/product/waitShelves',
      '/product/unShelves',
      '/product/maintain/basicsMange',
      '/product/maintain/priceManage',
      '/product/maintain/knifeMange',
      '/product/maintain/sizeMange',
      '/product/maintain/proShowMange',
      '/system/user',
      '/infoManage/noticeManage/noticeCategory',
      '/infoManage/noticeManage/noticeList',
      '/financialManagement/orderReview',
      '/productTemplate/fieldSettings',
      '/market/addProduct'
    ],
    currentView: {},
    // { [key]: { [cKey]: value } }
    // @key requestUrlRegexp  @cKey routePath @value {Object} menu
    menuTypeOfPath: {},
    // { [key]: { [cKey]: value } }
    // @key requestUrlRegexp  @cKey routePath @value menuType
    cachedMenuTypes: {}
  },
  mutations: {
    ADD_VISITED_VIEW: (state, view) => {
      if (state.visitedViews.some((v) => v.path === view.path)) return
      state.visitedViews.push(
        Object.assign({}, view, {
          title: view.meta.title || 'no-name'
        })
      )
    },
    ADD_CACHED_VIEW: (state, view) => {
      let matched = view.matched
      let componentName = matched.slice(-1)[0].components.default.name
      if (state.cachedViews.includes(componentName)) return
      if (state.cacheViews.includes(view.path)) {
        // 当前缓存路由为多层级路由时，将其父级路由加入state.cachedViews中
        if (matched.length > 2) {
          let tempArr = []
          matched.forEach(item => {
            tempArr.push(item.components.default.name)
          })
          state.cachedViews =
            state.cachedViews
              .concat(tempArr.reverse())
              .reduceRight((prev, next) => {
                if (!prev.includes(next)) prev.push(next)
                return prev
              }, [])
              .reverse()
        } else {
          state.cachedViews.push(componentName)
        }

        state.cachedViews = state.cachedViews.slice(-18)
      }
    },

    DEL_VISITED_VIEW: (state, view) => {
      for (const [i, v] of state.visitedViews.entries()) {
        if (v.path === view.path) {
          state.visitedViews.splice(i, 1)
          break
        }
      }
    },
    DEL_CACHED_VIEW: (state, view) => {
      for (const i of state.cachedViews) {
        if (i === view.name) {
          const index = state.cachedViews.indexOf(i)
          state.cachedViews.splice(index, 1)
          break
        }
      }
    },

    DEL_OTHERS_VISITED_VIEWS: (state, view) => {
      for (const [i, v] of state.visitedViews.entries()) {
        if (v.path === view.path) {
          state.visitedViews = state.visitedViews.slice(i, i + 1)
          break
        }
      }
    },
    DEL_OTHERS_CACHED_VIEWS: (state, view) => {
      for (const i of state.cachedViews) {
        if (i === view.name) {
          const index = state.cachedViews.indexOf(i)
          state.cachedViews = state.cachedViews.slice(index, index + 1)
          break
        }
      }
    },

    DEL_ALL_VISITED_VIEWS: (state) => {
      state.visitedViews = []
    },
    DEL_ALL_CACHED_VIEWS: (state) => {
      state.cachedViews = []
    },

    UPDATE_VISITED_VIEW: (state, view) => {
      for (let v of state.visitedViews) {
        if (v.path === view.path) {
          v = Object.assign(v, view)
          break
        }
      }
    },

    SET_CURRENT_VIEW: (state, view) => {
      state.currentView = view
    },
    SET_MENU_TYPE_OF_PATH: (state, data) => {
      for (const url in data) {
        let tempObj = {}
        data[url].forEach(item => {
          let { finalRouteWrapper: { finalRoute } = {} } = item
          let routePath = '$default'
          if (finalRoute && finalRoute.routePath !== '/home') {
            routePath = finalRoute.routePath.replace(/^[/.]?/, '/')
          }
          
          if (!tempObj[routePath]) tempObj[routePath] = []
          if (item.menuType === MENU_TYPE_OTHER && routePath === '$default') {
            tempObj[routePath].unshift(item)
          } else {
            tempObj[routePath].push(item)
          }
        })
        data[url] = tempObj
      }
      state.menuTypeOfPath = Object.freeze(data)
    }
  },
  actions: {
    addView({ dispatch, commit }, view) {
      dispatch('addVisitedView', view)
      dispatch('addCachedView', view)
      commit('SET_CURRENT_VIEW', view)
    },
    addVisitedView({ commit }, view) {
      commit('ADD_VISITED_VIEW', view)
    },
    addCachedView({ commit }, view) {
      commit('ADD_CACHED_VIEW', view)
    },

    delView({ dispatch, state }, view) {
      return new Promise((resolve) => {
        dispatch('delVisitedView', view)
        dispatch('delCachedView', view)
        resolve({
          visitedViews: [...state.visitedViews],
          cachedViews: [...state.cachedViews]
        })
      })
    },
    delVisitedView({ commit, state }, view) {
      return new Promise((resolve) => {
        commit('DEL_VISITED_VIEW', view)
        resolve([...state.visitedViews])
      })
    },
    delCachedView({ commit, state }, view) {
      return new Promise((resolve) => {
        commit('DEL_CACHED_VIEW', view)
        resolve([...state.cachedViews])
      })
    },

    delOthersViews({ dispatch, state }, view) {
      return new Promise((resolve) => {
        dispatch('delOthersVisitedViews', view)
        dispatch('delOthersCachedViews', view)
        resolve({
          visitedViews: [...state.visitedViews],
          cachedViews: [...state.cachedViews]
        })
      })
    },
    delOthersVisitedViews({ commit, state }, view) {
      return new Promise((resolve) => {
        commit('DEL_OTHERS_VISITED_VIEWS', view)
        resolve([...state.visitedViews])
      })
    },
    delOthersCachedViews({ commit, state }, view) {
      return new Promise((resolve) => {
        commit('DEL_OTHERS_CACHED_VIEWS', view)
        resolve([...state.cachedViews])
      })
    },

    delAllViews({ dispatch, state }, view) {
      return new Promise((resolve) => {
        dispatch('delAllVisitedViews', view)
        dispatch('delAllCachedViews', view)
        resolve({
          visitedViews: [...state.visitedViews],
          cachedViews: [...state.cachedViews]
        })
      })
    },
    delAllVisitedViews({ commit, state }) {
      return new Promise((resolve) => {
        commit('DEL_ALL_VISITED_VIEWS')
        resolve([...state.visitedViews])
      })
    },
    delAllCachedViews({ commit, state }) {
      return new Promise((resolve) => {
        commit('DEL_ALL_CACHED_VIEWS')
        resolve([...state.cachedViews])
      })
    },

    updateVisitedView({ commit }, view) {
      commit('UPDATE_VISITED_VIEW', view)
    }
  }
}

export default tagsView
