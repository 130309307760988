<template>
  <div class="base-tabs">
    <el-radio-group
      v-if="option.type === 'border'"
      class="list-state-group"
      v-model="curValue"
      @change="handleTabClick"
    >
      <el-radio-button v-for="({ label, value }, index) in option.column" :label="value" :key="index">
        {{ label }}
      </el-radio-button>
    </el-radio-group>
    <el-tabs
      v-else
      :type="option.type === 'normal' ? '' : option.type"
      :class="{ text: option.type === 'text' }"
      v-model="curValue"
      @tab-click="handleTabClick"
    >
      <el-tab-pane
        v-for="item in option.column"
        :key="item.value"
        :label="item.label"
        :name="item.value"
      ></el-tab-pane>
    </el-tabs>

    <baseTabs
      v-if="children"
      :key="curValue"
      v-model="childValue"
      :dic="children"
      @change="handleChange"
      @tab-click="handleTabClick"
    ></baseTabs>
  </div>
</template>

<script>
import { find } from 'lodash'
import { validData } from '@/components/avue/utils/util'
import { validatenull } from '@/components/avue/utils/validate'
import { getTabsOption } from './util'

export default {
  name: 'baseTabs',
  props: {
    value: {
      default: ''
    },
    dic: {
      type: Array | Object,
      default: () => {
        return []
      }
    },
    showAllLevels: {
      type: Boolean,
      default: true
    },
    type: {
      default: 'border'
    }
  },
  data() {
    return {
      curValue: '',
      childValue: []
    }
  },
  computed: {
    finalValue: {
      get({ curValue, childValue }) {
        return [curValue].concat(childValue)
      },
      set(value) {
        value = Array.isArray(value) ? value : []
        this.curValue = value[0] ?? this.defaultValue
        this.childValue = value.slice(1)
      }
    },
    defaultValue({ option: { defaultValue, column } }) {
      return validData(defaultValue, column[0]?.value)
    },
    option({ dic, type }) {
      let option = getTabsOption(dic)
      option.type = validData(option.type, type)
      return option
    },
    curItem({ option: { column }, curValue }) {
      return find(column, { value: curValue }) || {}
    },
    children({ curItem }) {
      return curItem.children
    }
  },
  watch: {
    defaultValue: {
      handler(defaultValue) {
        this.curValue = defaultValue
      },
      immediate: true
    },
    children: {
      handler() {
        this.childValue = []
      },
      immediate: true
    },
    value: {
      handler(value) {
        this.finalValue = value
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    // console.log(this.curValue, 'created')
    // 立即执行一次change，为了设置默认值
    let isFirst = true
    let unwatch = this.$watch(
      'finalValue',
      (finalValue) => {
        // console.log(this.curValue, finalValue)
        typeof unwatch === 'function' && unwatch()
        // 如果有children，就由子组件触发change
        if (!this.children && isFirst) {
          isFirst = false
          this.handleChange(finalValue)
        }
      },
      {
        immediate: true
      }
    )
  },
  methods: {
    handleTabClick() {
      // console.log(this.curValue, 'handleTabClick')
      let { finalValue } = this

      // 如果有children，就由子组件触发change
      validatenull(this.children) && this.handleChange(finalValue)

      this.$emit('tab-click', { name: finalValue[finalValue.length - 1] })
    },
    handleChange() {
      // console.log(this.curValue, 'handleChange')
      let { finalValue } = this
      this.$emit('input', finalValue)
      this.$emit('change', finalValue)
      this.$emit('search-change', finalValue)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.text {
  .el-tabs__nav-wrap::after {
    display: none;
  }
  .el-tabs__header {
    margin-bottom: 0;
  }
  .el-tabs__item {
    height: 1em;
    line-height: 1;
    padding: 0;
    font-size: 16px;

    + .el-tabs__item {
      padding-left: 40px;
    }
  }
}
</style>