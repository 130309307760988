const superRouter = [
  {
    name: '后台端-首页',
    id: 's-53',
    pid: null,
    sort: 0,
    path: '/home',
    icon: 'index',
    component: 'Layout',
    redirect: '/home/index',
    meta: { title: '首页', icon: 'index' },
    children: [
      {
        name: '后台端-系统首页',
        id: 's-54',
        pid: 's-53',
        sort: 1,
        icon: 'home',
        path: 'index',
        component: 'home',
        meta: { title: '系统首页', icon: 'icon' },
        children: []
      }
    ]
  },
  {
    name: '后台端-原型管理',
    id: 's-23',
    pid: null,
    sort: 1,
    path: '/product',
    icon: 'index',
    component: 'Layout',
    redirect: '/product/shelves',
    meta: { title: '原型管理', icon: 'ic_commodity' },
    children: [
      {
        name: '后台端-原型列表',
        id: 's-24',
        pid: 's-23',
        sort: 2,
        icon: 'index',
        path: 'shelves',
        component: 'product/shelves',
        meta: { title: '原型列表', icon: '' },
        children: []
      },
      {
        name: '后台端-价格管理',
        id: 's-27',
        pid: 's-23',
        sort: 5,
        icon: 'index',
        path: 'maintain',
        component: 'product/maintain',
        redirect: '/product/maintain/basicsMange',
        meta: { title: '价格管理', icon: '' },
        // meta: { title: '原型维护', icon: '' },
        children: [
          {
            name: '后台端-价格管理',
            id: 's-58',
            pid: 's-27',
            sort: 2,
            icon: 'index',
            path: 'priceManage',
            component: 'product/maintain/priceManage',
            meta: { title: '价格管理', icon: '' },
            children: []
          },
          {
            name: '后台端-原型基础信息编辑',
            id: 's-67',
            pid: 's-27',
            sort: 2,
            icon: 'index',
            path: 'basicsEdit',
            hidden: true,
            component: 'product/maintain/basicsEdit',
            meta: { title: '原型基础信息编辑', icon: '' },
            children: []
          },
          {
            name: '后台端-价格列表',
            id: 's-695',
            pid: 's-27',
            sort: 2,
            icon: 'index',
            path: 'priceList',
            component: 'product/maintain/priceList',
            meta: { title: '价格列表', icon: '' },
            children: []
          }
        ]
      },
      {
        name: '后台端-分类管理',
        id: 's-28',
        pid: 's-23',
        sort: 6,
        icon: 'index',
        path: 'category',
        component: 'product/category',
        meta: { title: '分类管理', icon: '' },
        children: []
      },
      {
        name: '后台端-档位管理',
        id: 's-59',
        pid: 's-23',
        sort: 6,
        icon: 'index',
        path: 'editGears',
        component: 'product/editGears',
        meta: { title: '档位管理', icon: '' },
        children: []
      },
      {
        name: '后台端-编辑档位',
        id: 's-60',
        pid: 's-23',
        sort: 7,
        icon: 'index',
        path: 'gearsManage/:id',
        component: 'product/gearsManage',
        hidden: true,
        meta: { title: '编辑档位', icon: '' },
        children: []
      },
      {
        name: '后台端-原型分组',
        id: 's-95',
        pid: 's-23',
        sort: 8,
        icon: 'index',
        path: 'protoGroupList',
        component: 'product/protoGroupList',
        meta: { title: '原型分组', icon: '' },
        children: []
      }
    ]
  },
  {
    name: '后台端-公共图库',
    id: 's-4',
    pid: null,
    sort: 1,
    path: '/picture',
    icon: 'index',
    component: 'Layout',
    redirect: '/picture/picManage',
    meta: { title: '公共图库', icon: 'ic_pic' },
    children: [
      {
        name: '后台端-公共图片',
        id: 's-36',
        pid: 's-4',
        sort: 1,
        icon: 'knife',
        path: 'publicGallery',
        component: 'picture/publicGallery',
        meta: { title: '公共图片', icon: '' },
        children: []
      },
      {
        name: '后台端-图片分类',
        id: 's-5',
        pid: 's-4',
        sort: 2,
        icon: 'knife',
        path: 'category',
        component: 'picture/category',
        meta: { title: '图片分类', icon: '' },
        children: []
      },
      {
        name: '后台端-图片统计',
        id: 's-75',
        pid: 's-4',
        sort: 3,
        icon: 'knife',
        path: 'statistics',
        component: 'picture/statistics',
        meta: { title: '图片统计', icon: '' },
        children: []
      },
      {
        name: '后台端-图片分组',
        id: 's-96',
        pid: 's-4',
        sort: 4,
        icon: 'knife',
        path: 'publicGalleryGroupList',
        component: 'picture/publicGalleryGroupList',
        meta: { title: '图片分组', icon: '' },
        children: []
      }
    ]
  },
  {
    name: '后台端-公共产品管理',
    id: 's-77',
    pid: null,
    sort: 1,
    path: '/finishedProductManage',
    icon: 'index',
    component: 'Layout',
    redirect: '/finishedProductManage/product',
    meta: { title: '公共产品管理', icon: 'ic_pic' },
    children: [
      {
        name: '后台端-公共产品管理',
        id: 's-78',
        pid: 's-77',
        sort: 2,
        icon: 'index',
        path: 'product',
        component: 'finishedProductManage/product',
        meta: { title: '公共产品管理', icon: '' },
        children: []
      },
      {
        name: '后台端-风格管理',
        id: 's-79',
        pid: 's-77',
        sort: 2,
        icon: 'index',
        path: 'style',
        component: 'finishedProductManage/style',
        meta: { title: '风格管理', icon: '' },
        children: []
      }
    ]
  },
  {
    name: '后台端-分销商管理',
    id: 's-37',
    pid: null,
    sort: 2,
    icon: 'index',
    path: '/user',
    component: 'Layout',
    redirect: '/user/list',
    meta: { title: '分销商管理', icon: 'ic_peoples' },
    children: [
      {
        name: '后台端-分销商列表',
        id: 's-38',
        pid: 's-37',
        sort: 8,
        icon: 'index',
        path: 'list',
        component: 'user',
        meta: { title: '分销商列表', icon: '' },
        children: []
      },
      {
        name: '后台端-分销商信息',
        id: 's-69',
        pid: 's-37',
        sort: 8,
        icon: 'index',
        path: 'resellerPage',
        component: 'user/resellerPage',
        hidden: true,
        meta: { title: '分销商信息', icon: '' },
        children: []
      },
      {
        name: '后台端-注销管理',
        id: 's-691',
        pid: 's-37',
        sort: 8,
        icon: 'index',
        path: 'destroy',
        component: 'user/destroy',
        meta: { title: '注销管理', icon: '' },
        children: []
      },
      {
        name: '后台端-注销管理/账号信息',
        id: 's-692',
        pid: 's-37',
        sort: 8,
        icon: 'index',
        path: 'destroy/info',
        component: 'user/destroy/info',
        meta: { title: '注销管理/账号信息', icon: '' },
        children: [],
        hidden: true
      },
      {
        name: '后台端-注销管理/余额变更记录',
        id: 's-693',
        pid: 's-37',
        sort: 8,
        icon: 'index',
        path: 'destroy/history',
        component: 'user/destroy/history',
        meta: { title: '注销管理/余额变更记录', icon: '' },
        children: [],
        hidden: true
      },
      {
        name: '后台端-注销管理/余额变更记录/详情',
        id: 's-694',
        pid: 's-37',
        sort: 8,
        icon: 'index',
        path: 'destroy/history/detail',
        component: 'user/destroy/history/detail',
        meta: { title: '注销管理/余额变更记录/详情', icon: '' },
        children: [],
        hidden: true
      },
      {
        name: '后台端-图片管理',
        id: 's-6',
        pid: 's-37',
        sort: 8,
        icon: 'knife',
        path: 'picManage',
        component: 'picture/picManage',
        meta: { title: '图片管理', icon: '' },
        children: []
      },
      {
        name: '后台端-订单管理',
        id: 's-20',
        pid: 's-37',
        sort: 8,
        path: '/orderManage',
        icon: 'index',
        redirect: 'orderList',
        component: 'order',
        meta: { title: '订单管理' },
        children: [
          {
            name: '后台端-已删除订单',
            id: 's-21',
            pid: 's-20',
            sort: 2,
            icon: 'index',
            path: 'deleted',
            component: 'order/orderList',
            meta: { title: '已删除订单', icon: '' },
            children: []
          },
          {
            name: '后台端-全部订单',
            id: 's-21',
            pid: 's-20',
            sort: 2,
            icon: 'index',
            path: 'orderList',
            component: 'order/orderList',
            meta: { title: '全部订单', icon: '' },
            children: []
          },
          {
            name: '后台端-FBA订单',
            id: 's-23',
            pid: 's-20',
            sort: 2,
            icon: 'index',
            path: 'orderFbaList',
            component: 'order/orderFbaList',
            meta: { title: 'FBA订单', icon: '' },
            children: []
          },
          {
            name: '后台端-报关清单',
            id: 's-22',
            pid: 's-20',
            sort: 2,
            icon: 'index',
            path: 'order/customsDeclarationList',
            component: 'order/customsDeclarationList',
            meta: { title: '报关清单', icon: '' },
            children: []
          },

          {
            name: '后台端-查看详情',
            id: 's-57',
            pid: 's-56',
            sort: 2,
            icon: '',
            path: 'sendToFactoryBatch/viewDetails',
            component: 'order/sendToFactoryBatch/viewDetails',
            hidden: true,
            meta: { title: '查看详情', icon: '' },
            children: []
          },
          {
            name: '后台端-查看详情1',
            id: 's-73',
            pid: 's-56',
            sort: 2,
            icon: '',
            path: 'cutoffBatch/viewDetails',
            component: 'order/cutoffBatch/viewDetails',
            hidden: true,
            meta: { title: '查看详情', icon: '' },
            children: []
          }
        ]
      },
      {
        name: '后台端-定制产品管理',
        id: 's-41',
        pid: 's-37',
        sort: 8,
        icon: 'knife',
        path: 'designedProductManage',
        component: 'designedProductManage',
        meta: { title: '定制产品管理', icon: '' },
        children: []
      },
      {
        name: '后台端-刀版图查看',
        id: 's-71',
        pid: 's-37',
        sort: 8,
        icon: 'knife',
        path: 'viewKnife',
        component: 'user/viewKnife',
        meta: { title: '刀版图查看', icon: '' },
        children: []
      },
      {
        name: '后台端-组合产品管理',
        id: 's-82',
        pid: 's-37',
        sort: 8,
        icon: 'knife',
        path: 'combinationPoduct',
        component: 'user/combinationPoduct',
        meta: { title: '组合产品管理', icon: '' },
        children: []
      },
      {
        name: '后台端-定制组合产品管理',
        id: 's-83',
        pid: 's-37',
        sort: 8,
        icon: 'knife',
        path: 'designCombinationProduct',
        component: 'user/designCombinationProduct',
        meta: { title: '定制组合产品管理', icon: '' },
        children: []
      },
      {
        name: '后台端-添加用户',
        id: 's-39',
        pid: 's-37',
        sort: 8,
        icon: 'role',
        path: 'addUser',
        component: 'user/addUser',
        hidden: true,
        meta: { title: '添加用户', icon: 'role' },
        children: []
      },
      {
        name: '后台端-编辑用户',
        id: 's-40',
        pid: 's-37',
        sort: 9,
        icon: 'role',
        path: 'editUser',
        component: 'user/editUser',
        meta: { title: '编辑子账号', icon: 'role' },
        hidden: true,
        children: []
      },
      {
        name: '后台端-自定义底板管理',
        id: 's-92',
        pid: 's-37',
        sort: 8,
        icon: 'knife',
        path: 'protoCustomFloorList',
        component: 'user/protoCustomFloor/protoCustomFloorList/index',
        meta: { title: '自定义底板管理', icon: '' },
        children: []
      },
      {
        name: '后台端-底板布局管理',
        id: 's-90',
        pid: 's-37',
        sort: 8,
        icon: 'knife',
        path: 'customFloorList',
        component: 'user/customFloor/customFloorList',
        meta: { title: '底板布局管理', icon: '' },
        children: []
      },
      {
        name: '后台端-新增底板布局',
        id: 's-91',
        pid: 's-37',
        sort: 8,
        icon: 'knife',
        path: 'addCustomFloor',
        component: 'user/customFloor/addCustomFloor',
        hidden: true,
        meta: { title: '新增底板布局', icon: '' },
        children: []
      },
      {
        name: '后台端-编辑底板布局',
        id: 's-93',
        pid: 's-37',
        sort: 8,
        icon: 'knife',
        path: 'editCustomFloor',
        component: 'user/customFloor/editCustomFloor',
        hidden: true,
        meta: { title: '编辑底板布局', icon: '' },
        children: []
      },
      {
        name: '后台端-数据统计',
        id: 's-74',
        pid: 's-37',
        sort: 10,
        icon: 'index',
        path: '/statistics',
        component: 'statistics',
        meta: { title: '数据统计', icon: '' },
        children: []
      },
      {
        name: '店铺管理',
        path: 'store',
        id: 's-75',
        pid: 's-37',
        sort: 8,
        icon: '',
        component: 'user/store/manage',
        meta: { title: '店铺管理', icon: '' },
        children: []
      },
      {
        hidden: true,
        name: '店铺订单',
        path: 'storeOrder',
        id: 's-76',
        pid: 's-37',
        sort: 8,
        icon: '',
        component: 'user/store/order',
        meta: { title: '店铺订单', icon: '' },
        children: []
      },
      {
        name: '后台端-用户解冻/冻结/警告',
        id: 's-38',
        pid: 's-90',
        sort: 8,
        icon: 'index',
        path: 'ThawAndFree',
        component: 'user/MoreThanBtn/ThawAndFree',
        meta: { title: '用户解冻/冻结/警告', icon: '' },
        children: [],
        hidden: true
      }
    ]
  },
  {
    name: '后台端-供应商管理',
    id: 's-46',
    pid: null,
    sort: 2,
    icon: 'index',
    path: '/supplier',
    component: 'Layout',
    redirect: '/supplier/list',
    meta: { title: '供应商管理', icon: 'ic_city' },
    children: [
      {
        name: '后台端-供应商列表',
        id: 's-55',
        pid: 's-46',
        sort: 8,
        icon: 'index',
        path: 'list',
        component: 'supplier/list',
        meta: { title: '供应商列表', icon: '' },
        children: []
      },
      {
        name: '后台端-添加供应商',
        id: 's-49',
        pid: 's-46',
        sort: 8,
        icon: 'role',
        path: 'list/addSupplier',
        component: 'supplier/list/module/addSupplier',
        hidden: true,
        meta: { title: '添加供应商', icon: 'role' },
        children: []
      },
      {
        name: '后台端-编辑供应商',
        id: 's-50',
        pid: 's-46',
        sort: 9,
        icon: 'role',
        path: 'list/editSupplier',
        component: 'supplier/list/module/editSupplier',
        meta: { title: '编辑供应商', icon: 'role' },
        hidden: true,
        children: []
      }
    ]
  },
  {
    name: '后台端-商品管理',
    id: 's-1',
    pid: null,
    sort: 1,
    path: '/knife',
    icon: 'index',
    component: 'Layout',
    redirect: 'noredirect',
    meta: { title: '商品管理', icon: 'index' },
    children: [
      {
        name: '后台端-创建商品',
        id: 's-2',
        pid: 's-1',
        sort: 2,
        icon: 'knife',
        path: 'psd',
        hidden: true,
        component: 'knife',
        meta: { title: '创建商品', icon: 'index' },
        children: []
      },
      {
        name: '后台端-校验刀版图',
        id: 's-3',
        pid: 's-1',
        sort: 3,
        icon: 'knife',
        path: 'check',
        component: 'knife/check',
        meta: { title: '校验刀版图', icon: 'index' },
        hidden: true,
        children: []
      },
      {
        name: '后台端-设置尺码',
        id: 's-60',
        pid: 's-1',
        sort: 3,
        icon: 'knife',
        path: 'size',
        component: 'knife/size',
        meta: { title: '设置尺码', icon: 'index' },
        hidden: true,
        children: []
      },
      {
        name: '后台端-设置尺码校验',
        id: 's-61',
        pid: 's-1',
        sort: 3,
        icon: 'knife',
        path: 'size/check',
        component: 'knife/size/check',
        meta: { title: '设置尺码校验', icon: 'index' },
        hidden: true,
        children: []
      },
      {
        name: '后台端-上传封面',
        id: 's-62',
        pid: 's-1',
        sort: 3,
        icon: 'knife',
        path: 'cover',
        component: 'knife/cover',
        meta: { title: '上传封面', icon: 'index' },
        hidden: true,
        children: []
      }
    ]
  },
  {
    name: '后台端-财务管理',
    id: 's-76',
    pid: null,
    sort: 1,
    path: '/financialManagement',
    icon: 'yunshu',
    component: 'Layout',
    redirect: '/financialManagement/userBalance',
    meta: { title: '财务管理', icon: 'money' },
    children: [
      {
        name: '用户余额',
        id: 's-77',
        pid: 's-76',
        sort: 2,
        path: 'userBalance',
        component: 'financial/userBalance',
        meta: { title: '用户余额', icon: '' },
        children: []
      },
      {
        name: '用户信用额度',
        id: 's-90',
        pid: 's-76',
        sort: 2,
        path: 'userCreditAmount',
        component: 'financial/userCreditAmount',
        meta: { title: '用户信用额度', icon: '' },
        children: []
      },
      {
        name: '月结账单',
        id: 's-95',
        pid: 's-76',
        sort: 2,
        path: 'monthlyBill',
        component: 'financial/monthlyBill',
        meta: { title: '月结账单', icon: '' },
        children: []
      },
      {
        name: '信用额度增减',
        id: 's-92',
        pid: 's-76',
        sort: 2,
        path: 'creditAmountChange',
        hidden: true,
        component: 'financial/userCreditAmount/creditAmountChange',
        meta: { title: '信用额度增减', icon: '' },
        children: []
      },
      {
        name: '信用额度月结账单',
        id: 's-93',
        pid: 's-76',
        sort: 2,
        path: 'userCreditBill',
        hidden: true,
        component: 'financial/userCreditAmount/userCreditBill',
        meta: { title: '信用额度月结账单', icon: '' },
        children: []
      },
      {
        name: '信用额度月结账单详情',
        id: 's-94',
        pid: 's-76',
        sort: 2,
        path: 'userMonthBillsList',
        hidden: true,
        component: 'financial/userCreditAmount/userCreditBill/userMonthBills/userMonthBillsList',
        meta: { title: '信用额度月结账单详情', icon: '' },
        children: []
      },
      // {
      //   name: '月结账单详情',
      //   id: 's-91',
      //   pid: 's-76',
      //   sort: 6,
      //   path: 'monthlyBillDetail',
      //   component: 'financial/monthlyBill/monthlyBillDetail',
      //   hidden: true,
      //   meta: { title: '月结账单详情', icon: '' },
      //   children: []
      // },
      {
        name: '提现管理',
        id: 's-78',
        pid: 's-76',
        sort: 3,
        path: 'withdrawManagement',

        component: 'financial/withdrawManagement',
        meta: { title: '提现管理', icon: '' },
        children: []
      },
      {
        name: '余额变更记录',
        id: 's-79',
        pid: 's-76',
        sort: 5,
        path: 'history',
        component: 'financial/history',
        hidden: true,
        meta: { title: '余额变更记录', icon: '' },
        children: []
      },
      {
        name: '信用额度变更详情',
        id: 's-81',
        pid: 's-76',
        sort: 6,
        path: 'historyDetail',
        component: 'financial/historyDetail',
        hidden: true,
        meta: { title: '信用额度变更详情', icon: '' },
        children: []
      },
      {
        name: '余额变更详情',
        id: 's-96',
        pid: 's-76',
        sort: 6,
        path: 'amountHistoryDetail',
        component: 'financial/amountHistoryDetail',
        hidden: true,
        meta: { title: '余额变更详情', icon: '' },
        children: []
      },
      {
        name: '充值记录',
        id: 's-80',
        pid: 's-76',
        sort: 5,
        path: 'topUpRecord',
        component: 'financial/topUpRecord',
        meta: { title: '充值记录', icon: '' },
        children: []
      },
      {
        name: '订单审核',
        id: 's-82',
        pid: 's-76',
        sort: 7,
        path: 'orderReview',
        component: 'financial/orderReview',
        meta: { title: '订单审核', icon: '' },
        children: []
      },
      {
        name: '订单审核编辑',
        id: 's-87',
        pid: 's-76',
        sort: 7,
        path: 'orderReviewEdit',
        component: 'financial/orderReview/module/editBtn',
        meta: { title: '订单审核编辑', icon: '' },
        children: [],
        hidden: true
      },
      {
        name: '订单审核详情',
        id: 's-88',
        pid: 's-76',
        sort: 7,
        path: 'orderReviewDetail',
        component: 'financial/orderReview/module/detailBtn',
        meta: { title: '订单审核详情', icon: '' },
        children: [],
        hidden: true
      },
      {
        name: '订单审核单条详情',
        id: 's-696',
        pid: 's-76',
        sort: 7,
        path: 'moredetail',
        component: 'financial/orderReview/module/MoreDetail',
        meta: { title: '订单审核单条详情', icon: '' },
        children: [],
        hidden: true
      },
      {
        name: '开票审核',
        id: 's-83',
        pid: 's-76',
        sort: 7,
        path: 'invoicingReview',
        component: 'financial/invoicingReview',
        meta: { title: '开票审核', icon: '' },
        children: []
      },
      {
        name: '售后审核客服',
        id: 's-84',
        pid: 's-76',
        sort: 8,
        path: 'afterSaleAudit/server',
        component: 'financial/afterSaleProcessingOfOrders',
        meta: { title: '售后审核_客服', icon: '' },
        children: []
      },
      {
        name: '售后审核客服经理',
        id: 's-85',
        pid: 's-76',
        sort: 8,
        path: 'afterSaleAudit/serverManager',
        component: 'financial/afterSaleProcessingOfOrders',
        meta: { title: '售后审核_客服经理', icon: '' },
        children: []
      },
      {
        name: '售后审核财务',
        id: 's-86',
        pid: 's-76',
        sort: 8,
        path: 'afterSaleAudit/finance',
        component: 'financial/afterSaleProcessingOfOrders',
        meta: { title: '售后审核_财务', icon: '' },
        children: []
      },
      {
        name: '退款原因设置',
        id: 's-85',
        pid: 's-76',
        sort: 7,
        path: 'afterSaleProcessingOfOrders/refundReasonSetting',
        component: 'financial/refundReasonSetting',
        meta: { title: '退款原因设置', icon: '' },
        children: []
      },
      {
        name: '收款账号管理',
        id: 's-86',
        pid: 's-76',
        sort: 7,
        path: 'receivingAccountManagement',
        component: 'financial/accountManage',
        meta: { title: '收款账号管理', icon: '' },
        children: []
      },
      {
        name: '银行分类',
        id: 's-89',
        pid: 's-76',
        sort: 7,
        path: 'bankCategory',
        component: 'financial/bankCategory',
        meta: { title: '银行分类', icon: '' },
        children: [],
        hidden: true
      },
      {
        name: 'FBA附加费设置',
        id: 's-90',
        pid: 's-76',
        sort: 7,
        path: 'fbaSurchargeSettings',
        component: 'financial/fbaSurchargeSettings',
        meta: { title: 'FBA附加费设置', icon: '' },
        children: []
      }
    ]
  },
  {
    name: '后台端-消息公开管理',
    id: 's-77',
    pid: null,
    sort: 1,
    icon: '',
    path: '/infoManage',
    component: 'Layout',
    redirect: '/infoManage/helpCenter',
    meta: { title: '消息公开管理', icon: 'index' },
    children: [
      {
        name: '后台端-帮助中心',
        id: 's-78',
        pid: 's-77',
        sort: 2,
        path: 'helpCenter',
        component: 'infoManage/helpCenter',
        icon: '',
        meta: { title: '帮助中心', icon: '' },
        children: []
        // hidden: true
      },
      {
        name: '后台端-文章管理',
        id: 's-87',
        pid: 's-77',
        sort: 2,
        path: 'articleManage',
        component: 'infoManage/helpCenter/articleManage',
        icon: '',
        meta: { title: '文章管理', icon: '' },
        hidden: true,
        children: []
      },
      {
        name: '后台端-创建文章',
        id: 's-88',
        pid: 's-77',
        sort: 2,
        path: 'createArticle',
        component: 'infoManage/helpCenter/createArticle',
        icon: '',
        meta: { title: '创建文章', icon: '' },
        hidden: true,
        children: []
      },
      {
        name: '后台端-创建公告',
        id: 's-89',
        pid: 's-77',
        sort: 2,
        path: 'createNotice',
        component: 'infoManage/noticeManage/createNotice',
        icon: '',
        meta: { title: '创建公告', icon: '' },
        hidden: true,
        children: []
      },
      {
        name: '后台端-公告管理',
        id: 's-84',
        pid: 's-77',
        sort: 2,
        path: 'noticeManage',
        component: 'infoManage/noticeManage',
        redirect: '/infoManage/noticeManage/noticeCategory',
        icon: '',
        meta: { title: '公告管理', icon: '' },
        children: [
          {
            name: '后台端-公告分类',
            id: 's-85',
            pid: 's-84',
            sort: 2,
            path: 'noticeCategory',
            component: 'infoManage/noticeManage/noticeCategory',
            icon: '',
            meta: { title: '公告分类', icon: '' },
            children: []
          },
          {
            name: '后台端-公告列表',
            id: 's-86',
            pid: 's-84',
            sort: 2,
            path: 'noticeList',
            component: 'infoManage/noticeManage/noticeList',
            icon: '',
            meta: { title: '公告列表', icon: '' },
            children: []
          }
        ]
      },
      {
        name: '后台端-消息',
        id: 's-79',
        pid: 's-77',
        sort: 3,
        path: 'message',
        component: 'infoManage/message',
        icon: '',
        meta: { title: '消息', icon: '' },
        children: []
      },
      {
        name: '后台端-消息模板添加/编辑',
        id: 's-80',
        pid: 's-77',
        sort: 4,
        path: 'message/templateChange',
        component: 'infoManage/templateChange',
        icon: '',
        meta: { title: '消息模板添加/编辑', icon: '' },
        children: [],
        hidden: true
      }
    ]
  },
  {
    name: '后台端-营销管理',
    id: 's-97',
    pid: null,
    sort: 0,
    path: '/marketingManagement',
    icon: 'index',
    component: 'Layout',
    redirect: '/marketingManagement',
    meta: { title: '营销管理', icon: 'icon' },
    children: [
      {
        name: '后台端-用户偏好记录',
        id: 's-98',
        pid: 's-97',
        sort: 1,
        icon: 'home',
        path: 'userBehavior',
        component: 'market/userBehavior',
        meta: { title: '用户偏好记录', icon: '' },
        children: []
      },
      {
        name: '后台端-推荐专题设置',
        id: 's-99',
        pid: 's-98',
        sort: 2,
        icon: 'home',
        path: 'recommendTopicsSit',
        component: 'market/recommendTopicsSit',
        meta: { title: '推荐专题设置', icon: '' },
        children: []
      },
      {
        name: '后台端-添加产品',
        id: 's-100',
        pid: 's-99',
        sort: 3,
        icon: 'home',
        path: 'addProduct',
        hidden: true,
        component: 'market/addProduct',
        meta: { title: '添加产品', icon: '' },
        children: []
      }
    ]
  },
  {
    name: '后台端-系统产品模板',
    id: 's-99',
    pid: null,
    sort: 0,
    path: '/productTemplate',
    icon: 'index',
    component: 'Layout',
    redirect: '/productTemplate/template',
    meta: { title: '系统产品模板', icon: 'icon' },
    children: [
      {
        name: '后台端-系统产品模板',
        id: 's-100',
        pid: 's-99',
        sort: 1,
        icon: '',
        path: 'template',
        component: 'productTemplate/template',
        meta: { title: '系统产品模板', icon: '' },
        children: []
      },
      {
        name: '后台端-模板管理',
        id: 's-698',
        pid: 's-99',
        sort: 2,
        icon: '',
        path: 'templateManage',
        hidden: true,
        component: 'productTemplate/templateManage',
        meta: { title: '模板管理', icon: '' },
        children: []
      },
      {
        name: '后台端-新增系统模板',
        id: 's-101',
        pid: 's-99',
        sort: 2,
        icon: '',
        path: 'addTemplate',
        hidden: true,
        component: 'productTemplate/addTemplate',
        meta: { title: '新增系统模板', icon: '' },
        children: []
      },
      {
        name: '后台端-字段规则设置',
        id: 's-102',
        pid: 's-99',
        sort: 3,
        icon: '',
        path: 'fieldSettings',
        component: 'productTemplate/fieldSettings',
        meta: { title: '字段规则设置', icon: '' },
        children: []
      },
      {
        name: '后台端-新增/编辑字段',
        id: 's-103',
        pid: 's-99',
        sort: 3,
        icon: '',
        path: 'addOrEditField',
        hidden: true,
        component: 'productTemplate/addOrEditField',
        meta: { title: '新增/编辑字段', icon: '' },
        children: []
      },
      {
        name: '后台端-属性管理',
        id: 's-104',
        pid: 's-99',
        sort: 3,
        icon: '',
        path: 'attributeManage',
        component: 'productTemplate/attributeManage',
        meta: { title: '属性管理', icon: '' },
        children: []
      }
    ]
  },
  {
    name: '后台端-系统管理',
    id: 's-13',
    pid: null,
    sort: 1,
    icon: 'system',
    path: '/system',
    component: 'Layout',
    redirect: '/system/vip',
    meta: { title: '系统管理', icon: 'ic_setting' },
    children: [
      {
        name: '后台端-会员等级设置',
        id: 's-74',
        pid: 's-13',
        sort: 2,
        path: 'vip',
        component: 'system/vip',
        icon: 'user',
        meta: { title: '会员等级设置', icon: '' },
        children: []
      },
      {
        name: '后台端-会员设置',
        id: 's-75',
        pid: 's-13',
        sort: 7,
        icon: 'index',
        path: 'vip/settings',
        component: 'system/vip/settingVip',
        hidden: true,
        meta: { title: '会员设置', icon: '' },
        children: []
      },
      {
        name: '后台端-管理员管理',
        id: 's-14',
        pid: 's-13',
        sort: 10,
        path: 'user',
        component: 'system/user',
        icon: 'user',
        permission: ['ad'],
        meta: { title: '管理员管理', icon: '' },
        children: []
      },
      {
        name: '后台端-管理员添加用户',
        id: 's-44',
        pid: 's-13',
        sort: 8,
        icon: 'role',
        path: 'user/addUser',
        component: 'system/user/addUser',
        hidden: true,
        meta: { title: '添加用户', icon: 'role' },
        children: []
      },
      {
        name: '后台端-管理员编辑用户',
        id: 's-45',
        pid: 's-13',
        sort: 9,
        icon: 'role',
        path: 'user/editUser',
        component: 'system/user/editUser',
        meta: { title: '编辑子账号', icon: 'role' },
        hidden: true,
        children: []
      },
      {
        name: '后台端-字段管理',
        id: 's-697',
        pid: 's-13',
        sort: 9,
        icon: '',
        path: 'fieldManagement',
        component: 'system/fieldManagement',
        meta: { title: '字段管理', icon: '' },
        children: []
      },
      // {
      //   name: '后台端-多端菜单管理',
      //   id: 's-51',
      //   pid: null,
      //   sort: 3,
      //   path: 'allMenus',
      //   icon: 'caidan',
      //   hidden: true,
      //   component: 'system/allMenu',
      //   meta: { title: '多端菜单管理', icon: '' }
      // },
      // {
      //   name: '后台端-权限管理',
      //   id: 's-16',
      //   pid: 's-13',
      //   hidden: true,
      //   sort: 4,
      //   path: 'permissions',
      //   icon: 'permission',
      //   component: 'system/allPermission/index',
      //   meta: { title: '权限管理', icon: '' }
      // },
      {
        name: '后台端-角色管理',
        path: 'roles',
        id: 's-17',
        pid: 's-13',
        sort: 5,
        icon: 'role',
        component: 'system/role/index',
        meta: { title: '角色管理', icon: '' }
      },
      {
        name: '后台端-菜单管理1',
        path: 'menuManage',
        id: 's-70',
        pid: 's-13',
        sort: 5,
        icon: 'role',
        component: 'system/menuManage/index',
        meta: { title: '菜单管理', icon: '' }
      },
      {
        name: '后台端-字典管理',
        path: 'dictionaries',
        id: 's-81',
        pid: 's-13',
        sort: 5,
        icon: '',
        component: 'system/Dictionaries/index',
        meta: { title: '字典管理', icon: '' }
      },
      {
        name: '后台端-平台管理',
        path: 'platform',
        id: 's-82',
        pid: 's-13',
        sort: 5,
        icon: '',
        component: 'system/platform/index',
        meta: { title: '平台管理', icon: '' }
      }
    ]
  },
  {
    name: '后台端-菜单管理 ',
    id: 's-15',
    pid: null,
    sort: 3,
    path: '/menus',
    icon: 'caidan',
    component: 'system/menu',
    meta: { title: '后台端菜单管理', icon: 'caidan' }
  }
]
export default superRouter
