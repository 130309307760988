<template>
  <!-- popover跟随最近的可滚动的父级滚动而滚动：overflow: auto; -->
  <el-popover
    ref="popover"
    style="display: inline-block"
    :popper-class="myClass"
    :placement="placement"
    :width="$attrs.width || 200"
    :title="$attrs.title || '提示'"
    :disabled="disabled || loading"
    :append-to-body="appendToBody"
    v-model="visible"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot name="tip">
      <p>{{ content }}</p>
    </slot>
    <div style="text-align: right; margin: 0" v-if="showBtn">
      <el-button size="mini" :type="cancelButtonType" @click="visible = false">取消</el-button>
      <el-button type="primary" size="mini" @click="sureHandler">确定</el-button>
    </div>
    <div slot="reference">
      <slot name="reference" :popoverLoading="loading" :scope="loading">
        <el-button>删除</el-button>
      </slot>
    </div>
  </el-popover>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    myClass:{
      type:String,
      default:'normal-popover'
    },
    placement: {
      type: String,
      default: 'top'
    },
    cancelButtonType: {
      type: String,
      default: 'text'
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    showBtn: {
      type: Boolean,
      default: true
    },
    content: {
      type: String,
      default: '确定删除吗,如果存在下级节点则节点上升，此操作不能撤销！'
    }
  },
  data() {
    return {
      visible: false,
      loading: false
    }
  },
  methods: {
    async sureHandler() {
      if (this.loading) return
      this.loading = true
      try {
        await this.$listeners.sureHandler.fns(this)
      } catch (err) {
        console.log(err)
      }
      this.loading = false
      this.doClose()
    },
    doClose() {
      this.visible = false
      let { popover } = this.$refs
      if (popover) popover.doClose()
    }
  }
}
</script>

<style lang="scss"></style>