/* eslint-disable */
import { getPageData } from '@/components/avue/utils/util'
import { validatenull } from '@/components/avue/utils/validate'
import { REQUEST_ALL_DATA } from '@/utils/constant'
import { getBaseTableDataMixin } from '@/components/base/baseTable/mixins/index'

export default function (api) {
  return {
    mixins: [
      getBaseTableDataMixin({
        dataAttrs: {
          postData: {}
        }
      })
    ],
    computed: {
      getAllDataApi() {
        return this.api || api
      }
    },
    methods: {
      async getListApi(postData) {
        let allData = (await this.getAllData(postData)) || []
        
        return {
          detail: getPageData(this.filterAllData(allData, postData), postData.page),
          page: {
            ...postData.page,
            total: allData.length
          }
        }
      },
      filterAllData(data) {
        return filterByParams(data, this.filterParams)
      },
      async getAllData(postData) {
        if (!validatenull(this.allData)) return this.allData
      
        let promise = this.allData = awaitResolveDetail(
          this.getAllDataApi({
            ...postData,
            ...REQUEST_ALL_DATA
          })
        )
        return this.allData = await promise
      }
    }
  }
}

export function filterByParams(data, params) {
  if (validatenull(data)) return []
  if (validatenull(params)) return data
  
  data = Array.isArray(data) ? data : [data]
  
  return data.filter(item => {
    let valid = true
    for (const key in params) {
      valid = valid && (validatenull(params[key]) || item[key] === params[key])
    }
    return valid
  })
}