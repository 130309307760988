<template>
  <div id="app">
    <router-view />
    <Mission />
  </div>
</template>

<script>
import Mission from '@/components/mission'
export default {
  name: 'App',
  components: {
    Mission
  }
}
</script>
<style lang="scss">
.scroll-top-class {
  height: 1px;
  width: 1px;
  border-radius: 50%;
  box-shadow: 0px 0px 8px 1px #ccc;
  position: absolute;
  bottom: 120px;
  right: 30px;
  background-color: rgba($color: #666, $alpha: 0.5);
  opacity: 0;
  z-index: 3000;
  cursor: pointer;
  transition: all 1s;
  line-height: 1px;
  font-size: 30px;
  text-align: center;
  color: #ddd;
}
</style>
