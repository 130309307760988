import { login, getInfo, logoutting, refreshToken } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { parseTime } from '@/utils'

const user = {
  state: {
    token: getToken(),
    refreshToken: getToken('refresh'),
    id: '',
    name: '',
    email: '',
    avatar: '',
    createTime: '',
    roles: [],
    is_super: false,
    type: undefined,
    accountType: 0
  },

  mutations: {
    SET_TOKEN: (state, { accessToken, rememberMe, expires }) => {
      state.token = accessToken
      setToken(accessToken, rememberMe, expires)
    },
    SET_REFRESH_TOKEN: (state, { refreshToken, rememberMe, expires }) => {
      state.refreshToken = refreshToken
      setToken(refreshToken, rememberMe, expires, 'refresh')
    },
    SET_ID: (state, id) => {
      state.id = id
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_CREATE_TIME: (state, createTime) => {
      state.createTime = createTime
    },
    SET_EMAIL: (state, email) => {
      state.email = email
    },
    //设置超级管理员
    SET_IS_SUPER: (state, is_super) => {
      state.is_super = is_super
    },
    SET_TYPE: (state, type) => {
      state.type = type
    },
    SET_ACCOUNT_TYPE: (state, type) => {
      state.accountType = type
    },

    CLEAR_TOKEN: (state) => {
      state.token = ''
      removeToken()
    },
    CLEAR_REFRESH_TOKEN: (state) => {
      state.refreshToken = ''
      removeToken('refresh')
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const accountName = userInfo.username
      const password = userInfo.password
      const rememberMe = userInfo.rememberMe
      return new Promise((resolve, reject) => {
        login({ accountName, password })
          .then((res) => {
            if ($SUC(res)) {
              const { accessToken, refreshToken } = res.detail || {}
              const expires = 7
              commit('SET_TOKEN', { accessToken, rememberMe, expires })
              commit('SET_REFRESH_TOKEN', { refreshToken, rememberMe, expires })
              resolve(res)
            }
            reject(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    async GetInfo({ commit }) {
      // eslint-disable-next-line no-undef
      let [, res] = await awaitWrap(getInfo())
      if ($SUC(res)) {
        // accountType 1主账号 2子账号
        const { createTime, email, id, isSuperUser, type = 2, accountName, accountType } = res.detail || {}
        commit('SET_ID', id)
        commit('SET_NAME', accountName)
        commit('SET_EMAIL', email)
        commit('SET_CREATE_TIME', parseTime(createTime))
        commit('SET_IS_SUPER', isSuperUser === 1)
        commit('SET_TYPE', type)
        commit('SET_ACCOUNT_TYPE', accountType)
        if (isSuperUser) {
          commit('SET_ROLES', ['admin'])
        }
        return res
      }
    },
  
    // 刷新Token
    RefreshToken({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        refreshToken()
          .then((res) => {
            if ($SUC(res)) {
              const { accessToken, refreshToken } = res.detail || {}
              const expires = 7
              commit('SET_TOKEN', { accessToken, expires })
              commit('SET_REFRESH_TOKEN', { refreshToken, expires })
              return resolve(res)
            } else {
              return Promise.reject(res)
            }
          })
          .catch((error) => {
            dispatch('GoLogin')
            reject(error)
          })
      })
    },
  
    // 登出
    LogOut({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        logoutting()
          .then((res) => {
            const { code } = res
            if ($SUC({ code })) {
              resolve(true)
              commit('CLEAR_TASKS', [])
            }
          })
          .catch((err) => {
            resolve(false)
          })
          .finally(() => {
            dispatch('GoLogin')
          })
        setTimeout(() => dispatch('GoLogin'))
      })
    },
  
    // 返回登录页
    GoLogin({ commit }) {
      commit('CLEAR_TOKEN')
      commit('CLEAR_REFRESH_TOKEN')
      location.reload() // 为了重新实例化vue-router对象 避免bug
    }
  }
}

export default user
