<template>
  <avue-crud
    ref="crud"
    class="base-table"
    :class="{ 'flex-one-page': finalOption.isOnePage }"
    :search.sync="searchForm"
    :data="finalData"
    :option="finalOption"
    :dic="finalDic"
    :page="tablePage"
    :tableLoading="tableLoading"
    @size-change="sizeChange"
    @current-change="pageChange"
    @search-change="searchChange"
    @search-reset="searchChange"
    @selection-change="handleSelectionChange"
    @search-init="baseTableInit"
    v-bind="$attrs"
    v-on="new$listeners"
  >
    <template v-if="finalList || $scopedSlots.headerBefore" #headerBefore>
      <div class="crud-header-before">
        <baseTabs v-if="finalList" v-model="curTab" :dic="finalList" :beforeChange="beforeTabChange" @change="handleTabChange" @tab-click="handleTabClick"></baseTabs>
        <slot name="headerBefore" :tab="curTabItem"></slot>
      </div>
    </template>
    <template #menuLeftBefore>
      <checkAllData v-if="checkAll" ref="checkAllData" v-bind="checkAllAttrs" v-on="checkAllListeners"></checkAllData>
    </template>

    <template v-for="slot in scopedSlots" v-slot:[slot.prop]="scope">
      <component v-if="components[slot.type]" :is="components[slot.type]" v-bind="handleBindData(scope, slot)"></component>
      <slot v-else :name="slot.prop" v-bind="scope"></slot>
    </template>
  </avue-crud>
</template>

<script>
import { avueCrud, componentMethodsMixin, eventMixin } from '@/mixins'
import { checkAllMixin } from './mixins'

import defaultImg from '@/views/components/defaultImg'

import { cloneDeep, pull } from 'lodash'
import { getDiffData, validateDiffData, validData } from '@/components/avue/utils/util'
import { filterByPermission, getTabValues } from '@/components/base/baseTabs/util'
import { validatenull } from '@/components/avue/utils/validate'
import getParentAttrsMixin from './mixins/getParentAttrs'

let customSlotList = ['headerBefore'] // 自定义插槽

export default {
  inheritAttrs: false,
  mixins: [
    eventMixin('resize'),
    avueCrud({
      isInit: false
    }),
    componentMethodsMixin('crud', ['toggleSelection', 'toggleRowExpansion', 'rowAdd', 'rowEdit']),
    checkAllMixin(),
    getParentAttrsMixin
  ],
  props: {
    list: {},
    // 全部列表数据
    data: {
      type: Array,
      default: () => []
    },
    option: {
      type: Object,
      default: () => ({})
    },
    dic: {
      type: Object,
      default: () => ({})
    },
    search: {
      type: Object,
      default: () => ({})
    },
    showAllLevels: Boolean,
    pageObj: {
      type: Object
    },
    api: Function,
    allDataApi: Function,

    checkAll: Boolean,
    isHandleRealData: Boolean,

    beforeTabChange: Function,
    initOnTabChange: Boolean
  },
  data() {
    return {
      curTab: [],
      unwatchs: {},
      tablePage: {
        pageIndex: 1,
        pageSize: 5,
        total: 0
      },
      DIC: {}
    }
  },
  computed: {
    finalData() {
      let {
        data,
        getList,
        tableData,
        tablePage: { pageIndex, pageSize },
        finalOption: { topPage, page }
      } = this

      if (getList) {
        return tableData
      }

      if (validatenull(data)) return []
      // 无上下分页器，则显示全部数据
      if (topPage === false && page === false) return data
      return data.slice((pageIndex - 1) * pageSize, pageIndex * pageSize)
    },

    finalList() {
      return filterByPermission(this.list)
    },
    listObj({ finalList }) {
      return getTabValues(finalList)
    },
    resetMergeData({ listObj, tabOptions }) {
      let mergeData = this.curTab.reduce((prev, value) => {
        return {
          ...prev,
          ...tabOptions[value]?.resetMergeData,
          ...listObj[value]?.resetMergeData
        }
      }, {})
      return {
        ...tabOptions.default?.resetMergeData,
        ...mergeData,
        ...this.$attrs.resetMergeData,
        ...this.sup_this.resetMergeData
      }
    },
    curTabItem({ tabOptions }) {
      const curTabValue = this.curTab.slice(-1)[0]
      return {
        ...tabOptions.default,
        ...tabOptions[curTabValue],
        ...this.listObj[curTabValue]
      }
    },
    getList() {
      return this.curTabItem.getList || this.api || this.$attrs.getList || this.sup_this.getListApi
    },
    isInit() {
      return validData(this.curTabItem.isInit, this.$attrs.isInit, true)
    },
    curOption() {
      let option = this.curTabItem.option || this.option
      return {
        ...option,
        dic: {
          ...this.dic,
          ...option.dic
        }
      }
    },
    tabOptions() {
      return this.getParentAttrs('tabOptions') || {}
    },

    finalOption({ curOption, finalColumn }) {
      let isOnePage = validData(curOption.isOnePage, true)
      return {
        ...curOption,
        column: finalColumn,
        selection: validData(curOption.selection, this.checkAll),
        search: validData(curOption.search, finalColumn.some(ele => ele.search)),
        isOnePage,
        height: validData(curOption.height, isOnePage ? '100%' : undefined),
        topPage: validData(curOption.topPage, false),
        pageSizes: validData(curOption.pageSizes, [20, 50, 100, 200]),
        editBtn: validData(curOption.editBtn, false),
        delBtn: validData(curOption.delBtn, false),
        selectable: (...args) => {
          // if (this.isCheckAllFromBtn) return false
          return typeof curOption.selectable === 'function' ? curOption.selectable(...args) : true
        }
      }
    },
    finalDic() {
      return {
        ...this.dic,
        ...this.curOption.dic,
        ...this.DIC
      }
    },
    finalColumn({ curOption }) {
      return cloneDeep(validData(curOption.column, []))
    },
    scopedSlots({ finalColumn, slots, componentKeys }) {
      let scopedSlots = slots.map((slot) => slot)
      finalColumn.forEach((column) => {
        // 有设置插槽则开启表单插槽功能
        let tempArr = scopedSlots.filter((slot) => slot.originProp === column.prop)
        if (tempArr.length) {
          tempArr.forEach((slot) => {
            column[slot.slotType] = true
            slot.type = column.type
          })
        }
        if (componentKeys.includes(column.type)) {
          column.slot = true
          scopedSlots.push({
            prop: column.prop,
            type: column.type
          })
        }
      })

      return scopedSlots
    },
    slots({ $scopedSlots }) {
      let scopedSlots = Object.keys($scopedSlots)
      pull(scopedSlots, ...customSlotList) // 删除插槽以便扩展

      let tempArr = []
      scopedSlots.forEach((prop) => {
        let slot = { prop }
        tempArr.push(slot)

        if (/SearchForm$/.test(prop)) {
          slot.slotType = 'searchFormSlot'
          slot.originProp = prop.replace(/SearchForm$/, '')
        } else if (/Header$/.test(prop)) {
          slot.slotType = 'headerSlot'
          slot.originProp = prop.replace(/Header$/, '')
        } else {
          slot.slotType = 'slot'
          slot.originProp = prop
        }
      })
      return tempArr
    },
    componentKeys({ components }) {
      return Object.keys(components)
    },
    components() {
      return {
        defaultImg
      }
    },

    sup_this() {
      return this.$attrs.sup_this || this.$parent
    },
    handleSearchFormProps() {
      return this.$attrs.handleSearchFormProps || this.sup_this.handleSearchFormProps
    },
    new$listeners() {
      return Object.assign(
        {
          ...this.$listeners
        },
        {
          input: () => {}
        }
      )
    }
  },
  watch: {
    curOption: {
      async handler(curOption) {
        this.DIC = await this.$store.dispatch('HandleOption', curOption)
      },
      immediate: true
    },
    data: {
      handler(n, o) {
        this.tablePage.total = n?.length || 0

        if (n !== o) {
          this.tablePage.pageIndex = 1
        }
      },
      immediate: true
    },
    search: {
      handler (search) {
        this.searchForm = Object.assign(this.searchForm, search)
      },
      immediate: true,
      deep: true
    },
    sup_this: {
      handler() {
        this.sup_this.baseTable = this
        this.setParentFns()
      },
      immediate: true
    }
  },
  created() {
    this.initPage()
    if (!this.finalOption.search) this.baseTableInit()
  },
  methods: {
    handleTabChange() {
      this.searchForm = {}
      this.$emit('tab-change', this.showAllLevels ? this.curTab : this.curTab.slice(-1)[0])
      this.$nextTick(function () {
        (this.isInit || this.initOnTabChange) && this.searchChange()
      })
    },
    handleTabClick(...args) {
      this.$emit('tab-click', ...args)
    },

    initPage() {
      let { unwatchs } = this
      if (unwatchs.initPage) unwatchs.initPage()
      unwatchs.initPage = this.$watch(
        function ({ pageObj }) {
          if (pageObj) return pageObj

          let {
            tablePage,
            finalOption: { pageSizes = [5, 10, 15, 20] }
          } = this

          if (getDiffData(pageSizes, this.oPageSizes)) {
            tablePage.pageSize = pageSizes[0]
            this.oPageSizes = pageSizes
          }

          return tablePage
        },
        function (n) {
          n.total = this.tablePage.total
          this.tablePage = n
        },
        {
          immediate: true
        }
      )
    },

    // 解决searchForm默认值未设置即发起请求
    baseTableInit() {
      if (this.isInit && this.getList && !this.finalList) {
        this.init()
      }
    },

    beforeInit() {
      return this.runFn(this.curTabItem.beforeInit || this.sup_this.beforeInit)
    },
    afterInit(res, postData) {
      // getAllData
      if (validateDiffData(this.postData, this.oPostData, ['page'])) this.oAllData = null
      this.oPostData = postData

      // tab 切换时，如果表格列数差距过大，会导致表格样式错乱
      this.$refs.crud?.doLayout()
      this.$nextTick(this.$refs.crud?.doLayout)

      return this.runFn(this.curTabItem.afterInit || this.sup_this.afterInit, res, postData)
    },
    handleTableData(data) {
      return this.runFn(this.curTabItem.handleTableData || this.sup_this.handleTableData, data) || data
    },
    setParentFns() {
      ;['init', 'emptyInit', 'searchChange'].forEach((fnName) => {
        this.sup_this[fnName] || (this.sup_this[fnName] = this[fnName])
      })
    },
    runFn(fn, ...args) {
      return typeof fn === 'function' && fn(...args)
    },

    onresize() {
      this.$nextTick(function () {
        this.$refs.crud?.doLayout()
      })
    },

    handleBindData(scope, slot) {
      if (slot.type === 'defaultImg') {
        scope.src = scope.row[scope.column.property]
      }
      return scope
    },
    validData
  }
}
</script>

<style lang="scss" scoped>
::v-deep.crud-container {
  .el-table {
    border-top: 1px solid $border-color;
    border-left: 1px solid $border-color;
    &::after {
      content: '';
      position: absolute;
      background-color: #ebeef5;
      z-index: 2000;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
    }
  }
  .crud-header-before {
    position: relative;
    margin-bottom: 32px;
  }
  .base-tabs {
    .base-tabs {
      margin-top: 16px;
    }
  }
}
</style>
