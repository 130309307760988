import cloneDeep from 'lodash/cloneDeep'
const checkImage = {
  namespaced: true,
  state: {
    checkImage: []
  },
  mutations: {
    SET_CHECK_IMAGE(state, data) {
      if (data.length && data.length > 0) {
        data.forEach((item) => {
          if (item.isRight) {
            item.sizePsdGroupCheckList.forEach((item) => {
              item.isRight = 1
            })
          }
        })
      }
      state.checkImage = data
    },
    SET_CHECK_IMAGE_GROUP(state, { id, index, isRight }) {
      const idx = state.checkImage.findIndex((item) => item.id === id)
      const checkImage = cloneDeep(state.checkImage)
      checkImage[idx].sizePsdGroupCheckList[index].isRight = isRight
      if (!isRight && checkImage[idx].isRight) {
        checkImage[idx].isRight = 0
      }
      state.checkImage = checkImage
    }
  },
  actions: {}
}

export default checkImage
