import { publicAdd as add } from '@/api/imageApi'
import { Message } from 'element-ui'
import { UP_STATUS } from '@/utils/constant'
const product = {
  state: {
    productObj: {},
    tasks: [],
    taskDialog: false
  },
  mutations: {
    TOGGLE_PRODUCT_OBJ(state, productObj) {
      sessionStorage.setItem('productObj', JSON.stringify(productObj || {}))
      state.productObj = productObj
    },
    CLEAR_TASKS(state, tasks) {
      state.tasks = tasks
    },
    UPDATE_TASKS(state, form) {
      const tasks = state.tasks
      tasks.push(form)
      state.tasks = tasks
    },
    CHANGE_TASK(state, task) {
      const tasks = state.tasks
      const idIndex = findSame(task, tasks)
      if (idIndex >= 0) {
        tasks.splice(idIndex, 1, task)
      }
      if (idIndex == -1) {
        tasks.push(task)
      }
    },
    TOGGLE_TASK_STAUS(state, form) {
      const tasks = state.tasks
      const idIndex = findSame(form, tasks)
      const { msg, title } = form || {}
      if (idIndex >= 0) {
        tasks[idIndex].status = msg ? '4' : '3'
        Message.success({
          type: msg ? 'danger' : 'success',
          message: `${title}${UP_STATUS[tasks[idIndex].status].label}`
        })
      }
    },
    SET_TASK_DIALOG(state, bool) {
      state.taskDialog = bool
    }
  },
  actions: {
    async ProAdd({ commit }, form) {
      console.log('form', form)
      const { title, name, categoryId, thumbnailPath, raw, width, height } = form || {}

      // const formData = new FormData()
      // formData.append('path', raw)
      // formData.append('title', title)
      // formData.append('isPublic', true)
      // formData.append('width', width)
      // formData.append('height', height)

      // if (categoryId) {
      //   formData.append('categoryId', categoryId)
      // }
      // formData.append('thumbnailPath', thumbnail_path, name)
      commit('UPDATE_TASKS', {
        ...form,
        status: '0'
      })

      //不能这么赋值，故删除
      // if (thumbnailPath) {
      //   thumbnailPath.name = name
      // }

      // eslint-disable-next-line no-undef
      const uploadedOssPicObj = await $uploadOSSPics([
        {
          files: [raw],
          // eslint-disable-next-line no-undef
          dirPrefix: $ossDirMapWithType['0'],
          prop: 'path',
          config: {
            onUploadProgress: (progressEvent) => {
              const { total = 0.000001, loaded = 0 } = progressEvent || {}
              commit('CHANGE_TASK', {
                ...form,
                loaded,
                total,
                status: total == loaded ? '2' : '1'
              })
            }
          },
          sucCallBack: (res) => {
            commit('TOGGLE_TASK_STAUS', {
              ...form,
              ...{ status: '3' }
            })
          },
          errCallBack: (res) => {
            commit('TOGGLE_TASK_STAUS', {
              ...form,
              ...{ status: '4' }
            })
          }
        },
        {
          files: [thumbnailPath],
          prop: 'thumbnailPath',
          // eslint-disable-next-line no-undef
          dirPrefix: $ossDirMapWithType['0'],
          uuidSuffix: '_t'
        }
      ])
      if (!uploadedOssPicObj) return
      
      const formData = {
        path: uploadedOssPicObj.path,
        thumbnailPath: uploadedOssPicObj.thumbnailPath,
        title,
        isPublic: 1,
        disable: 0,
        width,
        height
      }

      if (categoryId) {
        formData.categoryId = categoryId
      }

      return add(formData)
    }
  }
}

function findSame(task, data) {
  const { title } = task
  const unicodeId = `${title}`
  const fIndex = data.findIndex(({ title }) => {
    return unicodeId == `${title}`
  })
  return fIndex
}

export default product
