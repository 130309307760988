import { createRandomNum, getImgDom, getUUID, parseImgSrc } from '@/utils'
import { isNumber } from '@/components/avue/utils/validate'

export const PIC_SIZE = {
  min: '?x-oss-process=image/format,webp/resize,l_80,h_80',
  small: '?x-oss-process=image/format,webp/resize,l_150,h_150',
  mid: '?x-oss-process=image/format,webp/resize,l_350,h_350',
  large: '?x-oss-process=image/format,webp/resize,l_750,h_750'
}

export const PIC_BIG_SIZE = 3 * 1024 * 1024

//压缩图片最大宽度
export const COMPRESS_MAX_NUM = 800

export const KNIFE_PRO_SHOW_ITEM = {
  coord_show: [],
  white_show: [],
  show_masks: [],
  shadow_show: []
}

//公共图片禁用/启用
export const PUBLIC_PIC_DISABLE = 1
export const PUBLIC_PIC_ENABLE = 0

//图片上传到oss的uuid和原本文件名称的分隔符
export const OSS_SEPARATOR = '@@@@@'

export function getALiCompressUrl(src, size = 'min', noUseCache, validALiCompress = true) {
  // await new Promise(resolve => setTimeout(resolve, 1000))
  if (!src) return ''
  if (/^blob:/.test(src)) return src
  
  src = parseImgSrc(src) // 解决路径包含特殊字符的图片不显示问题
  if (!size) return aLiCompressPicByUrl(src, false, noUseCache)
  // size要在这之后判断：包含force__的size在这之后才会被取出正常size
  try {
    if (size.includes('force__')) {
      size = size.replace('force__', '')
      src = src
        .replace(/((x-oss-process=image)[^&]*&*)((uid=)[^&]*&*)?/g, '')
        .replace(/\?$/, '')
    }
  } catch {}
  if (size === 'origin') return aLiCompressPicByUrl(src, false, noUseCache)
  // size包含force__时，会去掉x-oss-process
  if (src.includes('x-oss-process')) return aLiCompressPicByUrl(src, false, noUseCache, validALiCompress)
  
  let picSize = PIC_SIZE[size]
  if (picSize) src = `${src}${src.includes('?') ? picSize.replace('?', '&') : picSize}`
  
  return aLiCompressPicByUrl(src, size, noUseCache, validALiCompress)
}
//获取缩略图
export async function getThumbnail(src, size = 'min', uid) {
  if (!src) return ''
  if (/^blob:/.test(src)) return src

  let picSize = isNumber(size) ? `?x-oss-process=image/resize,l_${size}` : PIC_SIZE[size]
  picSize = src.includes('?') ? picSize.replace('?', '&') : picSize
  let img = await getImgDom(`${src}${picSize}&uid=${uid || createRandomNum()}`)
  if (img) return img.src
}

export async function getThumbnailSrc(src, size, uid) {
  // await new Promise(resolve => setTimeout(resolve, 1000))
  if (!src) return ''
  if (/^blob:/.test(src)) return src

  src = parseImgSrc(src)
  if (!size) return src

  try {
    if (size.includes('force__')) {
      size = size.replace('force__', '')
      src = src.replace(/((x-oss-process=image)[^&]*&*)((uid=)[^&]*&*)?/g, '').replace(/\?$/, '')
    }
  } catch (e) {}
  if (size === 'origin') return src
  if (src.includes('x-oss-process=')) return src
  let res = await getThumbnail(src, size, uid)
  return res || src
}

//图片分组状态
export const PICTURE_GROUP_DISENABLE = 0
export const PICTURE_GROUP_ENABLE = 1

export const PICTURE_GROUP_ENABLE_OBJ = {
  [PICTURE_GROUP_ENABLE]: '已启用',
  [PICTURE_GROUP_DISENABLE]: '未启用'
}

//默认公共图片分组
export const PICTURE_GROUP_NOT_IS_DEFAULT = 0
export const PICTURE_GROUP_IS_DEFAULT = 1

export const PICTURE_GROUP_DEFAULT_OBJ = {
  [PICTURE_GROUP_IS_DEFAULT]: '是',
  [PICTURE_GROUP_NOT_IS_DEFAULT]: '否'
}

export const PICTURE_GROUP_DEFAULT_DIC = [
  {
    label: '全部',
    value: 'all'
  },
  {
    label: '是',
    value: PICTURE_GROUP_IS_DEFAULT
  },
  {
    label: '否',
    value: PICTURE_GROUP_NOT_IS_DEFAULT
  }
]

export const PICTURE_GROUP_ENABLE_DIC = [
  {
    label: '全部',
    value: 'all'
  },
  {
    label: '未启用',
    value: PICTURE_GROUP_NOT_IS_DEFAULT
  },
  {
    label: '已启用',
    value: PICTURE_GROUP_IS_DEFAULT
  }
]

/**
 * 获取oss上缩略图
 * @param url
 * @param longNum 压缩长边值
 * @param noUseCache 是否使用缓存图片
 * @param validALiCompress 是否开启缩略图缩略图压缩判断
 * @returns {string|Promise<string|*|undefined>} 开启缩略图压缩返回的是promise,其他情况返回处理过后的路径
 */
export function aLiCompressPicByUrl(url, longNum, noUseCache = false, validALiCompress = false) {
  if (!longNum && longNum !== false) {
    longNum = url
    url = ''
  }
  if (!url) {
    return `?x-oss-process=image/resize,l_${longNum}`
  }
  try {
    const urlInstance = new URL(url)
    if (!validALiCompress) {
      return createForMatUrl(urlInstance)
    }
    return getPassValidALiCompress(urlInstance)
  } catch {
    return url
  }
  
  function createForMatUrl(urlInstance) {
    const searchParams = urlInstance.searchParams
    if (longNum !== false && !searchParams.has('x-oss-process')) {
      searchParams.set('x-oss-process', `image/resize,l_${longNum}`)
    }
    if (noUseCache && !searchParams.has('uid')) {
      searchParams.set('uid', getUUID().replace(/-/g, ''))
    }
    return decodeURIComponent(urlInstance.href)
  }
  
  async function getPassValidALiCompress(urlInstance) {
    let formatUrl
    try {
      formatUrl = createForMatUrl(urlInstance)
      const success = await loadImage(formatUrl)
      if (success) return formatUrl
      throw new Error('image cross the border')
    } catch {
      return replaceOssProcess(formatUrl)
    }
  }
  
  function replaceOssProcess(formatUrl = url) {
    return formatUrl.replace('x-oss-process', 'x-oss-process-error')
  }
  
  function loadImage(rawUrl) {
    const img = new Image()
    return new Promise(resolve => {
      function sucFn() {
        offFn()
        resolve(true)
      }
      
      function errFn() {
        offFn()
        resolve(false)
      }
      
      function offFn() {
        img.removeEventListener('load', sucFn)
        img.removeEventListener('error', errFn)
      }
      
      img.addEventListener('load', sucFn)
      img.addEventListener('error', errFn)
      img.src = rawUrl
    })
  }
}
