//自定义底板
export const ORIGIN_SHOW_AREA_W = 2000
export const ORIGIN_SHOW_AREA_H = 2000
export const PIC_INIT_W = 1000
export const PIC_INIT_H = 1000
export const DESIGN_SHOW_AREA_W = 1000
export const DESIGN_SHOW_AREA_H = 1000
export const DESIGN_AREA_W = 1000
export const DESIGN_AREA_H = 1000
export const RECT_LINE_WIDTH = 2 //线宽度
export const FILL_COLOR = '#3841DB' //线条填充颜色
export const DESIGN_PROPORTION = DESIGN_AREA_W / DESIGN_SHOW_AREA_W
export const DIR_AREA_NUM = DESIGN_SHOW_AREA_W - DESIGN_AREA_W

export const SINGLE_PROTO_CUSTOM_FLOOR = 1 //单品自定义底板
export const COMBINATIONM_PROTO_CUSTOM_FLOOR = 2 //组合产品自定义模板

export const DISABLE_CUSTOM_FLOOR_LAYOUT = '0' //禁用自定义模板
export const ENABLE_CUSTOM_FLOOR_LAYOUT = '1' //启用自定义底板

//自定义底板启用，禁用列表
export const ENABLE_CUSTOM_FLOOR_LAYOUT_DATA = [
  {
    value: 'all',
    label: '全部'
  },
  {
    label: '启用',
    value: ENABLE_CUSTOM_FLOOR_LAYOUT
  },
  {
    label: '禁用',
    value: DISABLE_CUSTOM_FLOOR_LAYOUT
  }
]
