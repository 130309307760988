/* eslint-disable */
import { getValueFromObj } from '@/utils'
import { REQUEST_ALL_DATA } from '@/utils/constant'
import { handleSelectionData, validateSelectionData, getSelectionData } from '@/views/components/checkAllData3/utils'
import checkAllData from '@/views/components/checkAllData3'
import { initData } from '@/api/data'

let elTableInstance = {
  checkbox: 'elTableInstance',
  avueCrud: '$refs.crud.$refs.table',
  table: '$refs.table'
}
export default function ({ type = 'avueCrud', apiType = type, isHandleRealData } = {}) {
  return {
    components: {
      checkAllData
    },
    data() {
      return {
        // oAllData: null,
        isCheckAllFromBtn: false, // 点击全选按钮时的全选
        requestData: null,
        checkedList: [],
        checkAllStatus: '',
        selectionDataAll: [],
        selectionDataAllArr: [],
        selectionCount: 0,
        
        // 当前页全选
        curPageIndeterminate: false,
        curPageCheckedAll: false
      }
    },
    computed: {
      checkedAll() {
        return this.checkAllStatus === 'all'
      },
      checkAllAttrs() {
        let {
          page,
          size,
          total,
          tablePage = {
            pageIndex: page,
            pageSize: size,
            total: total
          },
          selectable,
          selectedData,
          data,
          requestData,
          selectionData = selectedData,
          isHandleRealData
        } = this
        return {
          selectable,
          selectionData,
          isHandleRealData,
          tableData: requestData || data,
          page: tablePage.pageIndex,
          size: tablePage.pageSize,
          total: tablePage.total,
          isPageChange: this.isPageChange,
          setIsPageChangeFalse: this.setIsPageChangeFalse,
          getAllData: this.getAllData,
          getElTableInstance: this.getElTableInstance,
          sup_this: this
        }
      },
      checkAllListeners() {
        return {
          checkAllStatus: this.checkAllStatusChange,
          selectionChange: this.selectionChange,
          selectionCountChange: this.selectionCountChange,
          checkChange: (checked) => this.isCheckAllFromBtn = checked,
          'hook:destroyed': () => this.isCheckAllFromBtn = false
        }
      },
      elTableInstance() {
        return {
          toggleAllSelection: () => {
            this.checkedList = this.tableData.map((i, index) => index)
            this.handleCheckedChange(this.checkedList)
          },
          toggleRowSelection: (row, selected) => {
            if (selected) {
              let index = this.tableData.findIndex((item) => item.id === row.id)
              if (!this.checkedList.includes(index) && index > -1) {
                this.checkedList.push(index)
              }
            }
            this.handleCheckedChange(this.checkedList)
          },
          clearSelection: () => {
            this.checkedList = []
            this.handleCheckedChange(this.checkedList)
          }
        }
      },
      getAllDataApi({ allDataApi }) {
        if (['checkbox', 'avueCrud'].includes(apiType)) {
          return () => {
            return (allDataApi || this.getList)({
              ...this.postData,
              ...REQUEST_ALL_DATA
            })
          }
        } else if (apiType === 'table') {
          return () => {
            return (allDataApi || initData)(this.url, this.method, {
              ...this.params,
              ...REQUEST_ALL_DATA
            })
          }
        }
      }
    },
    methods: {
      validateSelectionData,
      
      handleDialogOpen(param, dialogRefName) {
        return this.handleSelectionData(param, this.$refs[dialogRefName].dialogOpen)
      },
      async getSelectionData(row) {
        let getSelectionData = await this.handleSelectionData(row)
        if (!getSelectionData) return
        return getSelectionData()
      },
      handleSelectionData(param, callback) {
        return handleSelectionData(this, param, callback)
      },
      handleCheckedChange(value) {
        let { tableData } = this
        let tempArr = []
        value.forEach((i) => {
          tempArr.push(tableData[i])
        })
        // console.log(tempArr)
        this.handleSelectionChange(tempArr)
        
        // 当前页全选
        if (this.tableData.length) {
          let checkedCount = value.length
          this.curPageCheckedAll = checkedCount === this.tableData.length
          this.curPageIndeterminate = checkedCount > 0 && checkedCount < this.tableData.length
        }
      },
      handleSelectionChange(selection) {
        this.selectionData = selection
        if (!this.checkAll) this.selectionChange(selection)
      },
      
      initCallBack(res, postData) {
        this.checkAllInitCallBack(res, postData)
      },
      afterInit(res, postData) {
        this.checkAllInitCallBack(res, postData)
      },
      checkAllInitCallBack(res, postData) {
        let requestData = res?.detail || []
        this.requestData = requestData
        let initCallBack = getValueFromObj(this.$refs.checkAllData, 'initCallBack')
        if (initCallBack) initCallBack(requestData, postData?.page?.pageIndex)
        this.checkedList = []
      },
      checkAllStatusChange(status) {
        this.checkAllStatus = status
        status !== 'all' && (this.isCheckAllFromBtn = false)
        // console.log(this.selectionDataAllArr)
      },
      selectionChange(selectionDataAllArr) {
        this.selectionDataAll = selectionDataAllArr
        this.selectionDataAllArr = selectionDataAllArr.map(({ id }) => id)
        this.$emit('selection-change', selectionDataAllArr)
      },
      selectionCountChange(selectionCount) {
        this.selectionCount = selectionCount
      },
      async getSelectionDataAllArr() {
        if (!this.checkAll) return this.selectionData
        let tempArr = await this.$refs.checkAllData.getSelectionDataAllArr()
        this.selectionDataAllArr = tempArr.map(({ id }) => id)
        // console.log(tempArr)
        return tempArr
      },
      async getAllData() {
        if (this.oAllData) return this.oAllData
        this.oAllData = awaitResolveDetail(this.getAllDataApi())
        let detail = await this.oAllData
        if (!detail) {
          this.$message.warning('获取数据失败，请重试')
        }
        return this.oAllData = detail
      },
      getElTableInstance() {
        return getValueFromObj(this, elTableInstance[type])
      }
    }
  }
}
