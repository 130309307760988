import { render, staticRenderFns } from "./customDropdown.vue?vue&type=template&id=0c44711c&scoped=true&functional=true&"
var script = {}
import style0 from "./customDropdown.vue?vue&type=style&index=0&id=0c44711c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "0c44711c",
  null
  
)

export default component.exports