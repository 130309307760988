import { isArray } from 'lodash'

import Schema from 'async-validator'
import Vue from 'vue'

export function validatorFactory({ reg, errMsg, modifiers }) {
  if (!(reg instanceof RegExp)) {
    reg = new RegExp(reg, modifiers)
  }
  return function (rule, value, callback) {
    if (isEmpty(value)) {
      return callback()
    }
    reg.lastIndex = 0
    const rsCheck = reg.test(value)
    if (!rsCheck) {
      callback(errMsg)
    } else {
      callback()
    }
  }
}
export const validators = {
  number: {
    reg: /^[0-9]*$/,
    errMsg: '请输入数字'
  },
  intFloat: {
    reg: /^(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*))$/,
    errMsg: '请输入数字'
  },
  notNFloating: {
    reg: /^\d+(\.\d+)?$/,
    errMsg: '请输入数字'
  },
  money: {
    reg: /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0){1}$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/,
    errMsg: '金额格式错误'
  },
  twoFixedInteger: {
    reg: /^(([0-9]+)|([0-9]+\.[0-9]{0,2}))$/,
    errMsg: '请输入至多两位小数的正整数'
  }
}
for (const type in validators) {
  validators[type] = validatorFactory(validators[type])
}

export function isEmpty(value) {
  return value === undefined || value === '' || value === null
}

export function validateSpecialCharacter(rule, value, callback) {
  if (value.indexOf('管理员') != -1 || value.toLowerCase().indexOf('admin') != -1) {
    callback('昵称中不能包含admin或管理员')
  }
  callback()
}

/* 合法uri*/
export function validateURL(textval) {
  const urlregex =
    /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return urlregex.test(textval)
}

/* 小写字母*/
export function validateLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/* 大写字母*/
export function validateUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/**
 * 验证邮箱
 * @param str
 * @returns {boolean}
 */
export function validatEmail(str) {
  const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
  return reg.test(str)
}

// 手机号验证
export function isvalidPhone(str) {
  const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
  return reg.test(str)
}

// 不能输入中文
export function isvalidChinaese(str) {
  const reg = /^[a-zA-Z0-9_]+$/g
  return reg.test(str)
}

//输入中英文下划线和数字
export function isReasonableText(str) {
  // return /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/.test(str)
  return /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/.test(str)
}

//只适用element-ui库 的校验方法
// 验证是否正整数

// 手机号验证
export function isTele(rule, value, callback) {
  if (isEmpty(value)) {
    return callback()
  }
  const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
  const rsCheck = reg.test(value)
  if (!rsCheck) {
    callback('请输入正确的手机号')
  } else {
    callback()
  }
}

// 联系方式验证
export function isContactPhone(rule, value, callback) {
  if (isEmpty(value)) {
    return callback()
  }
  const reg = /^(\d{3,4}|\d{3,4}-|\s)+$/
  const rsCheck = reg.test(value)
  if (!rsCheck) {
    callback('请输入正确的手机号')
  } else {
    callback()
  }
}

/* 大小写字母*/
export function validatAlphabets(rule, value, callback) {
  if (isEmpty(value)) {
    return callback()
  }
  const reg = /^[A-Z\sa-z\d-_]+$/
  const rsCheck = reg.test(value)
  if (!rsCheck) {
    callback('请输入大小写字母')
  } else {
    callback()
  }
}

// 验证非正整数（负整数 + 0）
export function isNegativeAndZero(val) {
  return /^((-\d+)|(0+))$/.test(val)
}
// 验证是否是正整数
export function isInt(num) {
  return /^[1-9][0-9]*$/.test(num)
}
export function isInteger(rule, value, callback) {
  if (isEmpty(value)) {
    return callback()
  }
  const re = /^[1-9][0-9]*$/
  const rsCheck = re.test(value)
  if (!rsCheck) {
    callback(new Error('请输入正整数'))
  } else {
    callback()
  }
}

export function isMoreZero(rule, value, callback) {
  // console.log('value', value)
  if (isEmpty(value)) {
    return callback()
  }
  const re = /^(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*))$/
  const rsCheck = re.test(value)
  // console.log('rsCheck', rsCheck)
  if (!rsCheck) {
    callback(new Error('请输入大于0的数'))
  } else {
    callback()
  }
}

// 数字验证
export function validNumber(rule, value, callback) {
  if (/^[+-]?(0|([1-9]\d*))(\.\d+)?$/.test(value)) {
    return callback()
  }
  const reg = /^\d+(\.\d+)?$/
  const rsCheck = reg.test(value)
  if (!rsCheck) {
    callback('请输入数字')
  } else {
    callback()
  }
}
export function isNumber(value) {
  return /^[+-]?(0|([1-9]\d*))(\.\d+)?$/.test(value)
}

// 验证是否是整数
export function isIntAndZero(num) {
  return /^[0-9]*$/.test(num)
}
export function isIntegerAndZero(rule, value, callback) {
  if (value === undefined || value === '') {
    return callback(new Error('输入不可以为空'))
  }
  const re = /^[0-9]*$/
  const rsCheck = re.test(value)
  if (!rsCheck) {
    callback(new Error('请输入大于等于0的整数'))
  } else {
    callback()
  }
}

// 验证是否是金额_
export function isPrice(value) {
  return /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0){1}$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/.test(value)
}

// 验证是否是金额_
export function isMoney(rule, value, callback) {
  if (isEmpty(value)) {
    return callback()
  }
  const re = /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0){1}$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/
  const rsCheck = re.test(value)
  if (!rsCheck) {
    callback(new Error('金额格式错误'))
  } else {
    callback()
  }
}

export function validateNotNegative(value) {
  return /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0){1}$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/.test(value + '')
}
// 验证是否为非负数
export function notNegative(rule, value, callback) {
  if (isEmpty(value)) {
    return callback()
  }
  const re = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/
  const rsCheck = re.test(value)
  if (!rsCheck) {
    callback(new Error('请输入非负数'))
  } else {
    callback()
  }
}

// 验证是否数字字母_
export function isCommonCharacters(rule, value, callback) {
  if (value === undefined || value === '') {
    return callback()
  }
  const re = /^\w+$/
  const rsCheck = re.test(value)
  if (!rsCheck) {
    callback(new Error('只能输入数字字母下划线'))
  } else {
    callback()
  }
}

// 验证是否数字字母_和点字符
export function isCommonCharactersAndPoint(rule, value, callback) {
  if (value === undefined || value === '') {
    return callback()
  }
  const re = /^[\w-.+]+$/
  const rsCheck = re.test(value)
  if (!rsCheck) {
    callback(new Error('只能输入数字字母，下划线，加号。'))
  } else {
    callback()
  }
}
/* 邮件*/
export function isEmail(rule, value, callback) {
  if (isEmpty(value)) {
    return callback()
  }
  const reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const rsCheck = reg.test(value)
  if (!rsCheck) {
    callback('请输入正确的Email')
  } else {
    callback()
  }
}

// 至少8-16个字符，至少1个大写字母，1个小写字母和1个数字，其他可以是任意字符
export function isRobust(rule, value, callback) {
  if (isEmpty(value)) {
    return callback()
  }
  const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/
  const rsCheck = reg.test(value)
  if (!rsCheck) {
    callback('至少8-16个字符，至少存在1个大写字母，1个小写字母和1个数字')
  } else {
    callback()
  }
}

// 字符长度限制 validator构造函数
export function charLenLimitConstructor(min, max, err) {
  const reg = new RegExp(`^[.\\s\\S]{${min},${max}}$`)
  err = err || `请输入${min}-${max}个字符`
  return function (rule, value, callback) {
    if (isEmpty(value)) {
      return callback()
    }
    reg.lastIndex = 0
    const rsCheck = reg.test(value)
    if (!rsCheck) {
      callback(err)
    } else {
      callback()
    }
  }
}

//至少2-6个字符
export function is2And6char(rule, value, callback) {
  if (isEmpty(value)) {
    return callback()
  }
  const reg = /^.{2,6}$/
  const rsCheck = reg.test(value)
  if (!rsCheck) {
    callback('2-6个字符')
  } else {
    callback()
  }
}

//至少1-100个字符
export function is1And100char(rule, value, callback) {
  if (isEmpty(value)) {
    return callback()
  }
  const reg = /^.{1,100}$/
  const rsCheck = reg.test(value)
  if (!rsCheck) {
    callback('1-100个字符')
  } else {
    callback()
  }
}

//至少1-60个字符
export function is1And60char(rule, value, callback) {
  if (isEmpty(value)) {
    return callback()
  }
  const reg = /^.{1,60}$/
  const rsCheck = reg.test(value)
  if (!rsCheck) {
    callback('1-60个字符')
  } else {
    callback()
  }
}

//至少4-20个字符
export function is4And20char(rule, value, callback) {
  if (isEmpty(value)) {
    return callback()
  }
  const reg = /^.{4,20}$/
  const rsCheck = reg.test(value)
  if (!rsCheck) {
    callback('至少4-20个字符')
  } else {
    callback()
  }
}

// 最多10个字符
export function isSmall10char(rule, value, callback) {
  if (isEmpty(value)) {
    return callback()
  }
  if (value?.length > 10) {
    callback('最多10个字符')
  } else {
    callback()
  }
}

/* 大小写字母数字、_*/
export function isW(rule, value, callback) {
  if (isEmpty(value)) {
    return callback()
  }
  const reg = /^\w+$/
  const rsCheck = reg.test(value)
  if (!rsCheck) {
    callback('请输入大小写字母、数字、_')
  } else {
    callback()
  }
}

// 最大值限制构造函数
export function numMaxConstruct(max, err) {
  max = Number(max)
  err = err || `最大值为${max}`
  return function (rule, value, callback) {
    if (isEmpty(value)) {
      return callback()
    }
    if (Number(value) > max) {
      callback(err)
    } else {
      callback()
    }
  }
}

//输入中英文下划线和数字
export function validateReasonableText(rule, value, callback) {
  if (value === undefined || value === '') {
    return callback()
  }
  const re = /^[\u4e00-\u9fa5_a-zA-Z0-9.]+$/
  const rsCheck = re.test(value)
  if (!rsCheck) {
    callback(new Error('只能输入中英文下划线、数字和英文句点'))
  } else {
    callback()
  }
}

/**
 * 根据column校验数据
 * @param column
 * @param data
 * @param option
 * @returns {unknown[]|Promise<Awaited<unknown>[]>}
 */
export function fillValidErrorPassColumnInData(column, data, option = {}) {
  let { callback, isAsync, clearErrorBeforeValid = true, ...restOption } = option
  if (!isArray(data)) data = [data]
  if (clearErrorBeforeValid) {
    data.map(item => {
      Vue.delete(item, 'asyncValidatorErrorList')
    })
  }
  const descriptor = createDescriptorByColumn(column)
  const validator = new Schema(descriptor)
  const cn = {
    required: '$label必填'
  }
  validator.messages(cn)
  callback = callback || function (
    {
      errors,
      fields,
      row
    }
  ) {
    if (!errors) return
    errors.map(item => {
      let { field, message } = item
      const errProp = `_${field}_errMsg`
      const fItem = column.find(item => item.prop == field)
      const label = $GET(fItem, 'label', '')
      if (label && message.indexOf('$label') >= 0) {
        message = message.replace('$label', fItem.label).replace(field, '')
      }
      if (!row.asyncValidatorErrorList) {
        Vue.set(row, 'asyncValidatorErrorList', {})
      }
      Vue.set(row.asyncValidatorErrorList, errProp, message)
    })
  }
  delete option.callback
  const result = []
  const pArr = []
  data.map((item, index) => {
    const resItem = result[index] = {
      errors: [],
      fields: [],
      row: item
    }
    const p = validator.validate(item, restOption, (errors, fields) => {
      callback({
        errors,
        fields,
        row: item
      })
      resItem.errors = errors
      resItem.fields = fields
    })
    pArr.push(p)
  })

  if (isAsync) {
    return Promise.all(result).then(() => {
      return result
    })
  }
  return result
}

/**
 * 格式化描述符，用于async-validator
 * @param column
 * @returns {*}
 */
export function createDescriptorByColumn(column) {
  return column.reduce((cur, prev) => {
    const { prop } = prev
    const rules = prev.rules || []
    cur[prop] = rules
    return cur
  }, {})
}

