import { render, staticRenderFns } from "./commonTable copy.vue?vue&type=template&id=61a5ead6&scoped=true&"
import script from "./commonTable copy.vue?vue&type=script&lang=js&"
export * from "./commonTable copy.vue?vue&type=script&lang=js&"
import style0 from "./commonTable copy.vue?vue&type=style&index=0&id=61a5ead6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61a5ead6",
  null
  
)

export default component.exports