import { REQUEST_ALL_DATA } from '@/utils/constant/requestConst'

// 菜单列表 menuType 0菜单 1按钮 2其它
export const MENU_TYPE_MENU = 0
export const MENU_TYPE_BTN = 1
export const MENU_TYPE_OTHER = 2
export const MENU_TYPES = {
  menu: MENU_TYPE_MENU,
  btn: MENU_TYPE_BTN,
  other: MENU_TYPE_OTHER
}
export function getXMenuType(type = 'other') {
  return {
    'x-menu-type': MENU_TYPES[type]
  }
}

// 客户端类型 16对外分销业务端 17对外分销后台

export const CLIENT_TYPE_EXTERNAL_BUSINESS = 16
export const CLIENT_TYPE_EXTERNAL_ADMIN = 17

export const MENU_DIC_NAMES = {
  [CLIENT_TYPE_EXTERNAL_BUSINESS]: 'externalBusinessMenuList',
  [CLIENT_TYPE_EXTERNAL_ADMIN]: 'externalAdminMenuList',
}

//当前平台类型
export const CURRENT_MENU_TYPE = CLIENT_TYPE_EXTERNAL_ADMIN

export const MENU_DIC = {
  [CLIENT_TYPE_EXTERNAL_BUSINESS]: {
    label: '对外分销业务端菜单',
    value: CLIENT_TYPE_EXTERNAL_BUSINESS,
    name: MENU_DIC_NAMES[CLIENT_TYPE_EXTERNAL_BUSINESS],
    secret: 'jl3jeWOkmqwkRNJq',
    requestPrefix: 'externalbusiness',
    headers: {
      'x-client-id': 'zdExternalBusiness',
      'x-client-type': CLIENT_TYPE_EXTERNAL_BUSINESS
    },
    tokenKey: {
      access: 'x-zdExternalBusiness-access-token',
      refresh: 'x-zdExternalBusiness-refresh-token'
    }
  },
  [CLIENT_TYPE_EXTERNAL_ADMIN]: {
    label: '对外分销后台菜单',
    value: CLIENT_TYPE_EXTERNAL_ADMIN,
    name: MENU_DIC_NAMES[CLIENT_TYPE_EXTERNAL_ADMIN],
    secret: 'rgKUmMZLLiBWRzd2',
    requestPrefix: 'externaladmin',
    headers: {
      'x-client-id': 'zdExternalAdmin',
      'x-client-type': CLIENT_TYPE_EXTERNAL_ADMIN
    },
    tokenKey: {
      access: 'x-zdExternalAdmin-access-token',
      refresh: 'x-zdExternalAdmin-refresh-token'
    }
  }
}

//获取当前平台的配置
export const CURRENT_CLIENT_CONFIGURE = MENU_DIC[CURRENT_MENU_TYPE]
// 权限前缀
export const PERMISSION_PREFIX = CURRENT_CLIENT_CONFIGURE.requestPrefix

export const GET_PERMISSION_PREFIX = (code) => {
  return new RegExp(`^${PERMISSION_PREFIX}:`).test(code) ? code : `${PERMISSION_PREFIX}:${code.replace(/^:/, '')}`
}

export const REQUEST_PREFIX = CURRENT_CLIENT_CONFIGURE.requestPrefix

//获取请求前缀
export const GET_REQUEST_PREFIX = (url) => {
  return /^\/?common/.test(url) ? url : `/${REQUEST_PREFIX}/${url.replace(/^\//, '')}`
}

//获取平台类型字典
export function GET_CLIENT_TYPE_DIC() {
  const clientTypeDic = {}
  for (let clientType in MENU_DIC) {
    const prop = MENU_DIC[clientType].name
    //额外新增的MenuList
    const addMenuList = []
    //默认当前平台
    addMenuList.push(CURRENT_CLIENT_CONFIGURE.value)
    clientTypeDic[prop] = {
      url: GET_REQUEST_PREFIX('common/authService/menu/wmsTreeMenuList'),
      data: {
        clientType,
        ...REQUEST_ALL_DATA
      },
      dicData: null,
      props: {
        label: 'roleName',
        value: 'id'
      }
    }
  }
  return clientTypeDic
}

//根据类型获取数据
export function GET_DIC_BY_TYPE(type = CURRENT_MENU_TYPE) {
  return MENU_DIC[type]
}
