import { cloneDeep } from 'lodash'

export default function getBaseTableData(option) {
  const { dataAttrs, isCloneDeep } = option
  return {
    data() {
      this.baseTableUnwatchs = {}
      dataAttrs.baseTable = null
      return dataAttrs
    },
    watch: {
      baseTable: {
        handler(baseTable) {
          if (!baseTable) return
          createWatcher.call(this, dataAttrs, isCloneDeep)
        },
        immediate: true
      }
    }
  }
}

export function createWatcher(dataAttrs, isCloneDeep) {
  for (let key in dataAttrs) {
    //basetable中不存在这个key值
    if(this.baseTable[key] === undefined) return
    
    typeof this.baseTableUnwatchs[key] === 'function' && this.baseTableUnwatchs[key]()
    this.baseTableUnwatchs[key] = this.$watch(
      () => {
        return this.baseTable[key]
      },
      (childData) => {
        this[key] = isCloneDeep ? cloneDeep(childData) : childData
      },
      {
        deep: true,
        immediate: true
      }
    )
  }
}
