import { createRandomNum } from '../index'
import { OSS_DIR_MAP_WITH_TYPE, uploadOSSPics } from '@/commons/oss'
//图片处理
export function getImgData(src) {
  let lock = false
  let img = new Image()
  img.setAttribute('crossOrigin', 'Anonymous')
  let c = document.createElement('canvas')
  const ctx = c.getContext('2d')
  return new Promise((resolve, reject) => {
    img.onload = () => {
      const { width, height } = img
      c.width = width
      c.height = height
      c.setAttribute('id', 'img')
      ctx.drawImage(img, 0, 0)
      const imgData = ctx.getImageData(0, 0, width, height)
      imgData.cols = width
      imgData.rows = height
      img.onerror = null
      img.onload = null
      c = null
      img = null
      resolve(imgData)
    }
    //解决本地跨域
    img.onerror = () => {
      try {
        if (!lock) {
          lock = true
          if (src.length < 200) {
            img.src = src + '?uid' + createRandomNum()
          } else {
            img.src = src
          }
        }
      } catch (err) {
        reject(false)
      }
    }
    img.src = src
  })
}

//根据src创建canvas dom
export function getCanvasBySrc(src) {
  let lock = false
  let img = new Image()
  img.setAttribute('crossOrigin', 'Anonymous')
  let c = document.createElement('canvas')
  const ctx = c.getContext('2d')
  return new Promise((resolve, reject) => {
    img.onload = () => {
      const { width, height } = img
      c.width = width
      c.height = height
      c.setAttribute('id', 'img')
      ctx.drawImage(img, 0, 0)
      img.onerror = null
      img.onload = null
      img = null
      resolve(c)
    }
    //解决本地跨域
    img.onerror = () => {
      try {
        if (!lock) {
          lock = true
          if (src.length < 200) {
            img.src = src + '?uid' + createRandomNum()
          } else {
            img.src = src
          }
        }
      } catch (err) {
        reject(false)
      }
    }
    img.src = src
  })
}

export function getTileCanvasImgData(src, cW, cH) {
  let lock = false
  let img = new Image()
  img.setAttribute('crossOrigin', 'Anonymous')
  let c = document.createElement('canvas')
  const ctx = c.getContext('2d')
  return new Promise((resolve, reject) => {
    img.onload = () => {
      const { width, height } = img
      c.width = cW || width
      c.height = cH || height
      c.setAttribute('id', 'img')

      // ctx.drawImage(img, 0, 0)
      const pat = ctx.createPattern(img, 'repeat')
      ctx.rect(0, 0, c.width, c.height)
      ctx.fillStyle = pat
      ctx.fill()
      const imgData = ctx.getImageData(0, 0, cW || width, cH || height)

      imgData.cols = width
      imgData.rows = height
      img.onerror = null
      img.onload = null
      c = null
      img = null

      resolve(imgData)
    }
    //解决本地跨域
    img.onerror = () => {
      try {
        if (!lock) {
          lock = true
          if (src.length < 200) {
            img.src = src + '?uid' + createRandomNum()
          } else {
            img.src = src
          }
        }
      } catch (err) {
        reject(false)
      }
    }
    img.src = src
  })
}

export function getRedImgData(src) {
  let lock = false
  const img = new Image()
  const c = document.createElement('canvas')
  const ctx = c.getContext('2d')
  return new Promise((resolve) => {
    img.onload = () => {
      const { width, height } = img
      img.setAttribute('crossOrigin', 'Anonymous')
      c.width = width
      c.height = height
      // c.setAttribute("id", "img");
      ctx.drawImage(img, 0, 0)
      const imgData = ctx.getImageData(0, 0, width, height)
      imgData.cols = width
      imgData.rows = height
      const widthRows = width * 4
      const data = imgData.data
      for (let i = 0; i < data.length; i += 4) {
        if (data[i + 3] !== 0) {
          data[i] = 123 // red
          data[i + 1] = 175 // green
          data[i + 2] = 232 // blu
        }
      }

      ctx.putImageData(imgData, 0, 0)
      resolve(c)
      // const rand = parseInt(Math.random(0, width * height))
      // if (imgData.data[rand] > 0) {
      //   clearInterval(timer)
      //   resolve(imgData)
      // }
    }
    img.onerror = () => {
      if (!lock) {
        lock = true
        if (src.length < 200) {
          img.src = src + '?uid' + createRandomNum()
        } else {
          img.src = src
        }
      }
    }
    img.src = src
  })
}

//获取imageData中的Alpha通道的所有数据
export function getAlphaData(imageData) {
  const { width, height } = imageData
  const data = imageData.data
  const alphaData = {}

  alphaData.width = width
  alphaData.height = height
  alphaData.cols = width
  alphaData.rows = height
  const tmpData = []
  for (let i = 0; i < data.length; i += 4) {
    tmpData.push(data[i + 3])
  }
  alphaData.data = new Uint8Array(tmpData)
  return alphaData
}

export function getPixelByCoord({ data, cols }, coordX, coordY) {
  const num = parseInt(coordX * 4 + coordY * cols * 4)
  return [data[num], data[num + 1], data[num + 2], data[num + 3]]
}

export function getImgDom(src) {
  const img = new Image()
  return new Promise((resolve) => {
    img.onload = () => {
      setTimeout(() => {
        resolve(img)
      }, 500)
    }
    img.onerror = () => {
      resolve(null)
    }
    img.src = src
  })
}

export function getFileNameWithoutExpand(name) {
  name = name || ''
  return name.split('.')[0]
}

//rx,ry 围绕oX, oY点 获取旋转之后的角度
export function getRotatePoint({ rX, rY, oX, oY, angle }) {
  const { PI, cos, sin } = Math
  const rotate = (PI / 180) * -angle
  let x = (rX - oX) * cos(rotate) - (rY - oY) * sin(rotate) + oX
  let y = (rY - oY) * cos(rotate) + (rX - oX) * sin(rotate) + oY
  const dirX = x - rX
  const dirY = y - rY
  return {
    dirX,
    dirY,
    x,
    y
  }
}

//获取图片旋转之后的尺寸
export function getImgRotateSize({ src, angle }) {
  const c = document.createElement('canvas')
  const ctx = c.getContext('2d')
  const img = new Image()
  img.setAttribute('crossOrigin', 'Anonymous')

  return new Promise((resolve) => {
    img.onload = function () {
      const { PI, abs } = Math
      // let rotate = 45
      const { width, height } = img
      c.width = width * 2
      c.height = height * 2

      const [oCenterPointX, oCenterPointY] = [c.width / 2 - width / 2, c.height / 2 - height / 2]
      ctx.translate(c.width / 2, c.height / 2)
      ctx.rotate((angle * PI) / 180)
      ctx.drawImage(img, -width / 2, -height / 2)

      const imgData = ctx.getImageData(0, 0, c.width, c.height)
      const obj = getPos(imgData)

      const { minX, minY, maxX, maxY } = obj

      const obj1 = {
        minX: minX - width / 2,
        maxX: maxX - width / 2,
        minY: minY - height / 2,
        maxY: maxY - height / 2
      }

      const [w, h] = [obj1.maxX - obj1.minX, obj1.maxY - obj1.minY]

      // getOriginCoord({ w: 1837, h: 1973, l: 143, t: 780, dirL: -160, dirT: 137.5, oW: 1485, oH: 1740 })

      resolve({ w, h })

      // console.log('obj1', obj1)
      // document.body.append(c)
    }
    // img.src = require('./d1_0_angle.png')
    // img.src = require('./0_angle.png')
    img.src = src + '?uid=' + createRandomNum()
  })

  //获取图片未旋转之前的左上角坐标位置
  function getOriginCoord({ w, h, l, t, dirL, dirT, oW, oH }) {
    //psd中未旋转的中心点位置
    const [cX, cY] = [l + w / 2 - dirL, t + h / 2 - dirT]
    const [oL, oT] = [cX - oW / 2, cY - oH / 2]

    return {
      oL,
      oT,
      cX,
      cY
    }
  }

  //获取旋转之后的左上角和右下角的位置
  function getPos(imgData) {
    imgData.cols = imgData.width
    imgData.rows = imgData.height
    const { min, max } = Math
    let [minX, minY, maxX, maxY] = [imgData.width, imgData.height, -1, -1]
    for (let y = 0; y < imgData.rows; y++) {
      for (let x = 0; x < imgData.cols; x++) {
        if (getPixelByCoord(imgData, x, y)[3] != 0) {
          minX = min(minX, x)
          minY = min(minY, y)
          maxX = max(maxX, x)
          maxY = max(maxY, y)
        }
      }
    }
    return { minX, minY, maxX, maxY }
  }

  function getPixelByCoord({ data, cols }, coordX, coordY) {
    const num = parseInt(coordX * 4 + coordY * cols * 4)
    return [data[num], data[num + 1], data[num + 2], data[num + 3]]
  }
}

export function handleFigurePaths({ list, prop = 'path', type = 7 }) {
  return new Promise((resolve) => {
    if (!Array.isArray(list)) return resolve([])
    let p = []
    list.forEach((item, index) => {
      if (item instanceof File) {
        p.push(
          (async () => {
            let path = await getFigurePath(item, type)
            if (path) {
              list[index] = prop
                ? {
                  [prop]: path
                }
                : path
            }
            return index
          })()
        )
      }
    })
    // eslint-disable-next-line no-undef
    promiseAll(p).then((res) => {
      resolve(list)
    })
  })
}
export function getFigurePath(file, type = 7) {
  return new Promise((resolve) => {
    if (file instanceof File) {
      uploadOSSPics([
        {
          files: [file],
          prop: 'figurePath',
          dirPrefix: OSS_DIR_MAP_WITH_TYPE[type]
        }
      ])
        .then((res) => {
          resolve(res.figurePath)
        })
        .catch(() => {
          resolve(null)
        })
    } else {
      resolve(file)
    }
  })
}

// 获取最新图片
export function requestLatest(url) {
  url = new URL(url)
  const searchParams = url.searchParams
  searchParams.set('uid', createRandomNum())
  url.search = searchParams.toString()
  return url.href
}