import { debounce } from '@/utils'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      sidebarOpened(state) {
        return state.app.sidebar.opened
      }
    })
  },
  watch: {
    sidebarOpened() {
      this.resizeCallback()
    }
  },
  mounted() {
    this.handleResize()
  },
  activated() {
    this.handleResize()
  },
  deactivated() {
    window.removeEventListener('resize', this.resizeCallback)
  },
  methods: {
    handleResize() {
      if (this.onresize) {
        window.removeEventListener('resize', this.resizeCallback)
        if (!this.resizeCallback) {
          this.resizeCallback = debounce(
            this.onresize,
            500
          )
        }
        this.$nextTick(function () {
          this.resizeCallback()
          window.addEventListener('resize', this.resizeCallback)
          this.$once('hook:destroyed', () => {
            window.removeEventListener('resize', this.resizeCallback)
          })
        })
      }
    }
  }
}
