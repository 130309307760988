export const WAIT_RELATION = '11' //生成订单-待关联
export const WAIT_GENERATE_WAYBILL = '20' //待生成运单
export const EXPIRED = '29' //已过期
export const WAIT_PAIT = '30' //待付款
export const WAIT_DISPATCH = '40' //待排单
export const IN_DISPATCH = '41' //排单中
export const IN_PRODUCTION = '42' //生产中
export const WAIT_UPLOAD_EXPRESS = '50' //待选择物流
export const WAIT_UPLOAD_PACKING = '51' //待上传装箱单
export const UPLOADED_PACKING = '52' //已上传装箱单
export const WAIT_SEND_GOODS = '53' //待发货
export const DELIVER = '70' //已发货
export const RETURN_GOODS = '72' //退货中
export const RETURNED = '73' //已退货
export const CANCEL = '80' //已取消
export const FINISH = '90' //已完成

export const ORDER_STATUS_LIST = {
  [WAIT_RELATION]: '待关联',
  [WAIT_GENERATE_WAYBILL]: '待生成运单',
  [EXPIRED]: '已过期',
  [WAIT_PAIT]: '待付货款',
  [WAIT_DISPATCH]: '待排单',
  [IN_DISPATCH]: '排单中',
  [IN_PRODUCTION]: '生产中',
  [WAIT_UPLOAD_EXPRESS]: '待选择物流',
  [WAIT_UPLOAD_PACKING]: '待上传装箱单',
  [UPLOADED_PACKING]: '已上传装箱单',
  [WAIT_SEND_GOODS]: '待发货',
  [DELIVER]: '已发货',
  [RETURN_GOODS]: '退货中',
  [RETURNED]: '已退货',
  [CANCEL]: '已取消',
  [FINISH]: '已完成'
}
export const ORDER_STATUS_DIC = []
for (const key in ORDER_STATUS_LIST) {
  ORDER_STATUS_DIC.push({
    label: ORDER_STATUS_LIST[key],
    value: Number(key)
  })
}

//waybillCode 是否有运单号
export const WAYBILL_CODE_DIC = [
  {
    label: '全部',
    value: 'all'
  },
  {
    label: '是',
    value: '1'
  },
  {
    label: '否',
    value: '0'
  }
]
