<template>
  <el-dialog
    v-if="visible"
    append-to-body
    title="修改交易密码"
    :visible.sync="visible"
    :before-close="onCancel"
    width="30%"
  >
    <br />
    <paymentPassBox :passwordVal="passwordVal"></paymentPassBox>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取 消</el-button>
      <loading-btn type="primary" @click="commitPassword">确 定</loading-btn>
    </span>
  </el-dialog>
</template>

<script>
import paymentPassBox from '@/views/system/user/paymentPass/paymentPassBox'
import { mapGetters } from 'vuex'
import { changePassword } from '@/api/user'

export default {
  components: {
    paymentPassBox
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      passwordVal: []
    }
  },
  computed: {
    ...mapGetters(['id'])
  },
  methods: {
    onCancel() {
      this.$emit('update:visible', false)
    },
    processPaymentPassword() {
      let pwd = ''
      this.passwordVal
        .filter((item) => {
          return item !== ''
        })
        .map((item) => {
          pwd += item
        })
      if (pwd.length !== 6) {
        this.$message.warning('请输入完整密码')
        return undefined
      } else {
        return pwd
      }
    },
    async commitPassword() {
      const approveConfirmPassword = this.processPaymentPassword()
      if (!approveConfirmPassword) {
        return
      }
      try {
        const { code } = await changePassword({
          approveConfirmPassword,
          id: this.id
        })
        if (code === 0) {
          this.$message.success('交易密码修改成功')
        } else {
          this.$message.error('操作失败')
        }
      } catch {}
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style></style>
