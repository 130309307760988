/* eslint-disable */

export class GetLastPromise {
  wait(promise) {
    return new Promise(async (resolve, reject) => {
      this.lastPromise = promise
      await promise
      // 只有最后一次才会被返回
      if (this.lastPromise !== promise) return reject('当前请求不是最后一次')
      resolve(promise)
    })
  }
}