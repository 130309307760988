/* eslint-disable */
import { uniqueArray } from '@/utils'

export default function ({ list, suffix = '', getInitVal = true } = {}) {
  let curValKey = `curVal${suffix}`
  let listKey = `list${suffix}`
  let curItemKey = `curItem${suffix}`
  let ignoreKeys = `ignoreKeys${suffix}`
  let ignoreKeysObj = `ignoreKeysObj${suffix}`
  return {
    data() {
      return {
        [curValKey]: ''
      }
    },
    computed: {
      [listKey]() {
        return list || []
      },
      [curItemKey]({ [listKey]: curList, [curValKey]: curVal }) {
        return curList.find((item) => item.value === curVal) || {}
      },
      // 忽略搜索表单的字段
      // 因为每个订单状态的请求字段不一致
      [ignoreKeys]({ [listKey]: curList }) {
        return uniqueArray(
          curList.reduce((prev, next) => {
            for (const key in next.searchValue) {
              prev.push(key)
            }
            return prev
          }, [])
        )
      },
      [ignoreKeysObj]({ [ignoreKeys]: keys }) {
        return keys.reduce((prev, next) => {
          prev[next] = undefined
          return prev
        }, {})
      }
    },
    watch: {
      [listKey]: {
        handler(n) {
          if (n.length && getInitVal) {
            this[curValKey] = n[0].value
          }
        },
        immediate: true,
        deep: true
      }
    },
    methods: {
      [`getSearchValue${suffix}`](form = {}) {
        let { [ignoreKeysObj]: ignoreObj, [curItemKey]: curItem } = this
        return Object.assign(form, ignoreObj, curItem?.searchValue)
      }
    }
  }
}
