// 店铺

export const SHOP_TYPE_AMAZON = 2 //亚马逊
export const SHOP_TYPE_ALIBABA_EXPRESS = 1 //速卖通
export const SHOP_TYPE_EBAY = 3 //ebay
export const SHOP_TYPE_OTHER = 4 //其他
export const SHOP_TYPE_IS = 5 // 国际站

export const SHOP_TYPE_LIST = {
  [SHOP_TYPE_AMAZON]: {
    label: '亚马逊',
    value: SHOP_TYPE_AMAZON
  },
  [SHOP_TYPE_ALIBABA_EXPRESS]: {
    label: '速卖通',
    value: SHOP_TYPE_ALIBABA_EXPRESS
  },
  [SHOP_TYPE_EBAY]: {
    label: 'ebay',
    value: SHOP_TYPE_EBAY
  },
  [SHOP_TYPE_OTHER]: {
    label: '其他',
    value: SHOP_TYPE_OTHER
  },
  [SHOP_TYPE_IS]: {
    label: '国际站',
    value: SHOP_TYPE_IS
  }
}
