<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item,index) in levelList" v-if="item.meta.title" :key="item.path">
        <span v-if="item.redirect==='noredirect'||index==levelList.length-1" class="no-redirect">{{ item.meta.title }}</span>
        <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import pathToRegexp from 'path-to-regexp'

export default {
  data() {
    return {
      levelList: null
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
    getBreadcrumb() {
      let matched = this.$route.matched.filter(item => {
        if (item.name) {
          return true
        }
      })
      const first = matched[0]
      if (first && first.name !== '首页') {
        matched = [{ path: '/', redirect: '/', meta: { title: '首页' }}].concat(matched)
      }
      this.levelList = matched.map((route, index) => {
        let { redirect, meta: { firstChildPath } } = route
        if (firstChildPath && !/^\//.test(firstChildPath)) {
          const prefix = matched.slice(0, index + 1).reduce((prev, next) => {
            return /^\//.test(next.path) ? next.path : prev
          }, '')
          firstChildPath = `${prefix}/${firstChildPath}`
        }
        return {
          ...route,
          redirect: redirect ? redirect : firstChildPath ? firstChildPath : undefined
        }
      })
    },
    pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-externaladmin/issues/561
      const { params } = this.$route
      var toPath = pathToRegexp.compile(path)
      return toPath(params)
    },
    handleLink(item) {
      const { redirect, path } = item
      if (redirect) {
        this.$router.push(redirect)
        return
      }
      this.$router.push(this.pathCompile(path))
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .app-breadcrumb.el-breadcrumb {
    display: inline-block;
    font-size: 14px;
    line-height: 50px;
    margin-left: 10px;
    .no-redirect {
      color: #97a8be;
      cursor: text;
    }
  }
</style>
