export const tasksCols = [
  {
    prop: 'status',
    label: '状态',
    align: 'center',
    slotName: 'statusSlot'
  },
  {
    prop: 'total',
    label: '文件大小',
    align: 'center',
    width: 80,
    slotName: 'totalSlot'
  },
  {
    prop: 'categoryName',
    label: '分类',
    align: 'center',
    slotName: 'categoryNameSlot'
  },
  {
    prop: 'thumbnailPath',
    label: '图片',
    align: 'center',
    slotName: 'thumbnailPathSlot'
  },
  {
    prop: 'progress',
    label: '进度条',
    align: 'center',
    slotName: 'progressSlot'
  }
]
