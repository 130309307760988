// 售后订单
export const HANDLE_STATUS_PENDING = 0 //待处理
export const HANDLE_STATUS_PROCESSED = 1 //已处理
export const HANDLE_STATUS_REFUSED = 2 //已拒绝
export const HANDLE_STATUS_OBJ = {
  [HANDLE_STATUS_PENDING]: '售后待处理',
  [HANDLE_STATUS_PROCESSED]: '售后已处理',
  [HANDLE_STATUS_REFUSED]: '审核失败'
}
export const HANDLE_STATUS_DIC = [
  {
    label: '售后待处理',
    value: HANDLE_STATUS_PENDING
  },
  {
    label: '售后已处理',
    value: HANDLE_STATUS_PROCESSED
  },
  {
    label: '审核失败',
    value: HANDLE_STATUS_REFUSED
  }
]

// paymentType 支付方式/支付渠道（ 1、支付宝 2、微信 3、银行卡、4，余额付款 5、手工调整余额(免支付) 6、信用额度 7、临时信用额度）
export const PAYMENT_TYPE_ZFB = 1
export const PAYMENT_TYPE_WX = 2
export const PAYMENT_TYPE_BANK = 3
export const PAYMENT_TYPE_BALANCE = 4
export const PAYMENT_TYPE_BALANCE_BY_HAND = 5
export const PAYMENT_TYPE_CREDIT = 6
export const PAYMENT_TYPE_TEMPORARY = 7
export const PAYMENT_TYPE_LABELS = {
  [PAYMENT_TYPE_ZFB]: '支付宝',
  [PAYMENT_TYPE_WX]: '微信',
  [PAYMENT_TYPE_BANK]: '银行卡',
  [PAYMENT_TYPE_BALANCE]: '余额付款',
  [PAYMENT_TYPE_BALANCE_BY_HAND]: '免支付',
  [PAYMENT_TYPE_CREDIT]: '信用额度',
  [PAYMENT_TYPE_TEMPORARY]: '临时信用额度'
}

// tradeType
export const RECHARGE = 1 //充值
export const WITHDRAWAL = 2 //提现
export const ORDERPAY = 3 //订单支付
export const ORDER_AFTERSALE_REFUND = 4 //订单售后退款
export const MANUAL_ADJUST_AMOUNT = 5 //手工调整余额
export const REFUSE_REFUND = 6 //提现审核拒绝退款
export const REAPPLY_EXPRESS_FEE = 7 //重新申请运费退补款
export const AFTER_SALE_APPLY_REFUND = 8 //售后申请运费退补款
export const MANUAL_ADJUST_CREDIT = 9 //手工调整总信用额度
export const MANUAL_ADJUST_CANUSE_CREDIT = 10 //手工调整可用信用额度
export const RECHARGE_CREDIT_AMOUNT = 11 //充值信用额度
export const TEMPORARY_AMOUNT_GENERATE = 12 //临时额度生成
export const MONTHBILL_REIMBURSEMENT = 13 //月账单还款
export const FREIGHT_PAYMENT = 14 //支付运费
export const TRADE_TYPE_LABELS = {
  [RECHARGE]: '充值',
  [WITHDRAWAL]: '提现',
  [ORDERPAY]: '订单支付',
  [ORDER_AFTERSALE_REFUND]: '订单售后退款',
  [MANUAL_ADJUST_AMOUNT]: '手工调整余额',
  [REFUSE_REFUND]: '提现审核拒绝退款',
  [REAPPLY_EXPRESS_FEE]: '重新申请运费退补款',
  [AFTER_SALE_APPLY_REFUND]: '售后申请运费退补款',
  [MANUAL_ADJUST_CREDIT]: '手工调整总信用额度',
  [MANUAL_ADJUST_CANUSE_CREDIT]: '手工调整可用信用额度',
  [RECHARGE_CREDIT_AMOUNT]: '充值信用额度',
  [TEMPORARY_AMOUNT_GENERATE]: '临时额度生成',
  [MONTHBILL_REIMBURSEMENT]: '月账单还款',
  [FREIGHT_PAYMENT]: '支付运费'
}

//支付费用类型 0-支付订单所有费用（包括货款和运费），1-只支付货款，2-只支付运费;")
export const PAYMENT_AMOUNT_ALL = 0
export const PAYMENT_AMOUNT_PRODUCT = 1
export const PAYMENT_AMOUNT_FREIGHT = 2
