import { DISABLE_CREDIT } from '@/views/user/creditConst'

export const SEX_LIST = {
  0: '通用',
  1: '男',
  2: '女',
  3: '儿童'
}

export const LEVEL_LSIT = {
  1: '全部',
  2: '个人版',
  3: '创业版',
  4: '专业版',
  5: '企业版'
}

export const SEASONS_LSIT = {
  0: '通用',
  1: '春',
  2: '夏',
  3: '秋',
  4: '冬'
}

export const STRUCT_ITEKM = {
  name: '',
  shortName: '',
  colorId: ''
}

export const SIZES_ITEM = {
  size: null,
  weight: null,
  price: null,
  length: null,
  width: null,
  height: null
}

export const CUSTOM = 'custom'

export const SIZE_DETAIL = {
  sizeName: null,
  weight: null,
  price: null,
  length: null,
  width: null,
  height: null
}

export const STRUCR_ITEM = {
  name: '',
  colorId: '',
  shortName: ''
}
export const WAIT_PERFECT = 10
export const WAIT_SHELVES = 0
export const SHELVES = 1
export const OFF_SHELVES = 2
export const PRODUCT_SHELVES_STATUS_LIST = {
  [WAIT_PERFECT]: '待完善',
  [WAIT_SHELVES]: '待上架',
  [SHELVES]: '已上架',
  [OFF_SHELVES]: '已下架'
}
export const PRODUCT_SHELVES_STATUS_LIST1 = {
  [WAIT_SHELVES]: '待上架',
  [SHELVES]: '已上架',
  [OFF_SHELVES]: '已下架'
}
export const PRODUCT_SHELVES_LIST = {
  [WAIT_SHELVES]: '待上架',
  [SHELVES]: '已上架',
  [OFF_SHELVES]: '已下架'
}

// isPublicOnShelf 0下架 1上架
export const IS_PUBLIC_OFF_SHELF = 0
export const IS_PUBLIC_ON_SHELF = 1

// 档位状态
export const GEARS_ENABLED_OBJ = {
  activeText: '启用',
  activeValue: 1,
  disabledText: '禁用',
  disabledValue: 0
}

// 系统模板状态
export const TEMPLATE_ENABLED_OBJ = {
  activeText: '禁用',
  activeValue: 0,
  disabledText: '启用',
  disabledValue: 1
}

export const GEARS_ENABLED_DIC = [
  {
    label: GEARS_ENABLED_OBJ.activeText,
    value: GEARS_ENABLED_OBJ.activeValue
  },
  {
    label: GEARS_ENABLED_OBJ.disabledText,
    value: GEARS_ENABLED_OBJ.disabledValue
  }
]
// 档位状态 modifyType 是否启用过 0 未启用过 1 启用过
export const MODIFY_TYPE_OBJ = {
  activeText: '启用过',
  activeValue: 1,
  disabledText: '未启用过',
  disabledValue: 0
}

//原型分组状态
export const PROTO_GROUP_DISENABLE = 0
export const PROTO_GROUP_ENABLE = 1

export const PROTO_GROUP_ENABLE_OBJ = {
  [PROTO_GROUP_ENABLE]: '已启用',
  [PROTO_GROUP_DISENABLE]: '未启用'
}

//默认原型分组
export const PROTO_GROUP_NOT_IS_DEFAULT = 0
export const PROTO_GROUP_IS_DEFAULT = 1

export const PROTO_GROUP_DEFAULT_OBJ = {
  [PROTO_GROUP_IS_DEFAULT]: '是',
  [PROTO_GROUP_NOT_IS_DEFAULT]: '否'
}

export const PROTO_GROUP_DEFAULT_DIC = [
  {
    label: '全部',
    value: 'all'
  },
  {
    label: '是',
    value: PROTO_GROUP_IS_DEFAULT
  },
  {
    label: '否',
    value: PROTO_GROUP_NOT_IS_DEFAULT
  }
]

export const PROTO_GROUP_ENABLE_DIC = [
  {
    label: '全部',
    value: 'all'
  },
  {
    label: '未启用',
    value: PROTO_GROUP_DISENABLE
  },
  {
    label: '已启用',
    value: PROTO_GROUP_ENABLE
  }
]

// 价格设置类型 priceType 1-系统阶梯价格 2-固定档位价格
export const PRICE_TYPE_SYS = 1
export const PRICE_TYPE_FIXED = 2
export const PRICE_TYPE_DIC = [
  {
    label: '系统阶梯价格',
    value: PRICE_TYPE_SYS
  },
  {
    label: '固定档位价格',
    value: PRICE_TYPE_FIXED
  }
]

// 启用/禁用状态(启用为1, 禁用为0)
export const DISABLE_STATUS = 0
export const ENABLE_STATUS = 1

export const columnGroupTypeData = [
  {
    label: 'Required',
    value: 1
  },
  {
    label: 'Images',
    value: 2
  },
  {
    label: 'Variation',
    value: 3
  },
  {
    label: 'Basic',
    value: 4
  },
  {
    label: 'Discovery',
    value: 5
  },
  {
    label: 'Product Enrichment',
    value: 6
  },
  {
    label: 'Dimensions',
    value: 7
  },
  {
    label: 'Fulfillment',
    value: 8
  },
  {
    label: 'Compliance',
    value: 9
  },
  {
    label: 'Offer',
    value: 10
  },
  {
    label: 'B2B',
    value: 11
  },
  {
    label: 'Optional',
    value: 12
  },
  {
    label: 'Preferred',
    value: 13
  }
]

// 启用/禁用状态(启用为1, 禁用为0)
export const PRODUCT_LABEL_OF_ALL = 'all'
export const PRODUCT_LABEL_OF_NOT_SET = 0
export const PRODUCT_LABEL_OF_NEW = 1
export const productLabelList = [
  {
    label: '全部',
    value: PRODUCT_LABEL_OF_ALL
  },
  {
    label: '未设置',
    value: PRODUCT_LABEL_OF_NOT_SET
  },
  {
    label: '新品',
    value: PRODUCT_LABEL_OF_NEW
  }
]
