<template>
  <BaseDialog
    maxHeight="60vh"
    width="1200px"
    title="任务列表"
    :dialogVisible.sync="dialog"
    @closed="SET_TASK_DIALOG(false)"
  >
    <template>
      <CommonTable height="auto" :selection="false" :cols="cols" :infoData="data">
        <template #statusSlot="{ scoped: row }">
          <el-tag size="small" :type="UP_STATUS[row[row.prop]].type">
            <i class="el-icon-loading" v-if="row[row.prop] < 3" />
            {{ UP_STATUS[row[row.prop]].label }}
          </el-tag>
        </template>
        <template #thumbnailPathSlot="{ scoped: row }">
          <div style="text-align: center">
            <!-- <el-image style="witdh: 50px; height: 50px" fit="contain" :src="row[row.prop] | formatUrl"></el-image> -->
            <CacheImg style="witdh: 50px; height: 50px" fit="contain" :raw="row[row.prop]"></CacheImg>
          </div>
          <el-button style="white-space: normal" type="text" size="mini">
            {{ row.name }}
          </el-button>
        </template>

        <!-- <template #pathSlot="{ scoped: row }">
          <el-image
            v-for="(item, index) in row[row.prop]"
            :key="index"
            style="witdh: 50px; height: 50px"
            fit="contain"
            :src="item.url"
          ></el-image>
        </template> -->

        <template #categoryNameSlot="{ scoped: row }">
          {{ row[row.prop] || '默认' }}
        </template>

        <template #totalSlot="{ scoped: row }">
          <el-tag type="primary">
            {{ size(row) }}
          </el-tag>
        </template>
        <template #progressSlot="{ scoped: row }">
          <el-progress
            :key="row.id"
            :color="customColors"
            :text-inside="true"
            :stroke-width="15"
            :percentage="percentage(row)"
          ></el-progress>
        </template>
      </CommonTable>
    </template>
    <template #footer>
      <span></span>
    </template>
  </BaseDialog>
</template>
<script>
import CacheImg from '@/components/cacheImg.vue'

import { tasksCols as cols } from './cols'
import { UP_STATUS } from '@/utils/constant'
import { mapMutations, mapState } from 'vuex'
export default {
  components: {
    CacheImg
  },

  props: {
    data: {
      type: Array,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      cols,
      UP_STATUS,
      dialog: false,
      customColors: [
        { color: '#f56c6c', percentage: 20 },
        { color: '#e6a23c', percentage: 40 },
        { color: '#5cb87a', percentage: 60 },
        { color: '#1989fa', percentage: 80 },
        { color: '#6f7ad3', percentage: 100 }
      ]
    }
  },
  computed: {
    ...mapState({
      taskDialog(state) {
        return state.product.taskDialog
      }
    }),
    size() {
      return (row) => {
        const { total } = row || {}
        const M = 1 * 1024 * 1024
        const k = 1 * 1024
        if (total > M) {
          return Math.round(row.total / M) + 'M'
        }
        return Math.round(row.total / k) + 'K'
      }
    },
    percentage() {
      return (row) => {
        return row.total && row.loaded && Number(((row.loaded / row.total) * 100).toFixed(2))
      }
    },
    downSuccess(row) {
      return (row) => {
        if (!row.total) return false
        return row.total == row.loaded
      }
    }
  },
  watch: {
    taskDialog: {
      handler(n) {
        this.dialog = n
      },
      immediate: true
    }
  },
  filters: {
    formatUrl(blob) {
      return URL.createObjectURL(blob)
    }
  },
  methods: {
    ...mapMutations(['SET_TASK_DIALOG']),
    cancelDown(row, index) {
      const { cancel } = row
      typeof cancel === 'function' &&
        cancel({
          cancelMessage: '取消下载'
        })
      this.$emit('cancelDown', index)
    }
  }
}
</script>